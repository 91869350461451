/* 38ffe060534a5ba69ac4c6ad557bbf6b966d4b2d
 * This file is automatically generated by graphql-let. */

import * as Types from '../../__types__'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type CheckoutCreateMutationVariables = Types.Exact<{
  input: Types.CheckoutCreateInput
  queueToken?: Types.InputMaybe<Types.Scalars['String']>
}>

export type CheckoutCreateMutation = {
  __typename?: 'Mutation'
  checkoutCreate?: {
    __typename?: 'CheckoutCreatePayload'
    checkout?: {
      __typename?: 'Checkout'
      completedAt?: any | null
      createdAt: any
      currencyCode: Types.CurrencyCode
      email?: string | null
      id: string
      webUrl: any
      taxesIncluded: boolean
      taxExempt: boolean
      shippingDiscountAllocations: Array<{
        __typename?: 'DiscountAllocation'
        allocatedAmount: {
          __typename?: 'MoneyV2'
          amount: any
          currencyCode: Types.CurrencyCode
        }
      }>
      shippingLine?: {
        __typename?: 'ShippingRate'
        handle: string
        title: string
        priceV2: {
          __typename?: 'MoneyV2'
          amount: any
          currencyCode: Types.CurrencyCode
        }
      } | null
      lineItemsSubtotalPrice: {
        __typename?: 'MoneyV2'
        amount: any
        currencyCode: Types.CurrencyCode
      }
      subtotalPriceV2: {
        __typename?: 'MoneyV2'
        amount: any
        currencyCode: Types.CurrencyCode
      }
      totalDuties?: {
        __typename?: 'MoneyV2'
        amount: any
        currencyCode: Types.CurrencyCode
      } | null
      totalPriceV2: {
        __typename?: 'MoneyV2'
        amount: any
        currencyCode: Types.CurrencyCode
      }
      totalTaxV2: {
        __typename?: 'MoneyV2'
        amount: any
        currencyCode: Types.CurrencyCode
      }
      lineItems: {
        __typename?: 'CheckoutLineItemConnection'
        edges: Array<{
          __typename?: 'CheckoutLineItemEdge'
          node: {
            __typename?: 'CheckoutLineItem'
            id: string
            quantity: number
            title: string
            discountAllocations: Array<{
              __typename?: 'DiscountAllocation'
              allocatedAmount: {
                __typename?: 'MoneyV2'
                amount: any
                currencyCode: Types.CurrencyCode
              }
            }>
            variant?: {
              __typename?: 'ProductVariant'
              availableForSale: boolean
              currentlyNotInStock: boolean
              id: string
              quantityAvailable?: number | null
              requiresShipping: boolean
              sku?: string | null
              title: string
              weight?: number | null
              weightUnit: Types.WeightUnit
              compareAtPriceV2?: {
                __typename?: 'MoneyV2'
                amount: any
                currencyCode: Types.CurrencyCode
              } | null
              priceV2: {
                __typename?: 'MoneyV2'
                amount: any
                currencyCode: Types.CurrencyCode
              }
              selectedOptions: Array<{
                __typename?: 'SelectedOption'
                name: string
                value: string
              }>
              unitPrice?: {
                __typename?: 'MoneyV2'
                amount: any
                currencyCode: Types.CurrencyCode
              } | null
              product: {
                __typename?: 'Product'
                availableForSale: boolean
                description: string
                descriptionHtml: any
                handle: string
                id: string
                tags: Array<string>
                productType: string
                title: string
                totalInventory?: number | null
                updatedAt: any
                vendor: string
                compareAtPriceRange: {
                  __typename?: 'ProductPriceRange'
                  maxVariantPrice: {
                    __typename?: 'MoneyV2'
                    amount: any
                    currencyCode: Types.CurrencyCode
                  }
                  minVariantPrice: {
                    __typename?: 'MoneyV2'
                    amount: any
                    currencyCode: Types.CurrencyCode
                  }
                }
                landingPage?: {
                  __typename?: 'Metafield'
                  createdAt: any
                  description?: string | null
                  id: string
                  key: string
                  namespace: string
                  type: string
                  updatedAt: any
                  value: string
                } | null
                model?: {
                  __typename?: 'Metafield'
                  createdAt: any
                  description?: string | null
                  id: string
                  key: string
                  namespace: string
                  type: string
                  updatedAt: any
                  value: string
                } | null
                sellingPoints?: {
                  __typename?: 'Metafield'
                  createdAt: any
                  description?: string | null
                  id: string
                  key: string
                  namespace: string
                  type: string
                  updatedAt: any
                  value: string
                } | null
                simpleDescription?: {
                  __typename?: 'Metafield'
                  createdAt: any
                  description?: string | null
                  id: string
                  key: string
                  namespace: string
                  type: string
                  updatedAt: any
                  value: string
                } | null
                whatIsInTheBox?: {
                  __typename?: 'Metafield'
                  createdAt: any
                  description?: string | null
                  id: string
                  key: string
                  namespace: string
                  type: string
                  updatedAt: any
                  value: string
                } | null
                specs?: {
                  __typename?: 'Metafield'
                  createdAt: any
                  description?: string | null
                  id: string
                  key: string
                  namespace: string
                  type: string
                  updatedAt: any
                  value: string
                } | null
                faqs?: {
                  __typename?: 'Metafield'
                  createdAt: any
                  description?: string | null
                  id: string
                  key: string
                  namespace: string
                  type: string
                  updatedAt: any
                  value: string
                } | null
                manuals?: {
                  __typename?: 'Metafield'
                  createdAt: any
                  description?: string | null
                  id: string
                  key: string
                  namespace: string
                  type: string
                  updatedAt: any
                  value: string
                } | null
                amazonLink?: {
                  __typename?: 'Metafield'
                  createdAt: any
                  description?: string | null
                  id: string
                  key: string
                  namespace: string
                  type: string
                  updatedAt: any
                  value: string
                } | null
                seo: {
                  __typename?: 'SEO'
                  title?: string | null
                  description?: string | null
                }
                SEO_H1?: {
                  __typename?: 'Metafield'
                  createdAt: any
                  description?: string | null
                  id: string
                  key: string
                  namespace: string
                  type: string
                  updatedAt: any
                  value: string
                } | null
                images: {
                  __typename?: 'ImageConnection'
                  edges: Array<{
                    __typename?: 'ImageEdge'
                    node: {
                      __typename?: 'Image'
                      altText?: string | null
                      height?: number | null
                      id?: string | null
                      originalSrc: any
                      width?: number | null
                      transformedSrc: any
                      largeImageUrl: any
                      mediumImageUrl: any
                      smallImageUrl: any
                      thumbImageUrl: any
                      productSaleImageUrl: any
                    }
                  }>
                }
                variants: {
                  __typename?: 'ProductVariantConnection'
                  edges: Array<{
                    __typename?: 'ProductVariantEdge'
                    node: {
                      __typename?: 'ProductVariant'
                      availableForSale: boolean
                      currentlyNotInStock: boolean
                      id: string
                      quantityAvailable?: number | null
                      requiresShipping: boolean
                      sku?: string | null
                      title: string
                      weight?: number | null
                      weightUnit: Types.WeightUnit
                      compareAtPriceV2?: {
                        __typename?: 'MoneyV2'
                        amount: any
                        currencyCode: Types.CurrencyCode
                      } | null
                      priceV2: {
                        __typename?: 'MoneyV2'
                        amount: any
                        currencyCode: Types.CurrencyCode
                      }
                      selectedOptions: Array<{
                        __typename?: 'SelectedOption'
                        name: string
                        value: string
                      }>
                      unitPrice?: {
                        __typename?: 'MoneyV2'
                        amount: any
                        currencyCode: Types.CurrencyCode
                      } | null
                      image?: {
                        __typename?: 'Image'
                        altText?: string | null
                        height?: number | null
                        id?: string | null
                        originalSrc: any
                        width?: number | null
                        thumbImageUrl: any
                      } | null
                      model?: {
                        __typename?: 'Metafield'
                        createdAt: any
                        description?: string | null
                        id: string
                        key: string
                        namespace: string
                        type: string
                        updatedAt: any
                        value: string
                      } | null
                      edition_profile?: {
                        __typename?: 'Metafield'
                        createdAt: any
                        description?: string | null
                        id: string
                        key: string
                        namespace: string
                        type: string
                        updatedAt: any
                        value: string
                      } | null
                      amazonLink?: {
                        __typename?: 'Metafield'
                        createdAt: any
                        description?: string | null
                        id: string
                        key: string
                        namespace: string
                        type: string
                        updatedAt: any
                        value: string
                      } | null
                      sellingPoints?: {
                        __typename?: 'Metafield'
                        createdAt: any
                        description?: string | null
                        id: string
                        key: string
                        namespace: string
                        type: string
                        updatedAt: any
                        value: string
                      } | null
                    }
                  }>
                }
                seriesName?: {
                  __typename?: 'Metafield'
                  createdAt: any
                  description?: string | null
                  id: string
                  key: string
                  namespace: string
                  type: string
                  updatedAt: any
                  value: string
                } | null
                howToUse?: {
                  __typename?: 'Metafield'
                  id: string
                  value: string
                } | null
                discountJson?: {
                  __typename?: 'Metafield'
                  id: string
                  value: string
                } | null
                promotionalEndTime?: {
                  __typename?: 'Metafield'
                  id: string
                  value: string
                } | null
              }
              image?: {
                __typename?: 'Image'
                altText?: string | null
                height?: number | null
                id?: string | null
                originalSrc: any
                width?: number | null
              } | null
              model?: {
                __typename?: 'Metafield'
                createdAt: any
                description?: string | null
                id: string
                key: string
                namespace: string
                type: string
                updatedAt: any
                value: string
              } | null
            } | null
            customAttributes: Array<{
              __typename?: 'Attribute'
              key: string
              value?: string | null
            }>
          }
        }>
      }
    } | null
    checkoutUserErrors: Array<{
      __typename?: 'CheckoutUserError'
      code?: Types.CheckoutErrorCode | null
      field?: Array<string> | null
      message: string
    }>
  } | null
}

export type CheckoutQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']
}>

export type CheckoutQuery = {
  __typename?: 'QueryRoot'
  node?:
    | { __typename?: 'AppliedGiftCard' }
    | { __typename?: 'Article' }
    | { __typename?: 'Blog' }
    | { __typename?: 'Cart' }
    | { __typename?: 'CartLine' }
    | {
        __typename?: 'Checkout'
        id: string
        ready: boolean
        orderStatusUrl?: any | null
        webUrl: any
        email?: string | null
        customAttributes: Array<{
          __typename?: 'Attribute'
          key: string
          value?: string | null
        }>
        order?: {
          __typename?: 'Order'
          name: string
          orderNumber: number
          statusUrl: any
          id: string
          lineItems: {
            __typename?: 'OrderLineItemConnection'
            edges: Array<{
              __typename?: 'OrderLineItemEdge'
              cursor: string
              node: {
                __typename?: 'OrderLineItem'
                quantity: number
                title: string
                discountAllocations: Array<{
                  __typename?: 'DiscountAllocation'
                  allocatedAmount: {
                    __typename?: 'MoneyV2'
                    amount: any
                    currencyCode: Types.CurrencyCode
                  }
                }>
                variant?: {
                  __typename?: 'ProductVariant'
                  id: string
                  sku?: string | null
                  priceV2: {
                    __typename?: 'MoneyV2'
                    amount: any
                    currencyCode: Types.CurrencyCode
                  }
                  product: { __typename?: 'Product'; productType: string }
                } | null
              }
            }>
          }
          totalPriceV2: {
            __typename?: 'MoneyV2'
            amount: any
            currencyCode: Types.CurrencyCode
          }
          totalTaxV2?: {
            __typename?: 'MoneyV2'
            amount: any
            currencyCode: Types.CurrencyCode
          } | null
          totalShippingPriceV2: {
            __typename?: 'MoneyV2'
            amount: any
            currencyCode: Types.CurrencyCode
          }
          totalRefundedV2: {
            __typename?: 'MoneyV2'
            amount: any
            currencyCode: Types.CurrencyCode
          }
        } | null
      }
    | { __typename?: 'CheckoutLineItem' }
    | { __typename?: 'Collection' }
    | { __typename?: 'Comment' }
    | { __typename?: 'ExternalVideo' }
    | { __typename?: 'GenericFile' }
    | { __typename?: 'Location' }
    | { __typename?: 'MailingAddress' }
    | { __typename?: 'MediaImage' }
    | { __typename?: 'Menu' }
    | { __typename?: 'MenuItem' }
    | { __typename?: 'Metafield' }
    | { __typename?: 'Metaobject' }
    | { __typename?: 'Model3d' }
    | { __typename?: 'Order' }
    | { __typename?: 'Page' }
    | { __typename?: 'Payment' }
    | { __typename?: 'Product' }
    | { __typename?: 'ProductOption' }
    | { __typename?: 'ProductVariant' }
    | { __typename?: 'Shop' }
    | { __typename?: 'ShopPolicy' }
    | { __typename?: 'UrlRedirect' }
    | { __typename?: 'Video' }
    | null
}

export type CheckoutFragment = {
  __typename?: 'Checkout'
  completedAt?: any | null
  createdAt: any
  currencyCode: Types.CurrencyCode
  email?: string | null
  id: string
  webUrl: any
  taxesIncluded: boolean
  taxExempt: boolean
  shippingDiscountAllocations: Array<{
    __typename?: 'DiscountAllocation'
    allocatedAmount: {
      __typename?: 'MoneyV2'
      amount: any
      currencyCode: Types.CurrencyCode
    }
  }>
  shippingLine?: {
    __typename?: 'ShippingRate'
    handle: string
    title: string
    priceV2: {
      __typename?: 'MoneyV2'
      amount: any
      currencyCode: Types.CurrencyCode
    }
  } | null
  lineItemsSubtotalPrice: {
    __typename?: 'MoneyV2'
    amount: any
    currencyCode: Types.CurrencyCode
  }
  subtotalPriceV2: {
    __typename?: 'MoneyV2'
    amount: any
    currencyCode: Types.CurrencyCode
  }
  totalDuties?: {
    __typename?: 'MoneyV2'
    amount: any
    currencyCode: Types.CurrencyCode
  } | null
  totalPriceV2: {
    __typename?: 'MoneyV2'
    amount: any
    currencyCode: Types.CurrencyCode
  }
  totalTaxV2: {
    __typename?: 'MoneyV2'
    amount: any
    currencyCode: Types.CurrencyCode
  }
  lineItems: {
    __typename?: 'CheckoutLineItemConnection'
    edges: Array<{
      __typename?: 'CheckoutLineItemEdge'
      node: {
        __typename?: 'CheckoutLineItem'
        id: string
        quantity: number
        title: string
        discountAllocations: Array<{
          __typename?: 'DiscountAllocation'
          allocatedAmount: {
            __typename?: 'MoneyV2'
            amount: any
            currencyCode: Types.CurrencyCode
          }
        }>
        variant?: {
          __typename?: 'ProductVariant'
          availableForSale: boolean
          currentlyNotInStock: boolean
          id: string
          quantityAvailable?: number | null
          requiresShipping: boolean
          sku?: string | null
          title: string
          weight?: number | null
          weightUnit: Types.WeightUnit
          compareAtPriceV2?: {
            __typename?: 'MoneyV2'
            amount: any
            currencyCode: Types.CurrencyCode
          } | null
          priceV2: {
            __typename?: 'MoneyV2'
            amount: any
            currencyCode: Types.CurrencyCode
          }
          selectedOptions: Array<{
            __typename?: 'SelectedOption'
            name: string
            value: string
          }>
          unitPrice?: {
            __typename?: 'MoneyV2'
            amount: any
            currencyCode: Types.CurrencyCode
          } | null
          product: {
            __typename?: 'Product'
            availableForSale: boolean
            description: string
            descriptionHtml: any
            handle: string
            id: string
            tags: Array<string>
            productType: string
            title: string
            totalInventory?: number | null
            updatedAt: any
            vendor: string
            compareAtPriceRange: {
              __typename?: 'ProductPriceRange'
              maxVariantPrice: {
                __typename?: 'MoneyV2'
                amount: any
                currencyCode: Types.CurrencyCode
              }
              minVariantPrice: {
                __typename?: 'MoneyV2'
                amount: any
                currencyCode: Types.CurrencyCode
              }
            }
            landingPage?: {
              __typename?: 'Metafield'
              createdAt: any
              description?: string | null
              id: string
              key: string
              namespace: string
              type: string
              updatedAt: any
              value: string
            } | null
            model?: {
              __typename?: 'Metafield'
              createdAt: any
              description?: string | null
              id: string
              key: string
              namespace: string
              type: string
              updatedAt: any
              value: string
            } | null
            sellingPoints?: {
              __typename?: 'Metafield'
              createdAt: any
              description?: string | null
              id: string
              key: string
              namespace: string
              type: string
              updatedAt: any
              value: string
            } | null
            simpleDescription?: {
              __typename?: 'Metafield'
              createdAt: any
              description?: string | null
              id: string
              key: string
              namespace: string
              type: string
              updatedAt: any
              value: string
            } | null
            whatIsInTheBox?: {
              __typename?: 'Metafield'
              createdAt: any
              description?: string | null
              id: string
              key: string
              namespace: string
              type: string
              updatedAt: any
              value: string
            } | null
            specs?: {
              __typename?: 'Metafield'
              createdAt: any
              description?: string | null
              id: string
              key: string
              namespace: string
              type: string
              updatedAt: any
              value: string
            } | null
            faqs?: {
              __typename?: 'Metafield'
              createdAt: any
              description?: string | null
              id: string
              key: string
              namespace: string
              type: string
              updatedAt: any
              value: string
            } | null
            manuals?: {
              __typename?: 'Metafield'
              createdAt: any
              description?: string | null
              id: string
              key: string
              namespace: string
              type: string
              updatedAt: any
              value: string
            } | null
            amazonLink?: {
              __typename?: 'Metafield'
              createdAt: any
              description?: string | null
              id: string
              key: string
              namespace: string
              type: string
              updatedAt: any
              value: string
            } | null
            seo: {
              __typename?: 'SEO'
              title?: string | null
              description?: string | null
            }
            SEO_H1?: {
              __typename?: 'Metafield'
              createdAt: any
              description?: string | null
              id: string
              key: string
              namespace: string
              type: string
              updatedAt: any
              value: string
            } | null
            images: {
              __typename?: 'ImageConnection'
              edges: Array<{
                __typename?: 'ImageEdge'
                node: {
                  __typename?: 'Image'
                  altText?: string | null
                  height?: number | null
                  id?: string | null
                  originalSrc: any
                  width?: number | null
                  transformedSrc: any
                  largeImageUrl: any
                  mediumImageUrl: any
                  smallImageUrl: any
                  thumbImageUrl: any
                  productSaleImageUrl: any
                }
              }>
            }
            variants: {
              __typename?: 'ProductVariantConnection'
              edges: Array<{
                __typename?: 'ProductVariantEdge'
                node: {
                  __typename?: 'ProductVariant'
                  availableForSale: boolean
                  currentlyNotInStock: boolean
                  id: string
                  quantityAvailable?: number | null
                  requiresShipping: boolean
                  sku?: string | null
                  title: string
                  weight?: number | null
                  weightUnit: Types.WeightUnit
                  compareAtPriceV2?: {
                    __typename?: 'MoneyV2'
                    amount: any
                    currencyCode: Types.CurrencyCode
                  } | null
                  priceV2: {
                    __typename?: 'MoneyV2'
                    amount: any
                    currencyCode: Types.CurrencyCode
                  }
                  selectedOptions: Array<{
                    __typename?: 'SelectedOption'
                    name: string
                    value: string
                  }>
                  unitPrice?: {
                    __typename?: 'MoneyV2'
                    amount: any
                    currencyCode: Types.CurrencyCode
                  } | null
                  image?: {
                    __typename?: 'Image'
                    altText?: string | null
                    height?: number | null
                    id?: string | null
                    originalSrc: any
                    width?: number | null
                    thumbImageUrl: any
                  } | null
                  model?: {
                    __typename?: 'Metafield'
                    createdAt: any
                    description?: string | null
                    id: string
                    key: string
                    namespace: string
                    type: string
                    updatedAt: any
                    value: string
                  } | null
                  edition_profile?: {
                    __typename?: 'Metafield'
                    createdAt: any
                    description?: string | null
                    id: string
                    key: string
                    namespace: string
                    type: string
                    updatedAt: any
                    value: string
                  } | null
                  amazonLink?: {
                    __typename?: 'Metafield'
                    createdAt: any
                    description?: string | null
                    id: string
                    key: string
                    namespace: string
                    type: string
                    updatedAt: any
                    value: string
                  } | null
                  sellingPoints?: {
                    __typename?: 'Metafield'
                    createdAt: any
                    description?: string | null
                    id: string
                    key: string
                    namespace: string
                    type: string
                    updatedAt: any
                    value: string
                  } | null
                }
              }>
            }
            seriesName?: {
              __typename?: 'Metafield'
              createdAt: any
              description?: string | null
              id: string
              key: string
              namespace: string
              type: string
              updatedAt: any
              value: string
            } | null
            howToUse?: {
              __typename?: 'Metafield'
              id: string
              value: string
            } | null
            discountJson?: {
              __typename?: 'Metafield'
              id: string
              value: string
            } | null
            promotionalEndTime?: {
              __typename?: 'Metafield'
              id: string
              value: string
            } | null
          }
          image?: {
            __typename?: 'Image'
            altText?: string | null
            height?: number | null
            id?: string | null
            originalSrc: any
            width?: number | null
          } | null
          model?: {
            __typename?: 'Metafield'
            createdAt: any
            description?: string | null
            id: string
            key: string
            namespace: string
            type: string
            updatedAt: any
            value: string
          } | null
        } | null
        customAttributes: Array<{
          __typename?: 'Attribute'
          key: string
          value?: string | null
        }>
      }
    }>
  }
}

export type ShippingRateFragment = {
  __typename?: 'ShippingRate'
  handle: string
  title: string
  priceV2: {
    __typename?: 'MoneyV2'
    amount: any
    currencyCode: Types.CurrencyCode
  }
}

export type CheckoutLineItemsFragment = {
  __typename?: 'CheckoutLineItemConnection'
  edges: Array<{
    __typename?: 'CheckoutLineItemEdge'
    node: {
      __typename?: 'CheckoutLineItem'
      id: string
      quantity: number
      title: string
      discountAllocations: Array<{
        __typename?: 'DiscountAllocation'
        allocatedAmount: {
          __typename?: 'MoneyV2'
          amount: any
          currencyCode: Types.CurrencyCode
        }
      }>
      variant?: {
        __typename?: 'ProductVariant'
        availableForSale: boolean
        currentlyNotInStock: boolean
        id: string
        quantityAvailable?: number | null
        requiresShipping: boolean
        sku?: string | null
        title: string
        weight?: number | null
        weightUnit: Types.WeightUnit
        compareAtPriceV2?: {
          __typename?: 'MoneyV2'
          amount: any
          currencyCode: Types.CurrencyCode
        } | null
        priceV2: {
          __typename?: 'MoneyV2'
          amount: any
          currencyCode: Types.CurrencyCode
        }
        selectedOptions: Array<{
          __typename?: 'SelectedOption'
          name: string
          value: string
        }>
        unitPrice?: {
          __typename?: 'MoneyV2'
          amount: any
          currencyCode: Types.CurrencyCode
        } | null
        product: {
          __typename?: 'Product'
          availableForSale: boolean
          description: string
          descriptionHtml: any
          handle: string
          id: string
          tags: Array<string>
          productType: string
          title: string
          totalInventory?: number | null
          updatedAt: any
          vendor: string
          compareAtPriceRange: {
            __typename?: 'ProductPriceRange'
            maxVariantPrice: {
              __typename?: 'MoneyV2'
              amount: any
              currencyCode: Types.CurrencyCode
            }
            minVariantPrice: {
              __typename?: 'MoneyV2'
              amount: any
              currencyCode: Types.CurrencyCode
            }
          }
          landingPage?: {
            __typename?: 'Metafield'
            createdAt: any
            description?: string | null
            id: string
            key: string
            namespace: string
            type: string
            updatedAt: any
            value: string
          } | null
          model?: {
            __typename?: 'Metafield'
            createdAt: any
            description?: string | null
            id: string
            key: string
            namespace: string
            type: string
            updatedAt: any
            value: string
          } | null
          sellingPoints?: {
            __typename?: 'Metafield'
            createdAt: any
            description?: string | null
            id: string
            key: string
            namespace: string
            type: string
            updatedAt: any
            value: string
          } | null
          simpleDescription?: {
            __typename?: 'Metafield'
            createdAt: any
            description?: string | null
            id: string
            key: string
            namespace: string
            type: string
            updatedAt: any
            value: string
          } | null
          whatIsInTheBox?: {
            __typename?: 'Metafield'
            createdAt: any
            description?: string | null
            id: string
            key: string
            namespace: string
            type: string
            updatedAt: any
            value: string
          } | null
          specs?: {
            __typename?: 'Metafield'
            createdAt: any
            description?: string | null
            id: string
            key: string
            namespace: string
            type: string
            updatedAt: any
            value: string
          } | null
          faqs?: {
            __typename?: 'Metafield'
            createdAt: any
            description?: string | null
            id: string
            key: string
            namespace: string
            type: string
            updatedAt: any
            value: string
          } | null
          manuals?: {
            __typename?: 'Metafield'
            createdAt: any
            description?: string | null
            id: string
            key: string
            namespace: string
            type: string
            updatedAt: any
            value: string
          } | null
          amazonLink?: {
            __typename?: 'Metafield'
            createdAt: any
            description?: string | null
            id: string
            key: string
            namespace: string
            type: string
            updatedAt: any
            value: string
          } | null
          seo: {
            __typename?: 'SEO'
            title?: string | null
            description?: string | null
          }
          SEO_H1?: {
            __typename?: 'Metafield'
            createdAt: any
            description?: string | null
            id: string
            key: string
            namespace: string
            type: string
            updatedAt: any
            value: string
          } | null
          images: {
            __typename?: 'ImageConnection'
            edges: Array<{
              __typename?: 'ImageEdge'
              node: {
                __typename?: 'Image'
                altText?: string | null
                height?: number | null
                id?: string | null
                originalSrc: any
                width?: number | null
                transformedSrc: any
                largeImageUrl: any
                mediumImageUrl: any
                smallImageUrl: any
                thumbImageUrl: any
                productSaleImageUrl: any
              }
            }>
          }
          variants: {
            __typename?: 'ProductVariantConnection'
            edges: Array<{
              __typename?: 'ProductVariantEdge'
              node: {
                __typename?: 'ProductVariant'
                availableForSale: boolean
                currentlyNotInStock: boolean
                id: string
                quantityAvailable?: number | null
                requiresShipping: boolean
                sku?: string | null
                title: string
                weight?: number | null
                weightUnit: Types.WeightUnit
                compareAtPriceV2?: {
                  __typename?: 'MoneyV2'
                  amount: any
                  currencyCode: Types.CurrencyCode
                } | null
                priceV2: {
                  __typename?: 'MoneyV2'
                  amount: any
                  currencyCode: Types.CurrencyCode
                }
                selectedOptions: Array<{
                  __typename?: 'SelectedOption'
                  name: string
                  value: string
                }>
                unitPrice?: {
                  __typename?: 'MoneyV2'
                  amount: any
                  currencyCode: Types.CurrencyCode
                } | null
                image?: {
                  __typename?: 'Image'
                  altText?: string | null
                  height?: number | null
                  id?: string | null
                  originalSrc: any
                  width?: number | null
                  thumbImageUrl: any
                } | null
                model?: {
                  __typename?: 'Metafield'
                  createdAt: any
                  description?: string | null
                  id: string
                  key: string
                  namespace: string
                  type: string
                  updatedAt: any
                  value: string
                } | null
                edition_profile?: {
                  __typename?: 'Metafield'
                  createdAt: any
                  description?: string | null
                  id: string
                  key: string
                  namespace: string
                  type: string
                  updatedAt: any
                  value: string
                } | null
                amazonLink?: {
                  __typename?: 'Metafield'
                  createdAt: any
                  description?: string | null
                  id: string
                  key: string
                  namespace: string
                  type: string
                  updatedAt: any
                  value: string
                } | null
                sellingPoints?: {
                  __typename?: 'Metafield'
                  createdAt: any
                  description?: string | null
                  id: string
                  key: string
                  namespace: string
                  type: string
                  updatedAt: any
                  value: string
                } | null
              }
            }>
          }
          seriesName?: {
            __typename?: 'Metafield'
            createdAt: any
            description?: string | null
            id: string
            key: string
            namespace: string
            type: string
            updatedAt: any
            value: string
          } | null
          howToUse?: {
            __typename?: 'Metafield'
            id: string
            value: string
          } | null
          discountJson?: {
            __typename?: 'Metafield'
            id: string
            value: string
          } | null
          promotionalEndTime?: {
            __typename?: 'Metafield'
            id: string
            value: string
          } | null
        }
        image?: {
          __typename?: 'Image'
          altText?: string | null
          height?: number | null
          id?: string | null
          originalSrc: any
          width?: number | null
        } | null
        model?: {
          __typename?: 'Metafield'
          createdAt: any
          description?: string | null
          id: string
          key: string
          namespace: string
          type: string
          updatedAt: any
          value: string
        } | null
      } | null
      customAttributes: Array<{
        __typename?: 'Attribute'
        key: string
        value?: string | null
      }>
    }
  }>
}

export type CheckoutLineItemFragment = {
  __typename?: 'CheckoutLineItem'
  id: string
  quantity: number
  title: string
  discountAllocations: Array<{
    __typename?: 'DiscountAllocation'
    allocatedAmount: {
      __typename?: 'MoneyV2'
      amount: any
      currencyCode: Types.CurrencyCode
    }
  }>
  variant?: {
    __typename?: 'ProductVariant'
    availableForSale: boolean
    currentlyNotInStock: boolean
    id: string
    quantityAvailable?: number | null
    requiresShipping: boolean
    sku?: string | null
    title: string
    weight?: number | null
    weightUnit: Types.WeightUnit
    compareAtPriceV2?: {
      __typename?: 'MoneyV2'
      amount: any
      currencyCode: Types.CurrencyCode
    } | null
    priceV2: {
      __typename?: 'MoneyV2'
      amount: any
      currencyCode: Types.CurrencyCode
    }
    selectedOptions: Array<{
      __typename?: 'SelectedOption'
      name: string
      value: string
    }>
    unitPrice?: {
      __typename?: 'MoneyV2'
      amount: any
      currencyCode: Types.CurrencyCode
    } | null
    product: {
      __typename?: 'Product'
      availableForSale: boolean
      description: string
      descriptionHtml: any
      handle: string
      id: string
      tags: Array<string>
      productType: string
      title: string
      totalInventory?: number | null
      updatedAt: any
      vendor: string
      compareAtPriceRange: {
        __typename?: 'ProductPriceRange'
        maxVariantPrice: {
          __typename?: 'MoneyV2'
          amount: any
          currencyCode: Types.CurrencyCode
        }
        minVariantPrice: {
          __typename?: 'MoneyV2'
          amount: any
          currencyCode: Types.CurrencyCode
        }
      }
      landingPage?: {
        __typename?: 'Metafield'
        createdAt: any
        description?: string | null
        id: string
        key: string
        namespace: string
        type: string
        updatedAt: any
        value: string
      } | null
      model?: {
        __typename?: 'Metafield'
        createdAt: any
        description?: string | null
        id: string
        key: string
        namespace: string
        type: string
        updatedAt: any
        value: string
      } | null
      sellingPoints?: {
        __typename?: 'Metafield'
        createdAt: any
        description?: string | null
        id: string
        key: string
        namespace: string
        type: string
        updatedAt: any
        value: string
      } | null
      simpleDescription?: {
        __typename?: 'Metafield'
        createdAt: any
        description?: string | null
        id: string
        key: string
        namespace: string
        type: string
        updatedAt: any
        value: string
      } | null
      whatIsInTheBox?: {
        __typename?: 'Metafield'
        createdAt: any
        description?: string | null
        id: string
        key: string
        namespace: string
        type: string
        updatedAt: any
        value: string
      } | null
      specs?: {
        __typename?: 'Metafield'
        createdAt: any
        description?: string | null
        id: string
        key: string
        namespace: string
        type: string
        updatedAt: any
        value: string
      } | null
      faqs?: {
        __typename?: 'Metafield'
        createdAt: any
        description?: string | null
        id: string
        key: string
        namespace: string
        type: string
        updatedAt: any
        value: string
      } | null
      manuals?: {
        __typename?: 'Metafield'
        createdAt: any
        description?: string | null
        id: string
        key: string
        namespace: string
        type: string
        updatedAt: any
        value: string
      } | null
      amazonLink?: {
        __typename?: 'Metafield'
        createdAt: any
        description?: string | null
        id: string
        key: string
        namespace: string
        type: string
        updatedAt: any
        value: string
      } | null
      seo: {
        __typename?: 'SEO'
        title?: string | null
        description?: string | null
      }
      SEO_H1?: {
        __typename?: 'Metafield'
        createdAt: any
        description?: string | null
        id: string
        key: string
        namespace: string
        type: string
        updatedAt: any
        value: string
      } | null
      images: {
        __typename?: 'ImageConnection'
        edges: Array<{
          __typename?: 'ImageEdge'
          node: {
            __typename?: 'Image'
            altText?: string | null
            height?: number | null
            id?: string | null
            originalSrc: any
            width?: number | null
            transformedSrc: any
            largeImageUrl: any
            mediumImageUrl: any
            smallImageUrl: any
            thumbImageUrl: any
            productSaleImageUrl: any
          }
        }>
      }
      variants: {
        __typename?: 'ProductVariantConnection'
        edges: Array<{
          __typename?: 'ProductVariantEdge'
          node: {
            __typename?: 'ProductVariant'
            availableForSale: boolean
            currentlyNotInStock: boolean
            id: string
            quantityAvailable?: number | null
            requiresShipping: boolean
            sku?: string | null
            title: string
            weight?: number | null
            weightUnit: Types.WeightUnit
            compareAtPriceV2?: {
              __typename?: 'MoneyV2'
              amount: any
              currencyCode: Types.CurrencyCode
            } | null
            priceV2: {
              __typename?: 'MoneyV2'
              amount: any
              currencyCode: Types.CurrencyCode
            }
            selectedOptions: Array<{
              __typename?: 'SelectedOption'
              name: string
              value: string
            }>
            unitPrice?: {
              __typename?: 'MoneyV2'
              amount: any
              currencyCode: Types.CurrencyCode
            } | null
            image?: {
              __typename?: 'Image'
              altText?: string | null
              height?: number | null
              id?: string | null
              originalSrc: any
              width?: number | null
              thumbImageUrl: any
            } | null
            model?: {
              __typename?: 'Metafield'
              createdAt: any
              description?: string | null
              id: string
              key: string
              namespace: string
              type: string
              updatedAt: any
              value: string
            } | null
            edition_profile?: {
              __typename?: 'Metafield'
              createdAt: any
              description?: string | null
              id: string
              key: string
              namespace: string
              type: string
              updatedAt: any
              value: string
            } | null
            amazonLink?: {
              __typename?: 'Metafield'
              createdAt: any
              description?: string | null
              id: string
              key: string
              namespace: string
              type: string
              updatedAt: any
              value: string
            } | null
            sellingPoints?: {
              __typename?: 'Metafield'
              createdAt: any
              description?: string | null
              id: string
              key: string
              namespace: string
              type: string
              updatedAt: any
              value: string
            } | null
          }
        }>
      }
      seriesName?: {
        __typename?: 'Metafield'
        createdAt: any
        description?: string | null
        id: string
        key: string
        namespace: string
        type: string
        updatedAt: any
        value: string
      } | null
      howToUse?: { __typename?: 'Metafield'; id: string; value: string } | null
      discountJson?: {
        __typename?: 'Metafield'
        id: string
        value: string
      } | null
      promotionalEndTime?: {
        __typename?: 'Metafield'
        id: string
        value: string
      } | null
    }
    image?: {
      __typename?: 'Image'
      altText?: string | null
      height?: number | null
      id?: string | null
      originalSrc: any
      width?: number | null
    } | null
    model?: {
      __typename?: 'Metafield'
      createdAt: any
      description?: string | null
      id: string
      key: string
      namespace: string
      type: string
      updatedAt: any
      value: string
    } | null
  } | null
  customAttributes: Array<{
    __typename?: 'Attribute'
    key: string
    value?: string | null
  }>
}

export type MoneyV2Fragment = {
  __typename?: 'MoneyV2'
  amount: any
  currencyCode: Types.CurrencyCode
}

export type CartLineProductVariantFragment = {
  __typename?: 'ProductVariant'
  availableForSale: boolean
  currentlyNotInStock: boolean
  id: string
  quantityAvailable?: number | null
  requiresShipping: boolean
  sku?: string | null
  title: string
  weight?: number | null
  weightUnit: Types.WeightUnit
  compareAtPriceV2?: {
    __typename?: 'MoneyV2'
    amount: any
    currencyCode: Types.CurrencyCode
  } | null
  priceV2: {
    __typename?: 'MoneyV2'
    amount: any
    currencyCode: Types.CurrencyCode
  }
  selectedOptions: Array<{
    __typename?: 'SelectedOption'
    name: string
    value: string
  }>
  unitPrice?: {
    __typename?: 'MoneyV2'
    amount: any
    currencyCode: Types.CurrencyCode
  } | null
  product: {
    __typename?: 'Product'
    availableForSale: boolean
    description: string
    descriptionHtml: any
    handle: string
    id: string
    tags: Array<string>
    productType: string
    title: string
    totalInventory?: number | null
    updatedAt: any
    vendor: string
    compareAtPriceRange: {
      __typename?: 'ProductPriceRange'
      maxVariantPrice: {
        __typename?: 'MoneyV2'
        amount: any
        currencyCode: Types.CurrencyCode
      }
      minVariantPrice: {
        __typename?: 'MoneyV2'
        amount: any
        currencyCode: Types.CurrencyCode
      }
    }
    landingPage?: {
      __typename?: 'Metafield'
      createdAt: any
      description?: string | null
      id: string
      key: string
      namespace: string
      type: string
      updatedAt: any
      value: string
    } | null
    model?: {
      __typename?: 'Metafield'
      createdAt: any
      description?: string | null
      id: string
      key: string
      namespace: string
      type: string
      updatedAt: any
      value: string
    } | null
    sellingPoints?: {
      __typename?: 'Metafield'
      createdAt: any
      description?: string | null
      id: string
      key: string
      namespace: string
      type: string
      updatedAt: any
      value: string
    } | null
    simpleDescription?: {
      __typename?: 'Metafield'
      createdAt: any
      description?: string | null
      id: string
      key: string
      namespace: string
      type: string
      updatedAt: any
      value: string
    } | null
    whatIsInTheBox?: {
      __typename?: 'Metafield'
      createdAt: any
      description?: string | null
      id: string
      key: string
      namespace: string
      type: string
      updatedAt: any
      value: string
    } | null
    specs?: {
      __typename?: 'Metafield'
      createdAt: any
      description?: string | null
      id: string
      key: string
      namespace: string
      type: string
      updatedAt: any
      value: string
    } | null
    faqs?: {
      __typename?: 'Metafield'
      createdAt: any
      description?: string | null
      id: string
      key: string
      namespace: string
      type: string
      updatedAt: any
      value: string
    } | null
    manuals?: {
      __typename?: 'Metafield'
      createdAt: any
      description?: string | null
      id: string
      key: string
      namespace: string
      type: string
      updatedAt: any
      value: string
    } | null
    amazonLink?: {
      __typename?: 'Metafield'
      createdAt: any
      description?: string | null
      id: string
      key: string
      namespace: string
      type: string
      updatedAt: any
      value: string
    } | null
    seo: {
      __typename?: 'SEO'
      title?: string | null
      description?: string | null
    }
    SEO_H1?: {
      __typename?: 'Metafield'
      createdAt: any
      description?: string | null
      id: string
      key: string
      namespace: string
      type: string
      updatedAt: any
      value: string
    } | null
    images: {
      __typename?: 'ImageConnection'
      edges: Array<{
        __typename?: 'ImageEdge'
        node: {
          __typename?: 'Image'
          altText?: string | null
          height?: number | null
          id?: string | null
          originalSrc: any
          width?: number | null
          transformedSrc: any
          largeImageUrl: any
          mediumImageUrl: any
          smallImageUrl: any
          thumbImageUrl: any
          productSaleImageUrl: any
        }
      }>
    }
    variants: {
      __typename?: 'ProductVariantConnection'
      edges: Array<{
        __typename?: 'ProductVariantEdge'
        node: {
          __typename?: 'ProductVariant'
          availableForSale: boolean
          currentlyNotInStock: boolean
          id: string
          quantityAvailable?: number | null
          requiresShipping: boolean
          sku?: string | null
          title: string
          weight?: number | null
          weightUnit: Types.WeightUnit
          compareAtPriceV2?: {
            __typename?: 'MoneyV2'
            amount: any
            currencyCode: Types.CurrencyCode
          } | null
          priceV2: {
            __typename?: 'MoneyV2'
            amount: any
            currencyCode: Types.CurrencyCode
          }
          selectedOptions: Array<{
            __typename?: 'SelectedOption'
            name: string
            value: string
          }>
          unitPrice?: {
            __typename?: 'MoneyV2'
            amount: any
            currencyCode: Types.CurrencyCode
          } | null
          image?: {
            __typename?: 'Image'
            altText?: string | null
            height?: number | null
            id?: string | null
            originalSrc: any
            width?: number | null
            thumbImageUrl: any
          } | null
          model?: {
            __typename?: 'Metafield'
            createdAt: any
            description?: string | null
            id: string
            key: string
            namespace: string
            type: string
            updatedAt: any
            value: string
          } | null
          edition_profile?: {
            __typename?: 'Metafield'
            createdAt: any
            description?: string | null
            id: string
            key: string
            namespace: string
            type: string
            updatedAt: any
            value: string
          } | null
          amazonLink?: {
            __typename?: 'Metafield'
            createdAt: any
            description?: string | null
            id: string
            key: string
            namespace: string
            type: string
            updatedAt: any
            value: string
          } | null
          sellingPoints?: {
            __typename?: 'Metafield'
            createdAt: any
            description?: string | null
            id: string
            key: string
            namespace: string
            type: string
            updatedAt: any
            value: string
          } | null
        }
      }>
    }
    seriesName?: {
      __typename?: 'Metafield'
      createdAt: any
      description?: string | null
      id: string
      key: string
      namespace: string
      type: string
      updatedAt: any
      value: string
    } | null
    howToUse?: { __typename?: 'Metafield'; id: string; value: string } | null
    discountJson?: {
      __typename?: 'Metafield'
      id: string
      value: string
    } | null
    promotionalEndTime?: {
      __typename?: 'Metafield'
      id: string
      value: string
    } | null
  }
  image?: {
    __typename?: 'Image'
    altText?: string | null
    height?: number | null
    id?: string | null
    originalSrc: any
    width?: number | null
  } | null
  model?: {
    __typename?: 'Metafield'
    createdAt: any
    description?: string | null
    id: string
    key: string
    namespace: string
    type: string
    updatedAt: any
    value: string
  } | null
}

export type ProductFragment = {
  __typename?: 'Product'
  availableForSale: boolean
  description: string
  descriptionHtml: any
  handle: string
  id: string
  tags: Array<string>
  productType: string
  title: string
  totalInventory?: number | null
  updatedAt: any
  vendor: string
  compareAtPriceRange: {
    __typename?: 'ProductPriceRange'
    maxVariantPrice: {
      __typename?: 'MoneyV2'
      amount: any
      currencyCode: Types.CurrencyCode
    }
    minVariantPrice: {
      __typename?: 'MoneyV2'
      amount: any
      currencyCode: Types.CurrencyCode
    }
  }
  landingPage?: {
    __typename?: 'Metafield'
    createdAt: any
    description?: string | null
    id: string
    key: string
    namespace: string
    type: string
    updatedAt: any
    value: string
  } | null
  model?: {
    __typename?: 'Metafield'
    createdAt: any
    description?: string | null
    id: string
    key: string
    namespace: string
    type: string
    updatedAt: any
    value: string
  } | null
  sellingPoints?: {
    __typename?: 'Metafield'
    createdAt: any
    description?: string | null
    id: string
    key: string
    namespace: string
    type: string
    updatedAt: any
    value: string
  } | null
  simpleDescription?: {
    __typename?: 'Metafield'
    createdAt: any
    description?: string | null
    id: string
    key: string
    namespace: string
    type: string
    updatedAt: any
    value: string
  } | null
  whatIsInTheBox?: {
    __typename?: 'Metafield'
    createdAt: any
    description?: string | null
    id: string
    key: string
    namespace: string
    type: string
    updatedAt: any
    value: string
  } | null
  specs?: {
    __typename?: 'Metafield'
    createdAt: any
    description?: string | null
    id: string
    key: string
    namespace: string
    type: string
    updatedAt: any
    value: string
  } | null
  faqs?: {
    __typename?: 'Metafield'
    createdAt: any
    description?: string | null
    id: string
    key: string
    namespace: string
    type: string
    updatedAt: any
    value: string
  } | null
  manuals?: {
    __typename?: 'Metafield'
    createdAt: any
    description?: string | null
    id: string
    key: string
    namespace: string
    type: string
    updatedAt: any
    value: string
  } | null
  amazonLink?: {
    __typename?: 'Metafield'
    createdAt: any
    description?: string | null
    id: string
    key: string
    namespace: string
    type: string
    updatedAt: any
    value: string
  } | null
  seo: {
    __typename?: 'SEO'
    title?: string | null
    description?: string | null
  }
  SEO_H1?: {
    __typename?: 'Metafield'
    createdAt: any
    description?: string | null
    id: string
    key: string
    namespace: string
    type: string
    updatedAt: any
    value: string
  } | null
  images: {
    __typename?: 'ImageConnection'
    edges: Array<{
      __typename?: 'ImageEdge'
      node: {
        __typename?: 'Image'
        altText?: string | null
        height?: number | null
        id?: string | null
        originalSrc: any
        width?: number | null
        transformedSrc: any
        largeImageUrl: any
        mediumImageUrl: any
        smallImageUrl: any
        thumbImageUrl: any
        productSaleImageUrl: any
      }
    }>
  }
  variants: {
    __typename?: 'ProductVariantConnection'
    edges: Array<{
      __typename?: 'ProductVariantEdge'
      node: {
        __typename?: 'ProductVariant'
        availableForSale: boolean
        currentlyNotInStock: boolean
        id: string
        quantityAvailable?: number | null
        requiresShipping: boolean
        sku?: string | null
        title: string
        weight?: number | null
        weightUnit: Types.WeightUnit
        compareAtPriceV2?: {
          __typename?: 'MoneyV2'
          amount: any
          currencyCode: Types.CurrencyCode
        } | null
        priceV2: {
          __typename?: 'MoneyV2'
          amount: any
          currencyCode: Types.CurrencyCode
        }
        selectedOptions: Array<{
          __typename?: 'SelectedOption'
          name: string
          value: string
        }>
        unitPrice?: {
          __typename?: 'MoneyV2'
          amount: any
          currencyCode: Types.CurrencyCode
        } | null
        image?: {
          __typename?: 'Image'
          altText?: string | null
          height?: number | null
          id?: string | null
          originalSrc: any
          width?: number | null
          thumbImageUrl: any
        } | null
        model?: {
          __typename?: 'Metafield'
          createdAt: any
          description?: string | null
          id: string
          key: string
          namespace: string
          type: string
          updatedAt: any
          value: string
        } | null
        edition_profile?: {
          __typename?: 'Metafield'
          createdAt: any
          description?: string | null
          id: string
          key: string
          namespace: string
          type: string
          updatedAt: any
          value: string
        } | null
        amazonLink?: {
          __typename?: 'Metafield'
          createdAt: any
          description?: string | null
          id: string
          key: string
          namespace: string
          type: string
          updatedAt: any
          value: string
        } | null
        sellingPoints?: {
          __typename?: 'Metafield'
          createdAt: any
          description?: string | null
          id: string
          key: string
          namespace: string
          type: string
          updatedAt: any
          value: string
        } | null
      }
    }>
  }
  seriesName?: {
    __typename?: 'Metafield'
    createdAt: any
    description?: string | null
    id: string
    key: string
    namespace: string
    type: string
    updatedAt: any
    value: string
  } | null
  howToUse?: { __typename?: 'Metafield'; id: string; value: string } | null
  discountJson?: { __typename?: 'Metafield'; id: string; value: string } | null
  promotionalEndTime?: {
    __typename?: 'Metafield'
    id: string
    value: string
  } | null
}

export type MetafieldFragment = {
  __typename?: 'Metafield'
  createdAt: any
  description?: string | null
  id: string
  key: string
  namespace: string
  type: string
  updatedAt: any
  value: string
}

export type ProductImageFragment = {
  __typename?: 'Image'
  altText?: string | null
  height?: number | null
  id?: string | null
  originalSrc: any
  width?: number | null
  transformedSrc: any
  largeImageUrl: any
  mediumImageUrl: any
  smallImageUrl: any
  thumbImageUrl: any
  productSaleImageUrl: any
}

export type ProductVariantsFragment = {
  __typename?: 'ProductVariantConnection'
  edges: Array<{
    __typename?: 'ProductVariantEdge'
    node: {
      __typename?: 'ProductVariant'
      availableForSale: boolean
      currentlyNotInStock: boolean
      id: string
      quantityAvailable?: number | null
      requiresShipping: boolean
      sku?: string | null
      title: string
      weight?: number | null
      weightUnit: Types.WeightUnit
      compareAtPriceV2?: {
        __typename?: 'MoneyV2'
        amount: any
        currencyCode: Types.CurrencyCode
      } | null
      priceV2: {
        __typename?: 'MoneyV2'
        amount: any
        currencyCode: Types.CurrencyCode
      }
      selectedOptions: Array<{
        __typename?: 'SelectedOption'
        name: string
        value: string
      }>
      unitPrice?: {
        __typename?: 'MoneyV2'
        amount: any
        currencyCode: Types.CurrencyCode
      } | null
      image?: {
        __typename?: 'Image'
        altText?: string | null
        height?: number | null
        id?: string | null
        originalSrc: any
        width?: number | null
        thumbImageUrl: any
      } | null
      model?: {
        __typename?: 'Metafield'
        createdAt: any
        description?: string | null
        id: string
        key: string
        namespace: string
        type: string
        updatedAt: any
        value: string
      } | null
      edition_profile?: {
        __typename?: 'Metafield'
        createdAt: any
        description?: string | null
        id: string
        key: string
        namespace: string
        type: string
        updatedAt: any
        value: string
      } | null
      amazonLink?: {
        __typename?: 'Metafield'
        createdAt: any
        description?: string | null
        id: string
        key: string
        namespace: string
        type: string
        updatedAt: any
        value: string
      } | null
      sellingPoints?: {
        __typename?: 'Metafield'
        createdAt: any
        description?: string | null
        id: string
        key: string
        namespace: string
        type: string
        updatedAt: any
        value: string
      } | null
    }
  }>
}

export type ProductVariantFragment = {
  __typename?: 'ProductVariant'
  availableForSale: boolean
  currentlyNotInStock: boolean
  id: string
  quantityAvailable?: number | null
  requiresShipping: boolean
  sku?: string | null
  title: string
  weight?: number | null
  weightUnit: Types.WeightUnit
  compareAtPriceV2?: {
    __typename?: 'MoneyV2'
    amount: any
    currencyCode: Types.CurrencyCode
  } | null
  priceV2: {
    __typename?: 'MoneyV2'
    amount: any
    currencyCode: Types.CurrencyCode
  }
  selectedOptions: Array<{
    __typename?: 'SelectedOption'
    name: string
    value: string
  }>
  unitPrice?: {
    __typename?: 'MoneyV2'
    amount: any
    currencyCode: Types.CurrencyCode
  } | null
  image?: {
    __typename?: 'Image'
    altText?: string | null
    height?: number | null
    id?: string | null
    originalSrc: any
    width?: number | null
    thumbImageUrl: any
  } | null
  model?: {
    __typename?: 'Metafield'
    createdAt: any
    description?: string | null
    id: string
    key: string
    namespace: string
    type: string
    updatedAt: any
    value: string
  } | null
  edition_profile?: {
    __typename?: 'Metafield'
    createdAt: any
    description?: string | null
    id: string
    key: string
    namespace: string
    type: string
    updatedAt: any
    value: string
  } | null
  amazonLink?: {
    __typename?: 'Metafield'
    createdAt: any
    description?: string | null
    id: string
    key: string
    namespace: string
    type: string
    updatedAt: any
    value: string
  } | null
  sellingPoints?: {
    __typename?: 'Metafield'
    createdAt: any
    description?: string | null
    id: string
    key: string
    namespace: string
    type: string
    updatedAt: any
    value: string
  } | null
}

export type ImageFragment = {
  __typename?: 'Image'
  altText?: string | null
  height?: number | null
  id?: string | null
  originalSrc: any
  width?: number | null
}

export type MetaFieldLessFragment = {
  __typename?: 'Metafield'
  id: string
  value: string
}

export type CheckoutLineItemsAddMutationVariables = Types.Exact<{
  lineItems: Array<Types.CheckoutLineItemInput> | Types.CheckoutLineItemInput
  checkoutId: Types.Scalars['ID']
}>

export type CheckoutLineItemsAddMutation = {
  __typename?: 'Mutation'
  checkoutLineItemsAdd?: {
    __typename?: 'CheckoutLineItemsAddPayload'
    checkout?: {
      __typename?: 'Checkout'
      completedAt?: any | null
      createdAt: any
      currencyCode: Types.CurrencyCode
      email?: string | null
      id: string
      webUrl: any
      taxesIncluded: boolean
      taxExempt: boolean
      shippingDiscountAllocations: Array<{
        __typename?: 'DiscountAllocation'
        allocatedAmount: {
          __typename?: 'MoneyV2'
          amount: any
          currencyCode: Types.CurrencyCode
        }
      }>
      shippingLine?: {
        __typename?: 'ShippingRate'
        handle: string
        title: string
        priceV2: {
          __typename?: 'MoneyV2'
          amount: any
          currencyCode: Types.CurrencyCode
        }
      } | null
      lineItemsSubtotalPrice: {
        __typename?: 'MoneyV2'
        amount: any
        currencyCode: Types.CurrencyCode
      }
      subtotalPriceV2: {
        __typename?: 'MoneyV2'
        amount: any
        currencyCode: Types.CurrencyCode
      }
      totalDuties?: {
        __typename?: 'MoneyV2'
        amount: any
        currencyCode: Types.CurrencyCode
      } | null
      totalPriceV2: {
        __typename?: 'MoneyV2'
        amount: any
        currencyCode: Types.CurrencyCode
      }
      totalTaxV2: {
        __typename?: 'MoneyV2'
        amount: any
        currencyCode: Types.CurrencyCode
      }
      lineItems: {
        __typename?: 'CheckoutLineItemConnection'
        edges: Array<{
          __typename?: 'CheckoutLineItemEdge'
          node: {
            __typename?: 'CheckoutLineItem'
            id: string
            quantity: number
            title: string
            discountAllocations: Array<{
              __typename?: 'DiscountAllocation'
              allocatedAmount: {
                __typename?: 'MoneyV2'
                amount: any
                currencyCode: Types.CurrencyCode
              }
            }>
            variant?: {
              __typename?: 'ProductVariant'
              availableForSale: boolean
              currentlyNotInStock: boolean
              id: string
              quantityAvailable?: number | null
              requiresShipping: boolean
              sku?: string | null
              title: string
              weight?: number | null
              weightUnit: Types.WeightUnit
              compareAtPriceV2?: {
                __typename?: 'MoneyV2'
                amount: any
                currencyCode: Types.CurrencyCode
              } | null
              priceV2: {
                __typename?: 'MoneyV2'
                amount: any
                currencyCode: Types.CurrencyCode
              }
              selectedOptions: Array<{
                __typename?: 'SelectedOption'
                name: string
                value: string
              }>
              unitPrice?: {
                __typename?: 'MoneyV2'
                amount: any
                currencyCode: Types.CurrencyCode
              } | null
              product: {
                __typename?: 'Product'
                availableForSale: boolean
                description: string
                descriptionHtml: any
                handle: string
                id: string
                tags: Array<string>
                productType: string
                title: string
                totalInventory?: number | null
                updatedAt: any
                vendor: string
                compareAtPriceRange: {
                  __typename?: 'ProductPriceRange'
                  maxVariantPrice: {
                    __typename?: 'MoneyV2'
                    amount: any
                    currencyCode: Types.CurrencyCode
                  }
                  minVariantPrice: {
                    __typename?: 'MoneyV2'
                    amount: any
                    currencyCode: Types.CurrencyCode
                  }
                }
                landingPage?: {
                  __typename?: 'Metafield'
                  createdAt: any
                  description?: string | null
                  id: string
                  key: string
                  namespace: string
                  type: string
                  updatedAt: any
                  value: string
                } | null
                model?: {
                  __typename?: 'Metafield'
                  createdAt: any
                  description?: string | null
                  id: string
                  key: string
                  namespace: string
                  type: string
                  updatedAt: any
                  value: string
                } | null
                sellingPoints?: {
                  __typename?: 'Metafield'
                  createdAt: any
                  description?: string | null
                  id: string
                  key: string
                  namespace: string
                  type: string
                  updatedAt: any
                  value: string
                } | null
                simpleDescription?: {
                  __typename?: 'Metafield'
                  createdAt: any
                  description?: string | null
                  id: string
                  key: string
                  namespace: string
                  type: string
                  updatedAt: any
                  value: string
                } | null
                whatIsInTheBox?: {
                  __typename?: 'Metafield'
                  createdAt: any
                  description?: string | null
                  id: string
                  key: string
                  namespace: string
                  type: string
                  updatedAt: any
                  value: string
                } | null
                specs?: {
                  __typename?: 'Metafield'
                  createdAt: any
                  description?: string | null
                  id: string
                  key: string
                  namespace: string
                  type: string
                  updatedAt: any
                  value: string
                } | null
                faqs?: {
                  __typename?: 'Metafield'
                  createdAt: any
                  description?: string | null
                  id: string
                  key: string
                  namespace: string
                  type: string
                  updatedAt: any
                  value: string
                } | null
                manuals?: {
                  __typename?: 'Metafield'
                  createdAt: any
                  description?: string | null
                  id: string
                  key: string
                  namespace: string
                  type: string
                  updatedAt: any
                  value: string
                } | null
                amazonLink?: {
                  __typename?: 'Metafield'
                  createdAt: any
                  description?: string | null
                  id: string
                  key: string
                  namespace: string
                  type: string
                  updatedAt: any
                  value: string
                } | null
                seo: {
                  __typename?: 'SEO'
                  title?: string | null
                  description?: string | null
                }
                SEO_H1?: {
                  __typename?: 'Metafield'
                  createdAt: any
                  description?: string | null
                  id: string
                  key: string
                  namespace: string
                  type: string
                  updatedAt: any
                  value: string
                } | null
                images: {
                  __typename?: 'ImageConnection'
                  edges: Array<{
                    __typename?: 'ImageEdge'
                    node: {
                      __typename?: 'Image'
                      altText?: string | null
                      height?: number | null
                      id?: string | null
                      originalSrc: any
                      width?: number | null
                      transformedSrc: any
                      largeImageUrl: any
                      mediumImageUrl: any
                      smallImageUrl: any
                      thumbImageUrl: any
                      productSaleImageUrl: any
                    }
                  }>
                }
                variants: {
                  __typename?: 'ProductVariantConnection'
                  edges: Array<{
                    __typename?: 'ProductVariantEdge'
                    node: {
                      __typename?: 'ProductVariant'
                      availableForSale: boolean
                      currentlyNotInStock: boolean
                      id: string
                      quantityAvailable?: number | null
                      requiresShipping: boolean
                      sku?: string | null
                      title: string
                      weight?: number | null
                      weightUnit: Types.WeightUnit
                      compareAtPriceV2?: {
                        __typename?: 'MoneyV2'
                        amount: any
                        currencyCode: Types.CurrencyCode
                      } | null
                      priceV2: {
                        __typename?: 'MoneyV2'
                        amount: any
                        currencyCode: Types.CurrencyCode
                      }
                      selectedOptions: Array<{
                        __typename?: 'SelectedOption'
                        name: string
                        value: string
                      }>
                      unitPrice?: {
                        __typename?: 'MoneyV2'
                        amount: any
                        currencyCode: Types.CurrencyCode
                      } | null
                      image?: {
                        __typename?: 'Image'
                        altText?: string | null
                        height?: number | null
                        id?: string | null
                        originalSrc: any
                        width?: number | null
                        thumbImageUrl: any
                      } | null
                      model?: {
                        __typename?: 'Metafield'
                        createdAt: any
                        description?: string | null
                        id: string
                        key: string
                        namespace: string
                        type: string
                        updatedAt: any
                        value: string
                      } | null
                      edition_profile?: {
                        __typename?: 'Metafield'
                        createdAt: any
                        description?: string | null
                        id: string
                        key: string
                        namespace: string
                        type: string
                        updatedAt: any
                        value: string
                      } | null
                      amazonLink?: {
                        __typename?: 'Metafield'
                        createdAt: any
                        description?: string | null
                        id: string
                        key: string
                        namespace: string
                        type: string
                        updatedAt: any
                        value: string
                      } | null
                      sellingPoints?: {
                        __typename?: 'Metafield'
                        createdAt: any
                        description?: string | null
                        id: string
                        key: string
                        namespace: string
                        type: string
                        updatedAt: any
                        value: string
                      } | null
                    }
                  }>
                }
                seriesName?: {
                  __typename?: 'Metafield'
                  createdAt: any
                  description?: string | null
                  id: string
                  key: string
                  namespace: string
                  type: string
                  updatedAt: any
                  value: string
                } | null
                howToUse?: {
                  __typename?: 'Metafield'
                  id: string
                  value: string
                } | null
                discountJson?: {
                  __typename?: 'Metafield'
                  id: string
                  value: string
                } | null
                promotionalEndTime?: {
                  __typename?: 'Metafield'
                  id: string
                  value: string
                } | null
              }
              image?: {
                __typename?: 'Image'
                altText?: string | null
                height?: number | null
                id?: string | null
                originalSrc: any
                width?: number | null
              } | null
              model?: {
                __typename?: 'Metafield'
                createdAt: any
                description?: string | null
                id: string
                key: string
                namespace: string
                type: string
                updatedAt: any
                value: string
              } | null
            } | null
            customAttributes: Array<{
              __typename?: 'Attribute'
              key: string
              value?: string | null
            }>
          }
        }>
      }
    } | null
    checkoutUserErrors: Array<{
      __typename?: 'CheckoutUserError'
      code?: Types.CheckoutErrorCode | null
      field?: Array<string> | null
      message: string
    }>
  } | null
}

export type CheckoutLineItemsUpdateMutationVariables = Types.Exact<{
  checkoutId: Types.Scalars['ID']
  lineItems:
    | Array<Types.CheckoutLineItemUpdateInput>
    | Types.CheckoutLineItemUpdateInput
}>

export type CheckoutLineItemsUpdateMutation = {
  __typename?: 'Mutation'
  checkoutLineItemsUpdate?: {
    __typename?: 'CheckoutLineItemsUpdatePayload'
    checkout?: {
      __typename?: 'Checkout'
      completedAt?: any | null
      createdAt: any
      currencyCode: Types.CurrencyCode
      email?: string | null
      id: string
      webUrl: any
      taxesIncluded: boolean
      taxExempt: boolean
      shippingDiscountAllocations: Array<{
        __typename?: 'DiscountAllocation'
        allocatedAmount: {
          __typename?: 'MoneyV2'
          amount: any
          currencyCode: Types.CurrencyCode
        }
      }>
      shippingLine?: {
        __typename?: 'ShippingRate'
        handle: string
        title: string
        priceV2: {
          __typename?: 'MoneyV2'
          amount: any
          currencyCode: Types.CurrencyCode
        }
      } | null
      lineItemsSubtotalPrice: {
        __typename?: 'MoneyV2'
        amount: any
        currencyCode: Types.CurrencyCode
      }
      subtotalPriceV2: {
        __typename?: 'MoneyV2'
        amount: any
        currencyCode: Types.CurrencyCode
      }
      totalDuties?: {
        __typename?: 'MoneyV2'
        amount: any
        currencyCode: Types.CurrencyCode
      } | null
      totalPriceV2: {
        __typename?: 'MoneyV2'
        amount: any
        currencyCode: Types.CurrencyCode
      }
      totalTaxV2: {
        __typename?: 'MoneyV2'
        amount: any
        currencyCode: Types.CurrencyCode
      }
      lineItems: {
        __typename?: 'CheckoutLineItemConnection'
        edges: Array<{
          __typename?: 'CheckoutLineItemEdge'
          node: {
            __typename?: 'CheckoutLineItem'
            id: string
            quantity: number
            title: string
            discountAllocations: Array<{
              __typename?: 'DiscountAllocation'
              allocatedAmount: {
                __typename?: 'MoneyV2'
                amount: any
                currencyCode: Types.CurrencyCode
              }
            }>
            variant?: {
              __typename?: 'ProductVariant'
              availableForSale: boolean
              currentlyNotInStock: boolean
              id: string
              quantityAvailable?: number | null
              requiresShipping: boolean
              sku?: string | null
              title: string
              weight?: number | null
              weightUnit: Types.WeightUnit
              compareAtPriceV2?: {
                __typename?: 'MoneyV2'
                amount: any
                currencyCode: Types.CurrencyCode
              } | null
              priceV2: {
                __typename?: 'MoneyV2'
                amount: any
                currencyCode: Types.CurrencyCode
              }
              selectedOptions: Array<{
                __typename?: 'SelectedOption'
                name: string
                value: string
              }>
              unitPrice?: {
                __typename?: 'MoneyV2'
                amount: any
                currencyCode: Types.CurrencyCode
              } | null
              product: {
                __typename?: 'Product'
                availableForSale: boolean
                description: string
                descriptionHtml: any
                handle: string
                id: string
                tags: Array<string>
                productType: string
                title: string
                totalInventory?: number | null
                updatedAt: any
                vendor: string
                compareAtPriceRange: {
                  __typename?: 'ProductPriceRange'
                  maxVariantPrice: {
                    __typename?: 'MoneyV2'
                    amount: any
                    currencyCode: Types.CurrencyCode
                  }
                  minVariantPrice: {
                    __typename?: 'MoneyV2'
                    amount: any
                    currencyCode: Types.CurrencyCode
                  }
                }
                landingPage?: {
                  __typename?: 'Metafield'
                  createdAt: any
                  description?: string | null
                  id: string
                  key: string
                  namespace: string
                  type: string
                  updatedAt: any
                  value: string
                } | null
                model?: {
                  __typename?: 'Metafield'
                  createdAt: any
                  description?: string | null
                  id: string
                  key: string
                  namespace: string
                  type: string
                  updatedAt: any
                  value: string
                } | null
                sellingPoints?: {
                  __typename?: 'Metafield'
                  createdAt: any
                  description?: string | null
                  id: string
                  key: string
                  namespace: string
                  type: string
                  updatedAt: any
                  value: string
                } | null
                simpleDescription?: {
                  __typename?: 'Metafield'
                  createdAt: any
                  description?: string | null
                  id: string
                  key: string
                  namespace: string
                  type: string
                  updatedAt: any
                  value: string
                } | null
                whatIsInTheBox?: {
                  __typename?: 'Metafield'
                  createdAt: any
                  description?: string | null
                  id: string
                  key: string
                  namespace: string
                  type: string
                  updatedAt: any
                  value: string
                } | null
                specs?: {
                  __typename?: 'Metafield'
                  createdAt: any
                  description?: string | null
                  id: string
                  key: string
                  namespace: string
                  type: string
                  updatedAt: any
                  value: string
                } | null
                faqs?: {
                  __typename?: 'Metafield'
                  createdAt: any
                  description?: string | null
                  id: string
                  key: string
                  namespace: string
                  type: string
                  updatedAt: any
                  value: string
                } | null
                manuals?: {
                  __typename?: 'Metafield'
                  createdAt: any
                  description?: string | null
                  id: string
                  key: string
                  namespace: string
                  type: string
                  updatedAt: any
                  value: string
                } | null
                amazonLink?: {
                  __typename?: 'Metafield'
                  createdAt: any
                  description?: string | null
                  id: string
                  key: string
                  namespace: string
                  type: string
                  updatedAt: any
                  value: string
                } | null
                seo: {
                  __typename?: 'SEO'
                  title?: string | null
                  description?: string | null
                }
                SEO_H1?: {
                  __typename?: 'Metafield'
                  createdAt: any
                  description?: string | null
                  id: string
                  key: string
                  namespace: string
                  type: string
                  updatedAt: any
                  value: string
                } | null
                images: {
                  __typename?: 'ImageConnection'
                  edges: Array<{
                    __typename?: 'ImageEdge'
                    node: {
                      __typename?: 'Image'
                      altText?: string | null
                      height?: number | null
                      id?: string | null
                      originalSrc: any
                      width?: number | null
                      transformedSrc: any
                      largeImageUrl: any
                      mediumImageUrl: any
                      smallImageUrl: any
                      thumbImageUrl: any
                      productSaleImageUrl: any
                    }
                  }>
                }
                variants: {
                  __typename?: 'ProductVariantConnection'
                  edges: Array<{
                    __typename?: 'ProductVariantEdge'
                    node: {
                      __typename?: 'ProductVariant'
                      availableForSale: boolean
                      currentlyNotInStock: boolean
                      id: string
                      quantityAvailable?: number | null
                      requiresShipping: boolean
                      sku?: string | null
                      title: string
                      weight?: number | null
                      weightUnit: Types.WeightUnit
                      compareAtPriceV2?: {
                        __typename?: 'MoneyV2'
                        amount: any
                        currencyCode: Types.CurrencyCode
                      } | null
                      priceV2: {
                        __typename?: 'MoneyV2'
                        amount: any
                        currencyCode: Types.CurrencyCode
                      }
                      selectedOptions: Array<{
                        __typename?: 'SelectedOption'
                        name: string
                        value: string
                      }>
                      unitPrice?: {
                        __typename?: 'MoneyV2'
                        amount: any
                        currencyCode: Types.CurrencyCode
                      } | null
                      image?: {
                        __typename?: 'Image'
                        altText?: string | null
                        height?: number | null
                        id?: string | null
                        originalSrc: any
                        width?: number | null
                        thumbImageUrl: any
                      } | null
                      model?: {
                        __typename?: 'Metafield'
                        createdAt: any
                        description?: string | null
                        id: string
                        key: string
                        namespace: string
                        type: string
                        updatedAt: any
                        value: string
                      } | null
                      edition_profile?: {
                        __typename?: 'Metafield'
                        createdAt: any
                        description?: string | null
                        id: string
                        key: string
                        namespace: string
                        type: string
                        updatedAt: any
                        value: string
                      } | null
                      amazonLink?: {
                        __typename?: 'Metafield'
                        createdAt: any
                        description?: string | null
                        id: string
                        key: string
                        namespace: string
                        type: string
                        updatedAt: any
                        value: string
                      } | null
                      sellingPoints?: {
                        __typename?: 'Metafield'
                        createdAt: any
                        description?: string | null
                        id: string
                        key: string
                        namespace: string
                        type: string
                        updatedAt: any
                        value: string
                      } | null
                    }
                  }>
                }
                seriesName?: {
                  __typename?: 'Metafield'
                  createdAt: any
                  description?: string | null
                  id: string
                  key: string
                  namespace: string
                  type: string
                  updatedAt: any
                  value: string
                } | null
                howToUse?: {
                  __typename?: 'Metafield'
                  id: string
                  value: string
                } | null
                discountJson?: {
                  __typename?: 'Metafield'
                  id: string
                  value: string
                } | null
                promotionalEndTime?: {
                  __typename?: 'Metafield'
                  id: string
                  value: string
                } | null
              }
              image?: {
                __typename?: 'Image'
                altText?: string | null
                height?: number | null
                id?: string | null
                originalSrc: any
                width?: number | null
              } | null
              model?: {
                __typename?: 'Metafield'
                createdAt: any
                description?: string | null
                id: string
                key: string
                namespace: string
                type: string
                updatedAt: any
                value: string
              } | null
            } | null
            customAttributes: Array<{
              __typename?: 'Attribute'
              key: string
              value?: string | null
            }>
          }
        }>
      }
    } | null
    checkoutUserErrors: Array<{
      __typename?: 'CheckoutUserError'
      code?: Types.CheckoutErrorCode | null
      field?: Array<string> | null
      message: string
    }>
  } | null
}

export type CheckoutLineItemsRemoveMutationVariables = Types.Exact<{
  checkoutId: Types.Scalars['ID']
  lineItemIds: Array<Types.Scalars['ID']> | Types.Scalars['ID']
}>

export type CheckoutLineItemsRemoveMutation = {
  __typename?: 'Mutation'
  checkoutLineItemsRemove?: {
    __typename?: 'CheckoutLineItemsRemovePayload'
    checkout?: {
      __typename?: 'Checkout'
      completedAt?: any | null
      createdAt: any
      currencyCode: Types.CurrencyCode
      email?: string | null
      id: string
      webUrl: any
      taxesIncluded: boolean
      taxExempt: boolean
      shippingDiscountAllocations: Array<{
        __typename?: 'DiscountAllocation'
        allocatedAmount: {
          __typename?: 'MoneyV2'
          amount: any
          currencyCode: Types.CurrencyCode
        }
      }>
      shippingLine?: {
        __typename?: 'ShippingRate'
        handle: string
        title: string
        priceV2: {
          __typename?: 'MoneyV2'
          amount: any
          currencyCode: Types.CurrencyCode
        }
      } | null
      lineItemsSubtotalPrice: {
        __typename?: 'MoneyV2'
        amount: any
        currencyCode: Types.CurrencyCode
      }
      subtotalPriceV2: {
        __typename?: 'MoneyV2'
        amount: any
        currencyCode: Types.CurrencyCode
      }
      totalDuties?: {
        __typename?: 'MoneyV2'
        amount: any
        currencyCode: Types.CurrencyCode
      } | null
      totalPriceV2: {
        __typename?: 'MoneyV2'
        amount: any
        currencyCode: Types.CurrencyCode
      }
      totalTaxV2: {
        __typename?: 'MoneyV2'
        amount: any
        currencyCode: Types.CurrencyCode
      }
      lineItems: {
        __typename?: 'CheckoutLineItemConnection'
        edges: Array<{
          __typename?: 'CheckoutLineItemEdge'
          node: {
            __typename?: 'CheckoutLineItem'
            id: string
            quantity: number
            title: string
            discountAllocations: Array<{
              __typename?: 'DiscountAllocation'
              allocatedAmount: {
                __typename?: 'MoneyV2'
                amount: any
                currencyCode: Types.CurrencyCode
              }
            }>
            variant?: {
              __typename?: 'ProductVariant'
              availableForSale: boolean
              currentlyNotInStock: boolean
              id: string
              quantityAvailable?: number | null
              requiresShipping: boolean
              sku?: string | null
              title: string
              weight?: number | null
              weightUnit: Types.WeightUnit
              compareAtPriceV2?: {
                __typename?: 'MoneyV2'
                amount: any
                currencyCode: Types.CurrencyCode
              } | null
              priceV2: {
                __typename?: 'MoneyV2'
                amount: any
                currencyCode: Types.CurrencyCode
              }
              selectedOptions: Array<{
                __typename?: 'SelectedOption'
                name: string
                value: string
              }>
              unitPrice?: {
                __typename?: 'MoneyV2'
                amount: any
                currencyCode: Types.CurrencyCode
              } | null
              product: {
                __typename?: 'Product'
                availableForSale: boolean
                description: string
                descriptionHtml: any
                handle: string
                id: string
                tags: Array<string>
                productType: string
                title: string
                totalInventory?: number | null
                updatedAt: any
                vendor: string
                compareAtPriceRange: {
                  __typename?: 'ProductPriceRange'
                  maxVariantPrice: {
                    __typename?: 'MoneyV2'
                    amount: any
                    currencyCode: Types.CurrencyCode
                  }
                  minVariantPrice: {
                    __typename?: 'MoneyV2'
                    amount: any
                    currencyCode: Types.CurrencyCode
                  }
                }
                landingPage?: {
                  __typename?: 'Metafield'
                  createdAt: any
                  description?: string | null
                  id: string
                  key: string
                  namespace: string
                  type: string
                  updatedAt: any
                  value: string
                } | null
                model?: {
                  __typename?: 'Metafield'
                  createdAt: any
                  description?: string | null
                  id: string
                  key: string
                  namespace: string
                  type: string
                  updatedAt: any
                  value: string
                } | null
                sellingPoints?: {
                  __typename?: 'Metafield'
                  createdAt: any
                  description?: string | null
                  id: string
                  key: string
                  namespace: string
                  type: string
                  updatedAt: any
                  value: string
                } | null
                simpleDescription?: {
                  __typename?: 'Metafield'
                  createdAt: any
                  description?: string | null
                  id: string
                  key: string
                  namespace: string
                  type: string
                  updatedAt: any
                  value: string
                } | null
                whatIsInTheBox?: {
                  __typename?: 'Metafield'
                  createdAt: any
                  description?: string | null
                  id: string
                  key: string
                  namespace: string
                  type: string
                  updatedAt: any
                  value: string
                } | null
                specs?: {
                  __typename?: 'Metafield'
                  createdAt: any
                  description?: string | null
                  id: string
                  key: string
                  namespace: string
                  type: string
                  updatedAt: any
                  value: string
                } | null
                faqs?: {
                  __typename?: 'Metafield'
                  createdAt: any
                  description?: string | null
                  id: string
                  key: string
                  namespace: string
                  type: string
                  updatedAt: any
                  value: string
                } | null
                manuals?: {
                  __typename?: 'Metafield'
                  createdAt: any
                  description?: string | null
                  id: string
                  key: string
                  namespace: string
                  type: string
                  updatedAt: any
                  value: string
                } | null
                amazonLink?: {
                  __typename?: 'Metafield'
                  createdAt: any
                  description?: string | null
                  id: string
                  key: string
                  namespace: string
                  type: string
                  updatedAt: any
                  value: string
                } | null
                seo: {
                  __typename?: 'SEO'
                  title?: string | null
                  description?: string | null
                }
                SEO_H1?: {
                  __typename?: 'Metafield'
                  createdAt: any
                  description?: string | null
                  id: string
                  key: string
                  namespace: string
                  type: string
                  updatedAt: any
                  value: string
                } | null
                images: {
                  __typename?: 'ImageConnection'
                  edges: Array<{
                    __typename?: 'ImageEdge'
                    node: {
                      __typename?: 'Image'
                      altText?: string | null
                      height?: number | null
                      id?: string | null
                      originalSrc: any
                      width?: number | null
                      transformedSrc: any
                      largeImageUrl: any
                      mediumImageUrl: any
                      smallImageUrl: any
                      thumbImageUrl: any
                      productSaleImageUrl: any
                    }
                  }>
                }
                variants: {
                  __typename?: 'ProductVariantConnection'
                  edges: Array<{
                    __typename?: 'ProductVariantEdge'
                    node: {
                      __typename?: 'ProductVariant'
                      availableForSale: boolean
                      currentlyNotInStock: boolean
                      id: string
                      quantityAvailable?: number | null
                      requiresShipping: boolean
                      sku?: string | null
                      title: string
                      weight?: number | null
                      weightUnit: Types.WeightUnit
                      compareAtPriceV2?: {
                        __typename?: 'MoneyV2'
                        amount: any
                        currencyCode: Types.CurrencyCode
                      } | null
                      priceV2: {
                        __typename?: 'MoneyV2'
                        amount: any
                        currencyCode: Types.CurrencyCode
                      }
                      selectedOptions: Array<{
                        __typename?: 'SelectedOption'
                        name: string
                        value: string
                      }>
                      unitPrice?: {
                        __typename?: 'MoneyV2'
                        amount: any
                        currencyCode: Types.CurrencyCode
                      } | null
                      image?: {
                        __typename?: 'Image'
                        altText?: string | null
                        height?: number | null
                        id?: string | null
                        originalSrc: any
                        width?: number | null
                        thumbImageUrl: any
                      } | null
                      model?: {
                        __typename?: 'Metafield'
                        createdAt: any
                        description?: string | null
                        id: string
                        key: string
                        namespace: string
                        type: string
                        updatedAt: any
                        value: string
                      } | null
                      edition_profile?: {
                        __typename?: 'Metafield'
                        createdAt: any
                        description?: string | null
                        id: string
                        key: string
                        namespace: string
                        type: string
                        updatedAt: any
                        value: string
                      } | null
                      amazonLink?: {
                        __typename?: 'Metafield'
                        createdAt: any
                        description?: string | null
                        id: string
                        key: string
                        namespace: string
                        type: string
                        updatedAt: any
                        value: string
                      } | null
                      sellingPoints?: {
                        __typename?: 'Metafield'
                        createdAt: any
                        description?: string | null
                        id: string
                        key: string
                        namespace: string
                        type: string
                        updatedAt: any
                        value: string
                      } | null
                    }
                  }>
                }
                seriesName?: {
                  __typename?: 'Metafield'
                  createdAt: any
                  description?: string | null
                  id: string
                  key: string
                  namespace: string
                  type: string
                  updatedAt: any
                  value: string
                } | null
                howToUse?: {
                  __typename?: 'Metafield'
                  id: string
                  value: string
                } | null
                discountJson?: {
                  __typename?: 'Metafield'
                  id: string
                  value: string
                } | null
                promotionalEndTime?: {
                  __typename?: 'Metafield'
                  id: string
                  value: string
                } | null
              }
              image?: {
                __typename?: 'Image'
                altText?: string | null
                height?: number | null
                id?: string | null
                originalSrc: any
                width?: number | null
              } | null
              model?: {
                __typename?: 'Metafield'
                createdAt: any
                description?: string | null
                id: string
                key: string
                namespace: string
                type: string
                updatedAt: any
                value: string
              } | null
            } | null
            customAttributes: Array<{
              __typename?: 'Attribute'
              key: string
              value?: string | null
            }>
          }
        }>
      }
    } | null
    checkoutUserErrors: Array<{
      __typename?: 'CheckoutUserError'
      code?: Types.CheckoutErrorCode | null
      field?: Array<string> | null
      message: string
    }>
  } | null
}

export type CartQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']
}>

export type CartQuery = {
  __typename?: 'QueryRoot'
  node?:
    | { __typename?: 'AppliedGiftCard' }
    | { __typename?: 'Article' }
    | { __typename?: 'Blog' }
    | { __typename?: 'Cart' }
    | { __typename?: 'CartLine' }
    | {
        __typename?: 'Checkout'
        completedAt?: any | null
        createdAt: any
        currencyCode: Types.CurrencyCode
        email?: string | null
        id: string
        webUrl: any
        taxesIncluded: boolean
        taxExempt: boolean
        shippingDiscountAllocations: Array<{
          __typename?: 'DiscountAllocation'
          allocatedAmount: {
            __typename?: 'MoneyV2'
            amount: any
            currencyCode: Types.CurrencyCode
          }
        }>
        shippingLine?: {
          __typename?: 'ShippingRate'
          handle: string
          title: string
          priceV2: {
            __typename?: 'MoneyV2'
            amount: any
            currencyCode: Types.CurrencyCode
          }
        } | null
        lineItemsSubtotalPrice: {
          __typename?: 'MoneyV2'
          amount: any
          currencyCode: Types.CurrencyCode
        }
        subtotalPriceV2: {
          __typename?: 'MoneyV2'
          amount: any
          currencyCode: Types.CurrencyCode
        }
        totalDuties?: {
          __typename?: 'MoneyV2'
          amount: any
          currencyCode: Types.CurrencyCode
        } | null
        totalPriceV2: {
          __typename?: 'MoneyV2'
          amount: any
          currencyCode: Types.CurrencyCode
        }
        totalTaxV2: {
          __typename?: 'MoneyV2'
          amount: any
          currencyCode: Types.CurrencyCode
        }
        lineItems: {
          __typename?: 'CheckoutLineItemConnection'
          edges: Array<{
            __typename?: 'CheckoutLineItemEdge'
            node: {
              __typename?: 'CheckoutLineItem'
              id: string
              quantity: number
              title: string
              discountAllocations: Array<{
                __typename?: 'DiscountAllocation'
                allocatedAmount: {
                  __typename?: 'MoneyV2'
                  amount: any
                  currencyCode: Types.CurrencyCode
                }
              }>
              variant?: {
                __typename?: 'ProductVariant'
                availableForSale: boolean
                currentlyNotInStock: boolean
                id: string
                quantityAvailable?: number | null
                requiresShipping: boolean
                sku?: string | null
                title: string
                weight?: number | null
                weightUnit: Types.WeightUnit
                compareAtPriceV2?: {
                  __typename?: 'MoneyV2'
                  amount: any
                  currencyCode: Types.CurrencyCode
                } | null
                priceV2: {
                  __typename?: 'MoneyV2'
                  amount: any
                  currencyCode: Types.CurrencyCode
                }
                selectedOptions: Array<{
                  __typename?: 'SelectedOption'
                  name: string
                  value: string
                }>
                unitPrice?: {
                  __typename?: 'MoneyV2'
                  amount: any
                  currencyCode: Types.CurrencyCode
                } | null
                product: {
                  __typename?: 'Product'
                  availableForSale: boolean
                  description: string
                  descriptionHtml: any
                  handle: string
                  id: string
                  tags: Array<string>
                  productType: string
                  title: string
                  totalInventory?: number | null
                  updatedAt: any
                  vendor: string
                  compareAtPriceRange: {
                    __typename?: 'ProductPriceRange'
                    maxVariantPrice: {
                      __typename?: 'MoneyV2'
                      amount: any
                      currencyCode: Types.CurrencyCode
                    }
                    minVariantPrice: {
                      __typename?: 'MoneyV2'
                      amount: any
                      currencyCode: Types.CurrencyCode
                    }
                  }
                  landingPage?: {
                    __typename?: 'Metafield'
                    createdAt: any
                    description?: string | null
                    id: string
                    key: string
                    namespace: string
                    type: string
                    updatedAt: any
                    value: string
                  } | null
                  model?: {
                    __typename?: 'Metafield'
                    createdAt: any
                    description?: string | null
                    id: string
                    key: string
                    namespace: string
                    type: string
                    updatedAt: any
                    value: string
                  } | null
                  sellingPoints?: {
                    __typename?: 'Metafield'
                    createdAt: any
                    description?: string | null
                    id: string
                    key: string
                    namespace: string
                    type: string
                    updatedAt: any
                    value: string
                  } | null
                  simpleDescription?: {
                    __typename?: 'Metafield'
                    createdAt: any
                    description?: string | null
                    id: string
                    key: string
                    namespace: string
                    type: string
                    updatedAt: any
                    value: string
                  } | null
                  whatIsInTheBox?: {
                    __typename?: 'Metafield'
                    createdAt: any
                    description?: string | null
                    id: string
                    key: string
                    namespace: string
                    type: string
                    updatedAt: any
                    value: string
                  } | null
                  specs?: {
                    __typename?: 'Metafield'
                    createdAt: any
                    description?: string | null
                    id: string
                    key: string
                    namespace: string
                    type: string
                    updatedAt: any
                    value: string
                  } | null
                  faqs?: {
                    __typename?: 'Metafield'
                    createdAt: any
                    description?: string | null
                    id: string
                    key: string
                    namespace: string
                    type: string
                    updatedAt: any
                    value: string
                  } | null
                  manuals?: {
                    __typename?: 'Metafield'
                    createdAt: any
                    description?: string | null
                    id: string
                    key: string
                    namespace: string
                    type: string
                    updatedAt: any
                    value: string
                  } | null
                  amazonLink?: {
                    __typename?: 'Metafield'
                    createdAt: any
                    description?: string | null
                    id: string
                    key: string
                    namespace: string
                    type: string
                    updatedAt: any
                    value: string
                  } | null
                  seo: {
                    __typename?: 'SEO'
                    title?: string | null
                    description?: string | null
                  }
                  SEO_H1?: {
                    __typename?: 'Metafield'
                    createdAt: any
                    description?: string | null
                    id: string
                    key: string
                    namespace: string
                    type: string
                    updatedAt: any
                    value: string
                  } | null
                  images: {
                    __typename?: 'ImageConnection'
                    edges: Array<{
                      __typename?: 'ImageEdge'
                      node: {
                        __typename?: 'Image'
                        altText?: string | null
                        height?: number | null
                        id?: string | null
                        originalSrc: any
                        width?: number | null
                        transformedSrc: any
                        largeImageUrl: any
                        mediumImageUrl: any
                        smallImageUrl: any
                        thumbImageUrl: any
                        productSaleImageUrl: any
                      }
                    }>
                  }
                  variants: {
                    __typename?: 'ProductVariantConnection'
                    edges: Array<{
                      __typename?: 'ProductVariantEdge'
                      node: {
                        __typename?: 'ProductVariant'
                        availableForSale: boolean
                        currentlyNotInStock: boolean
                        id: string
                        quantityAvailable?: number | null
                        requiresShipping: boolean
                        sku?: string | null
                        title: string
                        weight?: number | null
                        weightUnit: Types.WeightUnit
                        compareAtPriceV2?: {
                          __typename?: 'MoneyV2'
                          amount: any
                          currencyCode: Types.CurrencyCode
                        } | null
                        priceV2: {
                          __typename?: 'MoneyV2'
                          amount: any
                          currencyCode: Types.CurrencyCode
                        }
                        selectedOptions: Array<{
                          __typename?: 'SelectedOption'
                          name: string
                          value: string
                        }>
                        unitPrice?: {
                          __typename?: 'MoneyV2'
                          amount: any
                          currencyCode: Types.CurrencyCode
                        } | null
                        image?: {
                          __typename?: 'Image'
                          altText?: string | null
                          height?: number | null
                          id?: string | null
                          originalSrc: any
                          width?: number | null
                          thumbImageUrl: any
                        } | null
                        model?: {
                          __typename?: 'Metafield'
                          createdAt: any
                          description?: string | null
                          id: string
                          key: string
                          namespace: string
                          type: string
                          updatedAt: any
                          value: string
                        } | null
                        edition_profile?: {
                          __typename?: 'Metafield'
                          createdAt: any
                          description?: string | null
                          id: string
                          key: string
                          namespace: string
                          type: string
                          updatedAt: any
                          value: string
                        } | null
                        amazonLink?: {
                          __typename?: 'Metafield'
                          createdAt: any
                          description?: string | null
                          id: string
                          key: string
                          namespace: string
                          type: string
                          updatedAt: any
                          value: string
                        } | null
                        sellingPoints?: {
                          __typename?: 'Metafield'
                          createdAt: any
                          description?: string | null
                          id: string
                          key: string
                          namespace: string
                          type: string
                          updatedAt: any
                          value: string
                        } | null
                      }
                    }>
                  }
                  seriesName?: {
                    __typename?: 'Metafield'
                    createdAt: any
                    description?: string | null
                    id: string
                    key: string
                    namespace: string
                    type: string
                    updatedAt: any
                    value: string
                  } | null
                  howToUse?: {
                    __typename?: 'Metafield'
                    id: string
                    value: string
                  } | null
                  discountJson?: {
                    __typename?: 'Metafield'
                    id: string
                    value: string
                  } | null
                  promotionalEndTime?: {
                    __typename?: 'Metafield'
                    id: string
                    value: string
                  } | null
                }
                image?: {
                  __typename?: 'Image'
                  altText?: string | null
                  height?: number | null
                  id?: string | null
                  originalSrc: any
                  width?: number | null
                } | null
                model?: {
                  __typename?: 'Metafield'
                  createdAt: any
                  description?: string | null
                  id: string
                  key: string
                  namespace: string
                  type: string
                  updatedAt: any
                  value: string
                } | null
              } | null
              customAttributes: Array<{
                __typename?: 'Attribute'
                key: string
                value?: string | null
              }>
            }
          }>
        }
      }
    | { __typename?: 'CheckoutLineItem' }
    | { __typename?: 'Collection' }
    | { __typename?: 'Comment' }
    | { __typename?: 'ExternalVideo' }
    | { __typename?: 'GenericFile' }
    | { __typename?: 'Location' }
    | { __typename?: 'MailingAddress' }
    | { __typename?: 'MediaImage' }
    | { __typename?: 'Menu' }
    | { __typename?: 'MenuItem' }
    | { __typename?: 'Metafield' }
    | { __typename?: 'Metaobject' }
    | { __typename?: 'Model3d' }
    | { __typename?: 'Order' }
    | { __typename?: 'Page' }
    | { __typename?: 'Payment' }
    | { __typename?: 'Product' }
    | { __typename?: 'ProductOption' }
    | { __typename?: 'ProductVariant' }
    | { __typename?: 'Shop' }
    | { __typename?: 'ShopPolicy' }
    | { __typename?: 'UrlRedirect' }
    | { __typename?: 'Video' }
    | null
}

export type CheckoutShippingLineUpdateMutationVariables = Types.Exact<{
  checkoutId: Types.Scalars['ID']
  shippingRateHandle: Types.Scalars['String']
}>

export type CheckoutShippingLineUpdateMutation = {
  __typename?: 'Mutation'
  checkoutShippingLineUpdate?: {
    __typename?: 'CheckoutShippingLineUpdatePayload'
    checkout?: {
      __typename?: 'Checkout'
      completedAt?: any | null
      createdAt: any
      currencyCode: Types.CurrencyCode
      email?: string | null
      id: string
      webUrl: any
      taxesIncluded: boolean
      taxExempt: boolean
      shippingDiscountAllocations: Array<{
        __typename?: 'DiscountAllocation'
        allocatedAmount: {
          __typename?: 'MoneyV2'
          amount: any
          currencyCode: Types.CurrencyCode
        }
      }>
      shippingLine?: {
        __typename?: 'ShippingRate'
        handle: string
        title: string
        priceV2: {
          __typename?: 'MoneyV2'
          amount: any
          currencyCode: Types.CurrencyCode
        }
      } | null
      lineItemsSubtotalPrice: {
        __typename?: 'MoneyV2'
        amount: any
        currencyCode: Types.CurrencyCode
      }
      subtotalPriceV2: {
        __typename?: 'MoneyV2'
        amount: any
        currencyCode: Types.CurrencyCode
      }
      totalDuties?: {
        __typename?: 'MoneyV2'
        amount: any
        currencyCode: Types.CurrencyCode
      } | null
      totalPriceV2: {
        __typename?: 'MoneyV2'
        amount: any
        currencyCode: Types.CurrencyCode
      }
      totalTaxV2: {
        __typename?: 'MoneyV2'
        amount: any
        currencyCode: Types.CurrencyCode
      }
      lineItems: {
        __typename?: 'CheckoutLineItemConnection'
        edges: Array<{
          __typename?: 'CheckoutLineItemEdge'
          node: {
            __typename?: 'CheckoutLineItem'
            id: string
            quantity: number
            title: string
            discountAllocations: Array<{
              __typename?: 'DiscountAllocation'
              allocatedAmount: {
                __typename?: 'MoneyV2'
                amount: any
                currencyCode: Types.CurrencyCode
              }
            }>
            variant?: {
              __typename?: 'ProductVariant'
              availableForSale: boolean
              currentlyNotInStock: boolean
              id: string
              quantityAvailable?: number | null
              requiresShipping: boolean
              sku?: string | null
              title: string
              weight?: number | null
              weightUnit: Types.WeightUnit
              compareAtPriceV2?: {
                __typename?: 'MoneyV2'
                amount: any
                currencyCode: Types.CurrencyCode
              } | null
              priceV2: {
                __typename?: 'MoneyV2'
                amount: any
                currencyCode: Types.CurrencyCode
              }
              selectedOptions: Array<{
                __typename?: 'SelectedOption'
                name: string
                value: string
              }>
              unitPrice?: {
                __typename?: 'MoneyV2'
                amount: any
                currencyCode: Types.CurrencyCode
              } | null
              product: {
                __typename?: 'Product'
                availableForSale: boolean
                description: string
                descriptionHtml: any
                handle: string
                id: string
                tags: Array<string>
                productType: string
                title: string
                totalInventory?: number | null
                updatedAt: any
                vendor: string
                compareAtPriceRange: {
                  __typename?: 'ProductPriceRange'
                  maxVariantPrice: {
                    __typename?: 'MoneyV2'
                    amount: any
                    currencyCode: Types.CurrencyCode
                  }
                  minVariantPrice: {
                    __typename?: 'MoneyV2'
                    amount: any
                    currencyCode: Types.CurrencyCode
                  }
                }
                landingPage?: {
                  __typename?: 'Metafield'
                  createdAt: any
                  description?: string | null
                  id: string
                  key: string
                  namespace: string
                  type: string
                  updatedAt: any
                  value: string
                } | null
                model?: {
                  __typename?: 'Metafield'
                  createdAt: any
                  description?: string | null
                  id: string
                  key: string
                  namespace: string
                  type: string
                  updatedAt: any
                  value: string
                } | null
                sellingPoints?: {
                  __typename?: 'Metafield'
                  createdAt: any
                  description?: string | null
                  id: string
                  key: string
                  namespace: string
                  type: string
                  updatedAt: any
                  value: string
                } | null
                simpleDescription?: {
                  __typename?: 'Metafield'
                  createdAt: any
                  description?: string | null
                  id: string
                  key: string
                  namespace: string
                  type: string
                  updatedAt: any
                  value: string
                } | null
                whatIsInTheBox?: {
                  __typename?: 'Metafield'
                  createdAt: any
                  description?: string | null
                  id: string
                  key: string
                  namespace: string
                  type: string
                  updatedAt: any
                  value: string
                } | null
                specs?: {
                  __typename?: 'Metafield'
                  createdAt: any
                  description?: string | null
                  id: string
                  key: string
                  namespace: string
                  type: string
                  updatedAt: any
                  value: string
                } | null
                faqs?: {
                  __typename?: 'Metafield'
                  createdAt: any
                  description?: string | null
                  id: string
                  key: string
                  namespace: string
                  type: string
                  updatedAt: any
                  value: string
                } | null
                manuals?: {
                  __typename?: 'Metafield'
                  createdAt: any
                  description?: string | null
                  id: string
                  key: string
                  namespace: string
                  type: string
                  updatedAt: any
                  value: string
                } | null
                amazonLink?: {
                  __typename?: 'Metafield'
                  createdAt: any
                  description?: string | null
                  id: string
                  key: string
                  namespace: string
                  type: string
                  updatedAt: any
                  value: string
                } | null
                seo: {
                  __typename?: 'SEO'
                  title?: string | null
                  description?: string | null
                }
                SEO_H1?: {
                  __typename?: 'Metafield'
                  createdAt: any
                  description?: string | null
                  id: string
                  key: string
                  namespace: string
                  type: string
                  updatedAt: any
                  value: string
                } | null
                images: {
                  __typename?: 'ImageConnection'
                  edges: Array<{
                    __typename?: 'ImageEdge'
                    node: {
                      __typename?: 'Image'
                      altText?: string | null
                      height?: number | null
                      id?: string | null
                      originalSrc: any
                      width?: number | null
                      transformedSrc: any
                      largeImageUrl: any
                      mediumImageUrl: any
                      smallImageUrl: any
                      thumbImageUrl: any
                      productSaleImageUrl: any
                    }
                  }>
                }
                variants: {
                  __typename?: 'ProductVariantConnection'
                  edges: Array<{
                    __typename?: 'ProductVariantEdge'
                    node: {
                      __typename?: 'ProductVariant'
                      availableForSale: boolean
                      currentlyNotInStock: boolean
                      id: string
                      quantityAvailable?: number | null
                      requiresShipping: boolean
                      sku?: string | null
                      title: string
                      weight?: number | null
                      weightUnit: Types.WeightUnit
                      compareAtPriceV2?: {
                        __typename?: 'MoneyV2'
                        amount: any
                        currencyCode: Types.CurrencyCode
                      } | null
                      priceV2: {
                        __typename?: 'MoneyV2'
                        amount: any
                        currencyCode: Types.CurrencyCode
                      }
                      selectedOptions: Array<{
                        __typename?: 'SelectedOption'
                        name: string
                        value: string
                      }>
                      unitPrice?: {
                        __typename?: 'MoneyV2'
                        amount: any
                        currencyCode: Types.CurrencyCode
                      } | null
                      image?: {
                        __typename?: 'Image'
                        altText?: string | null
                        height?: number | null
                        id?: string | null
                        originalSrc: any
                        width?: number | null
                        thumbImageUrl: any
                      } | null
                      model?: {
                        __typename?: 'Metafield'
                        createdAt: any
                        description?: string | null
                        id: string
                        key: string
                        namespace: string
                        type: string
                        updatedAt: any
                        value: string
                      } | null
                      edition_profile?: {
                        __typename?: 'Metafield'
                        createdAt: any
                        description?: string | null
                        id: string
                        key: string
                        namespace: string
                        type: string
                        updatedAt: any
                        value: string
                      } | null
                      amazonLink?: {
                        __typename?: 'Metafield'
                        createdAt: any
                        description?: string | null
                        id: string
                        key: string
                        namespace: string
                        type: string
                        updatedAt: any
                        value: string
                      } | null
                      sellingPoints?: {
                        __typename?: 'Metafield'
                        createdAt: any
                        description?: string | null
                        id: string
                        key: string
                        namespace: string
                        type: string
                        updatedAt: any
                        value: string
                      } | null
                    }
                  }>
                }
                seriesName?: {
                  __typename?: 'Metafield'
                  createdAt: any
                  description?: string | null
                  id: string
                  key: string
                  namespace: string
                  type: string
                  updatedAt: any
                  value: string
                } | null
                howToUse?: {
                  __typename?: 'Metafield'
                  id: string
                  value: string
                } | null
                discountJson?: {
                  __typename?: 'Metafield'
                  id: string
                  value: string
                } | null
                promotionalEndTime?: {
                  __typename?: 'Metafield'
                  id: string
                  value: string
                } | null
              }
              image?: {
                __typename?: 'Image'
                altText?: string | null
                height?: number | null
                id?: string | null
                originalSrc: any
                width?: number | null
              } | null
              model?: {
                __typename?: 'Metafield'
                createdAt: any
                description?: string | null
                id: string
                key: string
                namespace: string
                type: string
                updatedAt: any
                value: string
              } | null
            } | null
            customAttributes: Array<{
              __typename?: 'Attribute'
              key: string
              value?: string | null
            }>
          }
        }>
      }
    } | null
    checkoutUserErrors: Array<{
      __typename?: 'CheckoutUserError'
      code?: Types.CheckoutErrorCode | null
      field?: Array<string> | null
      message: string
    }>
  } | null
}

export type SimpleCheckoutFragment = {
  __typename?: 'Checkout'
  id: string
  ready: boolean
  orderStatusUrl?: any | null
  webUrl: any
  email?: string | null
  customAttributes: Array<{
    __typename?: 'Attribute'
    key: string
    value?: string | null
  }>
  order?: {
    __typename?: 'Order'
    name: string
    orderNumber: number
    statusUrl: any
    id: string
    lineItems: {
      __typename?: 'OrderLineItemConnection'
      edges: Array<{
        __typename?: 'OrderLineItemEdge'
        cursor: string
        node: {
          __typename?: 'OrderLineItem'
          quantity: number
          title: string
          discountAllocations: Array<{
            __typename?: 'DiscountAllocation'
            allocatedAmount: {
              __typename?: 'MoneyV2'
              amount: any
              currencyCode: Types.CurrencyCode
            }
          }>
          variant?: {
            __typename?: 'ProductVariant'
            id: string
            sku?: string | null
            priceV2: {
              __typename?: 'MoneyV2'
              amount: any
              currencyCode: Types.CurrencyCode
            }
            product: { __typename?: 'Product'; productType: string }
          } | null
        }
      }>
    }
    totalPriceV2: {
      __typename?: 'MoneyV2'
      amount: any
      currencyCode: Types.CurrencyCode
    }
    totalTaxV2?: {
      __typename?: 'MoneyV2'
      amount: any
      currencyCode: Types.CurrencyCode
    } | null
    totalShippingPriceV2: {
      __typename?: 'MoneyV2'
      amount: any
      currencyCode: Types.CurrencyCode
    }
    totalRefundedV2: {
      __typename?: 'MoneyV2'
      amount: any
      currencyCode: Types.CurrencyCode
    }
  } | null
}

export type CheckoutTestQueryVariables = Types.Exact<{ [key: string]: never }>

export type CheckoutTestQuery = {
  __typename?: 'QueryRoot'
  node?:
    | { __typename?: 'AppliedGiftCard' }
    | { __typename?: 'Article' }
    | { __typename?: 'Blog' }
    | { __typename?: 'Cart' }
    | { __typename?: 'CartLine' }
    | {
        __typename?: 'Checkout'
        lineItems: {
          __typename?: 'CheckoutLineItemConnection'
          edges: Array<{
            __typename?: 'CheckoutLineItemEdge'
            node: {
              __typename?: 'CheckoutLineItem'
              id: string
              quantity: number
            }
          }>
        }
      }
    | { __typename?: 'CheckoutLineItem' }
    | { __typename?: 'Collection' }
    | { __typename?: 'Comment' }
    | { __typename?: 'ExternalVideo' }
    | { __typename?: 'GenericFile' }
    | { __typename?: 'Location' }
    | { __typename?: 'MailingAddress' }
    | { __typename?: 'MediaImage' }
    | { __typename?: 'Menu' }
    | { __typename?: 'MenuItem' }
    | { __typename?: 'Metafield' }
    | { __typename?: 'Metaobject' }
    | { __typename?: 'Model3d' }
    | { __typename?: 'Order' }
    | { __typename?: 'Page' }
    | { __typename?: 'Payment' }
    | { __typename?: 'Product' }
    | { __typename?: 'ProductOption' }
    | { __typename?: 'ProductVariant' }
    | { __typename?: 'Shop' }
    | { __typename?: 'ShopPolicy' }
    | { __typename?: 'UrlRedirect' }
    | { __typename?: 'Video' }
    | null
}

export type ClearCartMutationVariables = Types.Exact<{
  checkoutId: Types.Scalars['ID']
  lineItemIds: Array<Types.Scalars['ID']> | Types.Scalars['ID']
}>

export type ClearCartMutation = {
  __typename?: 'Mutation'
  checkoutLineItemsRemove?: {
    __typename?: 'CheckoutLineItemsRemovePayload'
    checkout?: { __typename?: 'Checkout'; id: string } | null
    checkoutUserErrors: Array<{
      __typename?: 'CheckoutUserError'
      code?: Types.CheckoutErrorCode | null
      field?: Array<string> | null
      message: string
    }>
  } | null
}

export const ShippingRateFragmentDoc = gql`
  fragment ShippingRate on ShippingRate {
    handle
    priceV2 {
      amount
      currencyCode
    }
    title
  }
`
export const MoneyV2FragmentDoc = gql`
  fragment MoneyV2 on MoneyV2 {
    amount
    currencyCode
  }
`
export const MetafieldFragmentDoc = gql`
  fragment Metafield on Metafield {
    createdAt
    description
    id
    key
    namespace
    type
    updatedAt
    value
  }
`
export const ProductImageFragmentDoc = gql`
  fragment ProductImage on Image {
    altText
    height
    id
    originalSrc
    width
    transformedSrc(maxWidth: 400)
    largeImageUrl: transformedSrc(maxWidth: 1440)
    mediumImageUrl: transformedSrc(maxWidth: 992, crop: CENTER)
    smallImageUrl: transformedSrc(maxWidth: 768)
    thumbImageUrl: url(transform: { maxWidth: 162 })
    productSaleImageUrl: url(
      transform: { maxWidth: 300, maxHeight: 400, crop: CENTER }
    )
  }
`
export const ImageFragmentDoc = gql`
  fragment Image on Image {
    altText
    height
    id
    originalSrc
    width
  }
`
export const ProductVariantFragmentDoc = gql`
  fragment ProductVariant on ProductVariant {
    availableForSale
    compareAtPriceV2 {
      amount
      currencyCode
    }
    currentlyNotInStock
    id
    priceV2 {
      amount
      currencyCode
    }
    quantityAvailable
    requiresShipping
    selectedOptions {
      name
      value
    }
    sku
    title
    unitPrice {
      amount
      currencyCode
    }
    weight
    weightUnit
    image {
      ...Image
      thumbImageUrl: url(transform: { maxWidth: 162 })
    }
    model: metafield(namespace: "variant_fields", key: "model") {
      ...Metafield
    }
    edition_profile: metafield(
      namespace: "variant_fields"
      key: "edition_profile"
    ) {
      ...Metafield
    }
    amazonLink: metafield(namespace: "variant_fields", key: "amazon_link") {
      ...Metafield
    }
    sellingPoints: metafield(
      namespace: "variant_fields"
      key: "product_selling_points"
    ) {
      ...Metafield
    }
  }
  ${ImageFragmentDoc}
  ${MetafieldFragmentDoc}
`
export const ProductVariantsFragmentDoc = gql`
  fragment ProductVariants on ProductVariantConnection {
    edges {
      node {
        ...ProductVariant
      }
    }
  }
  ${ProductVariantFragmentDoc}
`
export const MetaFieldLessFragmentDoc = gql`
  fragment MetaFieldLess on Metafield {
    id
    value
  }
`
export const ProductFragmentDoc = gql`
  fragment Product on Product {
    availableForSale
    compareAtPriceRange {
      ... on ProductPriceRange {
        maxVariantPrice {
          ... on MoneyV2 {
            amount
            currencyCode
          }
        }
        minVariantPrice {
          ... on MoneyV2 {
            amount
            currencyCode
          }
        }
      }
    }
    landingPage: metafield(namespace: "product_fields", key: "landing_page") {
      ...Metafield
    }
    model: metafield(namespace: "product_fields", key: "model") {
      ...Metafield
    }
    sellingPoints: metafield(
      namespace: "product_fields"
      key: "product_selling_points"
    ) {
      ...Metafield
    }
    simpleDescription: metafield(
      namespace: "product_fields"
      key: "description"
    ) {
      ...Metafield
    }
    whatIsInTheBox: metafield(
      namespace: "product_fields"
      key: "what_is_in_the_box"
    ) {
      ...Metafield
    }
    specs: metafield(namespace: "product_fields", key: "specs") {
      ...Metafield
    }
    faqs: metafield(namespace: "product_fields", key: "faqs") {
      ...Metafield
    }
    manuals: metafield(namespace: "product_fields", key: "manuals") {
      ...Metafield
    }
    amazonLink: metafield(namespace: "product_fields", key: "amazon_link") {
      ...Metafield
    }
    description
    descriptionHtml
    handle
    id
    tags
    productType
    title
    totalInventory
    updatedAt
    vendor
    seo {
      title
      description
    }
    SEO_H1: metafield(namespace: "product_fields", key: "seo_h1") {
      ...Metafield
    }
    images(first: 100) {
      edges {
        node {
          ...ProductImage
        }
      }
    }
    variants(first: 100) {
      ...ProductVariants
    }
    seriesName: metafield(namespace: "product_fields", key: "series_name") {
      ...Metafield
    }
    howToUse: metafield(namespace: "product_fields", key: "how_to_use") {
      ...MetaFieldLess
    }
    discountJson: metafield(namespace: "product_fields", key: "discount_json") {
      ...MetaFieldLess
    }
    promotionalEndTime: metafield(
      namespace: "product_fields"
      key: "promotional_end_time"
    ) {
      ...MetaFieldLess
    }
  }
  ${MetafieldFragmentDoc}
  ${ProductImageFragmentDoc}
  ${ProductVariantsFragmentDoc}
  ${MetaFieldLessFragmentDoc}
`
export const CartLineProductVariantFragmentDoc = gql`
  fragment CartLineProductVariant on ProductVariant {
    availableForSale
    compareAtPriceV2 {
      amount
      currencyCode
    }
    currentlyNotInStock
    id
    priceV2 {
      amount
      currencyCode
    }
    quantityAvailable
    requiresShipping
    selectedOptions {
      name
      value
    }
    sku
    title
    unitPrice {
      amount
      currencyCode
    }
    product {
      ...Product
    }
    weight
    weightUnit
    image {
      ...Image
    }
    model: metafield(namespace: "product_fields", key: "model") {
      ...Metafield
    }
  }
  ${ProductFragmentDoc}
  ${ImageFragmentDoc}
  ${MetafieldFragmentDoc}
`
export const CheckoutLineItemFragmentDoc = gql`
  fragment CheckoutLineItem on CheckoutLineItem {
    id
    quantity
    title
    discountAllocations {
      allocatedAmount {
        ...MoneyV2
      }
    }
    variant {
      ...CartLineProductVariant
    }
    customAttributes {
      key
      value
    }
  }
  ${MoneyV2FragmentDoc}
  ${CartLineProductVariantFragmentDoc}
`
export const CheckoutLineItemsFragmentDoc = gql`
  fragment CheckoutLineItems on CheckoutLineItemConnection {
    edges {
      node {
        ...CheckoutLineItem
      }
    }
  }
  ${CheckoutLineItemFragmentDoc}
`
export const CheckoutFragmentDoc = gql`
  fragment Checkout on Checkout {
    completedAt
    createdAt
    currencyCode
    email
    id
    webUrl
    shippingDiscountAllocations {
      allocatedAmount {
        amount
        currencyCode
      }
    }
    shippingLine {
      ...ShippingRate
    }
    taxesIncluded
    taxExempt
    lineItemsSubtotalPrice {
      amount
      currencyCode
    }
    subtotalPriceV2 {
      amount
      currencyCode
    }
    totalDuties {
      amount
      currencyCode
    }
    totalPriceV2 {
      amount
      currencyCode
    }
    totalTaxV2 {
      amount
      currencyCode
    }
    lineItems(first: 100) {
      ...CheckoutLineItems
    }
  }
  ${ShippingRateFragmentDoc}
  ${CheckoutLineItemsFragmentDoc}
`
export const SimpleCheckoutFragmentDoc = gql`
  fragment SimpleCheckout on Checkout {
    id
    ready
    orderStatusUrl
    webUrl
    email
    customAttributes {
      key
      value
    }
    order {
      name
      orderNumber
      statusUrl
      id
      lineItems(first: 20) {
        edges {
          cursor
          node {
            quantity
            title
            discountAllocations {
              allocatedAmount {
                ...MoneyV2
              }
            }
            variant {
              id
              sku
              priceV2 {
                ...MoneyV2
              }
              product {
                productType
              }
            }
          }
        }
      }
      totalPriceV2 {
        ...MoneyV2
      }
      totalTaxV2 {
        ...MoneyV2
      }
      totalShippingPriceV2 {
        ...MoneyV2
      }
      totalRefundedV2 {
        ...MoneyV2
      }
    }
  }
  ${MoneyV2FragmentDoc}
`
export const CheckoutCreateDocument = gql`
  mutation CheckoutCreate($input: CheckoutCreateInput!, $queueToken: String) {
    checkoutCreate(input: $input, queueToken: $queueToken) {
      checkout {
        ...Checkout
      }
      checkoutUserErrors {
        code
        field
        message
      }
    }
  }
  ${CheckoutFragmentDoc}
`
export type CheckoutCreateMutationFn = Apollo.MutationFunction<
  CheckoutCreateMutation,
  CheckoutCreateMutationVariables
>

/**
 * __useCheckoutCreateMutation__
 *
 * To run a mutation, you first call `useCheckoutCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckoutCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkoutCreateMutation, { data, loading, error }] = useCheckoutCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *      queueToken: // value for 'queueToken'
 *   },
 * });
 */
export function useCheckoutCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CheckoutCreateMutation,
    CheckoutCreateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CheckoutCreateMutation,
    CheckoutCreateMutationVariables
  >(CheckoutCreateDocument, options)
}
export type CheckoutCreateMutationHookResult = ReturnType<
  typeof useCheckoutCreateMutation
>
export type CheckoutCreateMutationResult =
  Apollo.MutationResult<CheckoutCreateMutation>
export type CheckoutCreateMutationOptions = Apollo.BaseMutationOptions<
  CheckoutCreateMutation,
  CheckoutCreateMutationVariables
>
export const CheckoutDocument = gql`
  query Checkout($id: ID!) {
    node(id: $id) {
      ... on Checkout {
        ...SimpleCheckout
      }
    }
  }
  ${SimpleCheckoutFragmentDoc}
`

/**
 * __useCheckoutQuery__
 *
 * To run a query within a React component, call `useCheckoutQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckoutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckoutQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCheckoutQuery(
  baseOptions: Apollo.QueryHookOptions<CheckoutQuery, CheckoutQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CheckoutQuery, CheckoutQueryVariables>(
    CheckoutDocument,
    options
  )
}
export function useCheckoutLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CheckoutQuery,
    CheckoutQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CheckoutQuery, CheckoutQueryVariables>(
    CheckoutDocument,
    options
  )
}
export type CheckoutQueryHookResult = ReturnType<typeof useCheckoutQuery>
export type CheckoutLazyQueryHookResult = ReturnType<
  typeof useCheckoutLazyQuery
>
export type CheckoutQueryResult = Apollo.QueryResult<
  CheckoutQuery,
  CheckoutQueryVariables
>
export const CheckoutLineItemsAddDocument = gql`
  mutation checkoutLineItemsAdd(
    $lineItems: [CheckoutLineItemInput!]!
    $checkoutId: ID!
  ) {
    checkoutLineItemsAdd(lineItems: $lineItems, checkoutId: $checkoutId) {
      checkout {
        ...Checkout
      }
      checkoutUserErrors {
        code
        field
        message
      }
    }
  }
  ${CheckoutFragmentDoc}
`
export type CheckoutLineItemsAddMutationFn = Apollo.MutationFunction<
  CheckoutLineItemsAddMutation,
  CheckoutLineItemsAddMutationVariables
>

/**
 * __useCheckoutLineItemsAddMutation__
 *
 * To run a mutation, you first call `useCheckoutLineItemsAddMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckoutLineItemsAddMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkoutLineItemsAddMutation, { data, loading, error }] = useCheckoutLineItemsAddMutation({
 *   variables: {
 *      lineItems: // value for 'lineItems'
 *      checkoutId: // value for 'checkoutId'
 *   },
 * });
 */
export function useCheckoutLineItemsAddMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CheckoutLineItemsAddMutation,
    CheckoutLineItemsAddMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CheckoutLineItemsAddMutation,
    CheckoutLineItemsAddMutationVariables
  >(CheckoutLineItemsAddDocument, options)
}
export type CheckoutLineItemsAddMutationHookResult = ReturnType<
  typeof useCheckoutLineItemsAddMutation
>
export type CheckoutLineItemsAddMutationResult =
  Apollo.MutationResult<CheckoutLineItemsAddMutation>
export type CheckoutLineItemsAddMutationOptions = Apollo.BaseMutationOptions<
  CheckoutLineItemsAddMutation,
  CheckoutLineItemsAddMutationVariables
>
export const CheckoutLineItemsUpdateDocument = gql`
  mutation checkoutLineItemsUpdate(
    $checkoutId: ID!
    $lineItems: [CheckoutLineItemUpdateInput!]!
  ) {
    checkoutLineItemsUpdate(checkoutId: $checkoutId, lineItems: $lineItems) {
      checkout {
        ...Checkout
      }
      checkoutUserErrors {
        code
        field
        message
      }
    }
  }
  ${CheckoutFragmentDoc}
`
export type CheckoutLineItemsUpdateMutationFn = Apollo.MutationFunction<
  CheckoutLineItemsUpdateMutation,
  CheckoutLineItemsUpdateMutationVariables
>

/**
 * __useCheckoutLineItemsUpdateMutation__
 *
 * To run a mutation, you first call `useCheckoutLineItemsUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckoutLineItemsUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkoutLineItemsUpdateMutation, { data, loading, error }] = useCheckoutLineItemsUpdateMutation({
 *   variables: {
 *      checkoutId: // value for 'checkoutId'
 *      lineItems: // value for 'lineItems'
 *   },
 * });
 */
export function useCheckoutLineItemsUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CheckoutLineItemsUpdateMutation,
    CheckoutLineItemsUpdateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CheckoutLineItemsUpdateMutation,
    CheckoutLineItemsUpdateMutationVariables
  >(CheckoutLineItemsUpdateDocument, options)
}
export type CheckoutLineItemsUpdateMutationHookResult = ReturnType<
  typeof useCheckoutLineItemsUpdateMutation
>
export type CheckoutLineItemsUpdateMutationResult =
  Apollo.MutationResult<CheckoutLineItemsUpdateMutation>
export type CheckoutLineItemsUpdateMutationOptions = Apollo.BaseMutationOptions<
  CheckoutLineItemsUpdateMutation,
  CheckoutLineItemsUpdateMutationVariables
>
export const CheckoutLineItemsRemoveDocument = gql`
  mutation checkoutLineItemsRemove($checkoutId: ID!, $lineItemIds: [ID!]!) {
    checkoutLineItemsRemove(
      checkoutId: $checkoutId
      lineItemIds: $lineItemIds
    ) {
      checkout {
        ...Checkout
      }
      checkoutUserErrors {
        code
        field
        message
      }
    }
  }
  ${CheckoutFragmentDoc}
`
export type CheckoutLineItemsRemoveMutationFn = Apollo.MutationFunction<
  CheckoutLineItemsRemoveMutation,
  CheckoutLineItemsRemoveMutationVariables
>

/**
 * __useCheckoutLineItemsRemoveMutation__
 *
 * To run a mutation, you first call `useCheckoutLineItemsRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckoutLineItemsRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkoutLineItemsRemoveMutation, { data, loading, error }] = useCheckoutLineItemsRemoveMutation({
 *   variables: {
 *      checkoutId: // value for 'checkoutId'
 *      lineItemIds: // value for 'lineItemIds'
 *   },
 * });
 */
export function useCheckoutLineItemsRemoveMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CheckoutLineItemsRemoveMutation,
    CheckoutLineItemsRemoveMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CheckoutLineItemsRemoveMutation,
    CheckoutLineItemsRemoveMutationVariables
  >(CheckoutLineItemsRemoveDocument, options)
}
export type CheckoutLineItemsRemoveMutationHookResult = ReturnType<
  typeof useCheckoutLineItemsRemoveMutation
>
export type CheckoutLineItemsRemoveMutationResult =
  Apollo.MutationResult<CheckoutLineItemsRemoveMutation>
export type CheckoutLineItemsRemoveMutationOptions = Apollo.BaseMutationOptions<
  CheckoutLineItemsRemoveMutation,
  CheckoutLineItemsRemoveMutationVariables
>
export const CartDocument = gql`
  query Cart($id: ID!) {
    node(id: $id) {
      ...Checkout
    }
  }
  ${CheckoutFragmentDoc}
`

/**
 * __useCartQuery__
 *
 * To run a query within a React component, call `useCartQuery` and pass it any options that fit your needs.
 * When your component renders, `useCartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCartQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCartQuery(
  baseOptions: Apollo.QueryHookOptions<CartQuery, CartQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CartQuery, CartQueryVariables>(CartDocument, options)
}
export function useCartLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CartQuery, CartQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CartQuery, CartQueryVariables>(
    CartDocument,
    options
  )
}
export type CartQueryHookResult = ReturnType<typeof useCartQuery>
export type CartLazyQueryHookResult = ReturnType<typeof useCartLazyQuery>
export type CartQueryResult = Apollo.QueryResult<CartQuery, CartQueryVariables>
export const CheckoutShippingLineUpdateDocument = gql`
  mutation CheckoutShippingLineUpdate(
    $checkoutId: ID!
    $shippingRateHandle: String!
  ) {
    checkoutShippingLineUpdate(
      checkoutId: $checkoutId
      shippingRateHandle: $shippingRateHandle
    ) {
      checkout {
        ...Checkout
      }
      checkoutUserErrors {
        code
        field
        message
      }
    }
  }
  ${CheckoutFragmentDoc}
`
export type CheckoutShippingLineUpdateMutationFn = Apollo.MutationFunction<
  CheckoutShippingLineUpdateMutation,
  CheckoutShippingLineUpdateMutationVariables
>

/**
 * __useCheckoutShippingLineUpdateMutation__
 *
 * To run a mutation, you first call `useCheckoutShippingLineUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckoutShippingLineUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkoutShippingLineUpdateMutation, { data, loading, error }] = useCheckoutShippingLineUpdateMutation({
 *   variables: {
 *      checkoutId: // value for 'checkoutId'
 *      shippingRateHandle: // value for 'shippingRateHandle'
 *   },
 * });
 */
export function useCheckoutShippingLineUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CheckoutShippingLineUpdateMutation,
    CheckoutShippingLineUpdateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CheckoutShippingLineUpdateMutation,
    CheckoutShippingLineUpdateMutationVariables
  >(CheckoutShippingLineUpdateDocument, options)
}
export type CheckoutShippingLineUpdateMutationHookResult = ReturnType<
  typeof useCheckoutShippingLineUpdateMutation
>
export type CheckoutShippingLineUpdateMutationResult =
  Apollo.MutationResult<CheckoutShippingLineUpdateMutation>
export type CheckoutShippingLineUpdateMutationOptions =
  Apollo.BaseMutationOptions<
    CheckoutShippingLineUpdateMutation,
    CheckoutShippingLineUpdateMutationVariables
  >
export const CheckoutTestDocument = gql`
  query CheckoutTest {
    node(
      id: "gid://shopify/Checkout/6fb8ba572b2fd50859e3b7aa8a6aede3?key=36f98e701eadca2550a0515ded82256d"
    ) {
      ... on Checkout {
        lineItems(first: 100) {
          edges {
            node {
              id
              quantity
            }
          }
        }
      }
    }
  }
`

/**
 * __useCheckoutTestQuery__
 *
 * To run a query within a React component, call `useCheckoutTestQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckoutTestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckoutTestQuery({
 *   variables: {
 *   },
 * });
 */
export function useCheckoutTestQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CheckoutTestQuery,
    CheckoutTestQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CheckoutTestQuery, CheckoutTestQueryVariables>(
    CheckoutTestDocument,
    options
  )
}
export function useCheckoutTestLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CheckoutTestQuery,
    CheckoutTestQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CheckoutTestQuery, CheckoutTestQueryVariables>(
    CheckoutTestDocument,
    options
  )
}
export type CheckoutTestQueryHookResult = ReturnType<
  typeof useCheckoutTestQuery
>
export type CheckoutTestLazyQueryHookResult = ReturnType<
  typeof useCheckoutTestLazyQuery
>
export type CheckoutTestQueryResult = Apollo.QueryResult<
  CheckoutTestQuery,
  CheckoutTestQueryVariables
>
export const ClearCartDocument = gql`
  mutation ClearCart($checkoutId: ID!, $lineItemIds: [ID!]!) {
    checkoutLineItemsRemove(
      checkoutId: $checkoutId
      lineItemIds: $lineItemIds
    ) {
      checkout {
        id
      }
      checkoutUserErrors {
        code
        field
        message
      }
    }
  }
`
export type ClearCartMutationFn = Apollo.MutationFunction<
  ClearCartMutation,
  ClearCartMutationVariables
>

/**
 * __useClearCartMutation__
 *
 * To run a mutation, you first call `useClearCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearCartMutation, { data, loading, error }] = useClearCartMutation({
 *   variables: {
 *      checkoutId: // value for 'checkoutId'
 *      lineItemIds: // value for 'lineItemIds'
 *   },
 * });
 */
export function useClearCartMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ClearCartMutation,
    ClearCartMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ClearCartMutation, ClearCartMutationVariables>(
    ClearCartDocument,
    options
  )
}
export type ClearCartMutationHookResult = ReturnType<
  typeof useClearCartMutation
>
export type ClearCartMutationResult = Apollo.MutationResult<ClearCartMutation>
export type ClearCartMutationOptions = Apollo.BaseMutationOptions<
  ClearCartMutation,
  ClearCartMutationVariables
>
