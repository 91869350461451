/* 3975a212908b9430c398156ad3c4ea4bc49346bf
 * This file is automatically generated by graphql-let. */

import * as Types from '../../__types__'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type ProductQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['ID']>
  handle?: Types.InputMaybe<Types.Scalars['String']>
}>

export type ProductQuery = {
  __typename?: 'QueryRoot'
  product?: {
    __typename?: 'Product'
    availableForSale: boolean
    description: string
    descriptionHtml: any
    handle: string
    id: string
    tags: Array<string>
    productType: string
    title: string
    totalInventory?: number | null
    updatedAt: any
    vendor: string
    compareAtPriceRange: {
      __typename?: 'ProductPriceRange'
      maxVariantPrice: {
        __typename?: 'MoneyV2'
        amount: any
        currencyCode: Types.CurrencyCode
      }
      minVariantPrice: {
        __typename?: 'MoneyV2'
        amount: any
        currencyCode: Types.CurrencyCode
      }
    }
    landingPage?: {
      __typename?: 'Metafield'
      createdAt: any
      description?: string | null
      id: string
      key: string
      namespace: string
      type: string
      updatedAt: any
      value: string
    } | null
    model?: {
      __typename?: 'Metafield'
      createdAt: any
      description?: string | null
      id: string
      key: string
      namespace: string
      type: string
      updatedAt: any
      value: string
    } | null
    sellingPoints?: {
      __typename?: 'Metafield'
      createdAt: any
      description?: string | null
      id: string
      key: string
      namespace: string
      type: string
      updatedAt: any
      value: string
    } | null
    simpleDescription?: {
      __typename?: 'Metafield'
      createdAt: any
      description?: string | null
      id: string
      key: string
      namespace: string
      type: string
      updatedAt: any
      value: string
    } | null
    whatIsInTheBox?: {
      __typename?: 'Metafield'
      createdAt: any
      description?: string | null
      id: string
      key: string
      namespace: string
      type: string
      updatedAt: any
      value: string
    } | null
    specs?: {
      __typename?: 'Metafield'
      createdAt: any
      description?: string | null
      id: string
      key: string
      namespace: string
      type: string
      updatedAt: any
      value: string
    } | null
    faqs?: {
      __typename?: 'Metafield'
      createdAt: any
      description?: string | null
      id: string
      key: string
      namespace: string
      type: string
      updatedAt: any
      value: string
    } | null
    manuals?: {
      __typename?: 'Metafield'
      createdAt: any
      description?: string | null
      id: string
      key: string
      namespace: string
      type: string
      updatedAt: any
      value: string
    } | null
    amazonLink?: {
      __typename?: 'Metafield'
      createdAt: any
      description?: string | null
      id: string
      key: string
      namespace: string
      type: string
      updatedAt: any
      value: string
    } | null
    seo: {
      __typename?: 'SEO'
      title?: string | null
      description?: string | null
    }
    SEO_H1?: {
      __typename?: 'Metafield'
      createdAt: any
      description?: string | null
      id: string
      key: string
      namespace: string
      type: string
      updatedAt: any
      value: string
    } | null
    images: {
      __typename?: 'ImageConnection'
      edges: Array<{
        __typename?: 'ImageEdge'
        node: {
          __typename?: 'Image'
          altText?: string | null
          height?: number | null
          id?: string | null
          originalSrc: any
          width?: number | null
          transformedSrc: any
          largeImageUrl: any
          mediumImageUrl: any
          smallImageUrl: any
          thumbImageUrl: any
          productSaleImageUrl: any
        }
      }>
    }
    variants: {
      __typename?: 'ProductVariantConnection'
      edges: Array<{
        __typename?: 'ProductVariantEdge'
        node: {
          __typename?: 'ProductVariant'
          availableForSale: boolean
          currentlyNotInStock: boolean
          id: string
          quantityAvailable?: number | null
          requiresShipping: boolean
          sku?: string | null
          title: string
          weight?: number | null
          weightUnit: Types.WeightUnit
          compareAtPriceV2?: {
            __typename?: 'MoneyV2'
            amount: any
            currencyCode: Types.CurrencyCode
          } | null
          priceV2: {
            __typename?: 'MoneyV2'
            amount: any
            currencyCode: Types.CurrencyCode
          }
          selectedOptions: Array<{
            __typename?: 'SelectedOption'
            name: string
            value: string
          }>
          unitPrice?: {
            __typename?: 'MoneyV2'
            amount: any
            currencyCode: Types.CurrencyCode
          } | null
          image?: {
            __typename?: 'Image'
            altText?: string | null
            height?: number | null
            id?: string | null
            originalSrc: any
            width?: number | null
            thumbImageUrl: any
          } | null
          model?: {
            __typename?: 'Metafield'
            createdAt: any
            description?: string | null
            id: string
            key: string
            namespace: string
            type: string
            updatedAt: any
            value: string
          } | null
          edition_profile?: {
            __typename?: 'Metafield'
            createdAt: any
            description?: string | null
            id: string
            key: string
            namespace: string
            type: string
            updatedAt: any
            value: string
          } | null
          amazonLink?: {
            __typename?: 'Metafield'
            createdAt: any
            description?: string | null
            id: string
            key: string
            namespace: string
            type: string
            updatedAt: any
            value: string
          } | null
          sellingPoints?: {
            __typename?: 'Metafield'
            createdAt: any
            description?: string | null
            id: string
            key: string
            namespace: string
            type: string
            updatedAt: any
            value: string
          } | null
        }
      }>
    }
    seriesName?: {
      __typename?: 'Metafield'
      createdAt: any
      description?: string | null
      id: string
      key: string
      namespace: string
      type: string
      updatedAt: any
      value: string
    } | null
    howToUse?: { __typename?: 'Metafield'; id: string; value: string } | null
    discountJson?: {
      __typename?: 'Metafield'
      id: string
      value: string
    } | null
    promotionalEndTime?: {
      __typename?: 'Metafield'
      id: string
      value: string
    } | null
  } | null
}

export type ProductFragment = {
  __typename?: 'Product'
  availableForSale: boolean
  description: string
  descriptionHtml: any
  handle: string
  id: string
  tags: Array<string>
  productType: string
  title: string
  totalInventory?: number | null
  updatedAt: any
  vendor: string
  compareAtPriceRange: {
    __typename?: 'ProductPriceRange'
    maxVariantPrice: {
      __typename?: 'MoneyV2'
      amount: any
      currencyCode: Types.CurrencyCode
    }
    minVariantPrice: {
      __typename?: 'MoneyV2'
      amount: any
      currencyCode: Types.CurrencyCode
    }
  }
  landingPage?: {
    __typename?: 'Metafield'
    createdAt: any
    description?: string | null
    id: string
    key: string
    namespace: string
    type: string
    updatedAt: any
    value: string
  } | null
  model?: {
    __typename?: 'Metafield'
    createdAt: any
    description?: string | null
    id: string
    key: string
    namespace: string
    type: string
    updatedAt: any
    value: string
  } | null
  sellingPoints?: {
    __typename?: 'Metafield'
    createdAt: any
    description?: string | null
    id: string
    key: string
    namespace: string
    type: string
    updatedAt: any
    value: string
  } | null
  simpleDescription?: {
    __typename?: 'Metafield'
    createdAt: any
    description?: string | null
    id: string
    key: string
    namespace: string
    type: string
    updatedAt: any
    value: string
  } | null
  whatIsInTheBox?: {
    __typename?: 'Metafield'
    createdAt: any
    description?: string | null
    id: string
    key: string
    namespace: string
    type: string
    updatedAt: any
    value: string
  } | null
  specs?: {
    __typename?: 'Metafield'
    createdAt: any
    description?: string | null
    id: string
    key: string
    namespace: string
    type: string
    updatedAt: any
    value: string
  } | null
  faqs?: {
    __typename?: 'Metafield'
    createdAt: any
    description?: string | null
    id: string
    key: string
    namespace: string
    type: string
    updatedAt: any
    value: string
  } | null
  manuals?: {
    __typename?: 'Metafield'
    createdAt: any
    description?: string | null
    id: string
    key: string
    namespace: string
    type: string
    updatedAt: any
    value: string
  } | null
  amazonLink?: {
    __typename?: 'Metafield'
    createdAt: any
    description?: string | null
    id: string
    key: string
    namespace: string
    type: string
    updatedAt: any
    value: string
  } | null
  seo: {
    __typename?: 'SEO'
    title?: string | null
    description?: string | null
  }
  SEO_H1?: {
    __typename?: 'Metafield'
    createdAt: any
    description?: string | null
    id: string
    key: string
    namespace: string
    type: string
    updatedAt: any
    value: string
  } | null
  images: {
    __typename?: 'ImageConnection'
    edges: Array<{
      __typename?: 'ImageEdge'
      node: {
        __typename?: 'Image'
        altText?: string | null
        height?: number | null
        id?: string | null
        originalSrc: any
        width?: number | null
        transformedSrc: any
        largeImageUrl: any
        mediumImageUrl: any
        smallImageUrl: any
        thumbImageUrl: any
        productSaleImageUrl: any
      }
    }>
  }
  variants: {
    __typename?: 'ProductVariantConnection'
    edges: Array<{
      __typename?: 'ProductVariantEdge'
      node: {
        __typename?: 'ProductVariant'
        availableForSale: boolean
        currentlyNotInStock: boolean
        id: string
        quantityAvailable?: number | null
        requiresShipping: boolean
        sku?: string | null
        title: string
        weight?: number | null
        weightUnit: Types.WeightUnit
        compareAtPriceV2?: {
          __typename?: 'MoneyV2'
          amount: any
          currencyCode: Types.CurrencyCode
        } | null
        priceV2: {
          __typename?: 'MoneyV2'
          amount: any
          currencyCode: Types.CurrencyCode
        }
        selectedOptions: Array<{
          __typename?: 'SelectedOption'
          name: string
          value: string
        }>
        unitPrice?: {
          __typename?: 'MoneyV2'
          amount: any
          currencyCode: Types.CurrencyCode
        } | null
        image?: {
          __typename?: 'Image'
          altText?: string | null
          height?: number | null
          id?: string | null
          originalSrc: any
          width?: number | null
          thumbImageUrl: any
        } | null
        model?: {
          __typename?: 'Metafield'
          createdAt: any
          description?: string | null
          id: string
          key: string
          namespace: string
          type: string
          updatedAt: any
          value: string
        } | null
        edition_profile?: {
          __typename?: 'Metafield'
          createdAt: any
          description?: string | null
          id: string
          key: string
          namespace: string
          type: string
          updatedAt: any
          value: string
        } | null
        amazonLink?: {
          __typename?: 'Metafield'
          createdAt: any
          description?: string | null
          id: string
          key: string
          namespace: string
          type: string
          updatedAt: any
          value: string
        } | null
        sellingPoints?: {
          __typename?: 'Metafield'
          createdAt: any
          description?: string | null
          id: string
          key: string
          namespace: string
          type: string
          updatedAt: any
          value: string
        } | null
      }
    }>
  }
  seriesName?: {
    __typename?: 'Metafield'
    createdAt: any
    description?: string | null
    id: string
    key: string
    namespace: string
    type: string
    updatedAt: any
    value: string
  } | null
  howToUse?: { __typename?: 'Metafield'; id: string; value: string } | null
  discountJson?: { __typename?: 'Metafield'; id: string; value: string } | null
  promotionalEndTime?: {
    __typename?: 'Metafield'
    id: string
    value: string
  } | null
}

export type MetafieldFragment = {
  __typename?: 'Metafield'
  createdAt: any
  description?: string | null
  id: string
  key: string
  namespace: string
  type: string
  updatedAt: any
  value: string
}

export type ProductImageFragment = {
  __typename?: 'Image'
  altText?: string | null
  height?: number | null
  id?: string | null
  originalSrc: any
  width?: number | null
  transformedSrc: any
  largeImageUrl: any
  mediumImageUrl: any
  smallImageUrl: any
  thumbImageUrl: any
  productSaleImageUrl: any
}

export type ProductVariantsFragment = {
  __typename?: 'ProductVariantConnection'
  edges: Array<{
    __typename?: 'ProductVariantEdge'
    node: {
      __typename?: 'ProductVariant'
      availableForSale: boolean
      currentlyNotInStock: boolean
      id: string
      quantityAvailable?: number | null
      requiresShipping: boolean
      sku?: string | null
      title: string
      weight?: number | null
      weightUnit: Types.WeightUnit
      compareAtPriceV2?: {
        __typename?: 'MoneyV2'
        amount: any
        currencyCode: Types.CurrencyCode
      } | null
      priceV2: {
        __typename?: 'MoneyV2'
        amount: any
        currencyCode: Types.CurrencyCode
      }
      selectedOptions: Array<{
        __typename?: 'SelectedOption'
        name: string
        value: string
      }>
      unitPrice?: {
        __typename?: 'MoneyV2'
        amount: any
        currencyCode: Types.CurrencyCode
      } | null
      image?: {
        __typename?: 'Image'
        altText?: string | null
        height?: number | null
        id?: string | null
        originalSrc: any
        width?: number | null
        thumbImageUrl: any
      } | null
      model?: {
        __typename?: 'Metafield'
        createdAt: any
        description?: string | null
        id: string
        key: string
        namespace: string
        type: string
        updatedAt: any
        value: string
      } | null
      edition_profile?: {
        __typename?: 'Metafield'
        createdAt: any
        description?: string | null
        id: string
        key: string
        namespace: string
        type: string
        updatedAt: any
        value: string
      } | null
      amazonLink?: {
        __typename?: 'Metafield'
        createdAt: any
        description?: string | null
        id: string
        key: string
        namespace: string
        type: string
        updatedAt: any
        value: string
      } | null
      sellingPoints?: {
        __typename?: 'Metafield'
        createdAt: any
        description?: string | null
        id: string
        key: string
        namespace: string
        type: string
        updatedAt: any
        value: string
      } | null
    }
  }>
}

export type ProductVariantFragment = {
  __typename?: 'ProductVariant'
  availableForSale: boolean
  currentlyNotInStock: boolean
  id: string
  quantityAvailable?: number | null
  requiresShipping: boolean
  sku?: string | null
  title: string
  weight?: number | null
  weightUnit: Types.WeightUnit
  compareAtPriceV2?: {
    __typename?: 'MoneyV2'
    amount: any
    currencyCode: Types.CurrencyCode
  } | null
  priceV2: {
    __typename?: 'MoneyV2'
    amount: any
    currencyCode: Types.CurrencyCode
  }
  selectedOptions: Array<{
    __typename?: 'SelectedOption'
    name: string
    value: string
  }>
  unitPrice?: {
    __typename?: 'MoneyV2'
    amount: any
    currencyCode: Types.CurrencyCode
  } | null
  image?: {
    __typename?: 'Image'
    altText?: string | null
    height?: number | null
    id?: string | null
    originalSrc: any
    width?: number | null
    thumbImageUrl: any
  } | null
  model?: {
    __typename?: 'Metafield'
    createdAt: any
    description?: string | null
    id: string
    key: string
    namespace: string
    type: string
    updatedAt: any
    value: string
  } | null
  edition_profile?: {
    __typename?: 'Metafield'
    createdAt: any
    description?: string | null
    id: string
    key: string
    namespace: string
    type: string
    updatedAt: any
    value: string
  } | null
  amazonLink?: {
    __typename?: 'Metafield'
    createdAt: any
    description?: string | null
    id: string
    key: string
    namespace: string
    type: string
    updatedAt: any
    value: string
  } | null
  sellingPoints?: {
    __typename?: 'Metafield'
    createdAt: any
    description?: string | null
    id: string
    key: string
    namespace: string
    type: string
    updatedAt: any
    value: string
  } | null
}

export type ImageFragment = {
  __typename?: 'Image'
  altText?: string | null
  height?: number | null
  id?: string | null
  originalSrc: any
  width?: number | null
}

export type MetaFieldLessFragment = {
  __typename?: 'Metafield'
  id: string
  value: string
}

export type ProductFewQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['ID']>
}>

export type ProductFewQuery = {
  __typename?: 'QueryRoot'
  product?: {
    __typename?: 'Product'
    title: string
    handle: string
    id: string
    description: string
    productType: string
    tags: Array<string>
    totalInventory?: number | null
    featuredImage?: {
      __typename?: 'Image'
      xs: any
      lg: any
      md: any
      xl: any
    } | null
    variants: {
      __typename?: 'ProductVariantConnection'
      edges: Array<{
        __typename?: 'ProductVariantEdge'
        node: {
          __typename?: 'ProductVariant'
          currentlyNotInStock: boolean
          id: string
          sku?: string | null
          title: string
          compareAtPriceV2?: {
            __typename?: 'MoneyV2'
            amount: any
            currencyCode: Types.CurrencyCode
          } | null
          priceV2: {
            __typename?: 'MoneyV2'
            amount: any
            currencyCode: Types.CurrencyCode
          }
          image?: {
            __typename?: 'Image'
            xs: any
            lg: any
            md: any
            xl: any
          } | null
        }
      }>
    }
  } | null
}

export type SimpleProductVariantFragment = {
  __typename?: 'ProductVariant'
  currentlyNotInStock: boolean
  id: string
  sku?: string | null
  title: string
  compareAtPriceV2?: {
    __typename?: 'MoneyV2'
    amount: any
    currencyCode: Types.CurrencyCode
  } | null
  priceV2: {
    __typename?: 'MoneyV2'
    amount: any
    currencyCode: Types.CurrencyCode
  }
  image?: { __typename?: 'Image'; xs: any; lg: any; md: any; xl: any } | null
}

export type ProductForProductHeaderQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['ID']>
  handle?: Types.InputMaybe<Types.Scalars['String']>
}>

export type ProductForProductHeaderQuery = {
  __typename?: 'QueryRoot'
  product?: {
    __typename?: 'Product'
    availableForSale: boolean
    description: string
    descriptionHtml: any
    handle: string
    id: string
    tags: Array<string>
    productType: string
    title: string
    totalInventory?: number | null
    updatedAt: any
    vendor: string
    compareAtPriceRange: {
      __typename?: 'ProductPriceRange'
      maxVariantPrice: {
        __typename?: 'MoneyV2'
        amount: any
        currencyCode: Types.CurrencyCode
      }
      minVariantPrice: {
        __typename?: 'MoneyV2'
        amount: any
        currencyCode: Types.CurrencyCode
      }
    }
    landingPage?: {
      __typename?: 'Metafield'
      createdAt: any
      description?: string | null
      id: string
      key: string
      namespace: string
      type: string
      updatedAt: any
      value: string
    } | null
    model?: {
      __typename?: 'Metafield'
      createdAt: any
      description?: string | null
      id: string
      key: string
      namespace: string
      type: string
      updatedAt: any
      value: string
    } | null
    sellingPoints?: {
      __typename?: 'Metafield'
      createdAt: any
      description?: string | null
      id: string
      key: string
      namespace: string
      type: string
      updatedAt: any
      value: string
    } | null
    simpleDescription?: {
      __typename?: 'Metafield'
      createdAt: any
      description?: string | null
      id: string
      key: string
      namespace: string
      type: string
      updatedAt: any
      value: string
    } | null
    whatIsInTheBox?: {
      __typename?: 'Metafield'
      createdAt: any
      description?: string | null
      id: string
      key: string
      namespace: string
      type: string
      updatedAt: any
      value: string
    } | null
    specs?: {
      __typename?: 'Metafield'
      createdAt: any
      description?: string | null
      id: string
      key: string
      namespace: string
      type: string
      updatedAt: any
      value: string
    } | null
    faqs?: {
      __typename?: 'Metafield'
      createdAt: any
      description?: string | null
      id: string
      key: string
      namespace: string
      type: string
      updatedAt: any
      value: string
    } | null
    manuals?: {
      __typename?: 'Metafield'
      createdAt: any
      description?: string | null
      id: string
      key: string
      namespace: string
      type: string
      updatedAt: any
      value: string
    } | null
    amazonLink?: {
      __typename?: 'Metafield'
      createdAt: any
      description?: string | null
      id: string
      key: string
      namespace: string
      type: string
      updatedAt: any
      value: string
    } | null
    seo: {
      __typename?: 'SEO'
      title?: string | null
      description?: string | null
    }
    SEO_H1?: {
      __typename?: 'Metafield'
      createdAt: any
      description?: string | null
      id: string
      key: string
      namespace: string
      type: string
      updatedAt: any
      value: string
    } | null
    images: {
      __typename?: 'ImageConnection'
      edges: Array<{
        __typename?: 'ImageEdge'
        node: {
          __typename?: 'Image'
          altText?: string | null
          height?: number | null
          id?: string | null
          originalSrc: any
          width?: number | null
          transformedSrc: any
          largeImageUrl: any
          mediumImageUrl: any
          smallImageUrl: any
          thumbImageUrl: any
          productSaleImageUrl: any
        }
      }>
    }
    variants: {
      __typename?: 'ProductVariantConnection'
      edges: Array<{
        __typename?: 'ProductVariantEdge'
        node: {
          __typename?: 'ProductVariant'
          availableForSale: boolean
          currentlyNotInStock: boolean
          id: string
          quantityAvailable?: number | null
          requiresShipping: boolean
          sku?: string | null
          title: string
          weight?: number | null
          weightUnit: Types.WeightUnit
          compareAtPriceV2?: {
            __typename?: 'MoneyV2'
            amount: any
            currencyCode: Types.CurrencyCode
          } | null
          priceV2: {
            __typename?: 'MoneyV2'
            amount: any
            currencyCode: Types.CurrencyCode
          }
          selectedOptions: Array<{
            __typename?: 'SelectedOption'
            name: string
            value: string
          }>
          unitPrice?: {
            __typename?: 'MoneyV2'
            amount: any
            currencyCode: Types.CurrencyCode
          } | null
          image?: {
            __typename?: 'Image'
            altText?: string | null
            height?: number | null
            id?: string | null
            originalSrc: any
            width?: number | null
            thumbImageUrl: any
          } | null
          model?: {
            __typename?: 'Metafield'
            createdAt: any
            description?: string | null
            id: string
            key: string
            namespace: string
            type: string
            updatedAt: any
            value: string
          } | null
          edition_profile?: {
            __typename?: 'Metafield'
            createdAt: any
            description?: string | null
            id: string
            key: string
            namespace: string
            type: string
            updatedAt: any
            value: string
          } | null
          amazonLink?: {
            __typename?: 'Metafield'
            createdAt: any
            description?: string | null
            id: string
            key: string
            namespace: string
            type: string
            updatedAt: any
            value: string
          } | null
          sellingPoints?: {
            __typename?: 'Metafield'
            createdAt: any
            description?: string | null
            id: string
            key: string
            namespace: string
            type: string
            updatedAt: any
            value: string
          } | null
        }
      }>
    }
    seriesName?: {
      __typename?: 'Metafield'
      createdAt: any
      description?: string | null
      id: string
      key: string
      namespace: string
      type: string
      updatedAt: any
      value: string
    } | null
    howToUse?: { __typename?: 'Metafield'; id: string; value: string } | null
    discountJson?: {
      __typename?: 'Metafield'
      id: string
      value: string
    } | null
    promotionalEndTime?: {
      __typename?: 'Metafield'
      id: string
      value: string
    } | null
  } | null
}

export type GetProductVariantQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']
  selectedOptions: Array<Types.SelectedOptionInput> | Types.SelectedOptionInput
}>

export type GetProductVariantQuery = {
  __typename?: 'QueryRoot'
  product?: {
    __typename?: 'Product'
    availableForSale: boolean
    description: string
    descriptionHtml: any
    handle: string
    id: string
    tags: Array<string>
    productType: string
    title: string
    totalInventory?: number | null
    updatedAt: any
    vendor: string
    variantBySelectedOptions?: {
      __typename?: 'ProductVariant'
      availableForSale: boolean
      currentlyNotInStock: boolean
      id: string
      quantityAvailable?: number | null
      requiresShipping: boolean
      sku?: string | null
      title: string
      weight?: number | null
      weightUnit: Types.WeightUnit
      compareAtPriceV2?: {
        __typename?: 'MoneyV2'
        amount: any
        currencyCode: Types.CurrencyCode
      } | null
      priceV2: {
        __typename?: 'MoneyV2'
        amount: any
        currencyCode: Types.CurrencyCode
      }
      selectedOptions: Array<{
        __typename?: 'SelectedOption'
        name: string
        value: string
      }>
      unitPrice?: {
        __typename?: 'MoneyV2'
        amount: any
        currencyCode: Types.CurrencyCode
      } | null
      image?: {
        __typename?: 'Image'
        altText?: string | null
        height?: number | null
        id?: string | null
        originalSrc: any
        width?: number | null
        thumbImageUrl: any
      } | null
      model?: {
        __typename?: 'Metafield'
        createdAt: any
        description?: string | null
        id: string
        key: string
        namespace: string
        type: string
        updatedAt: any
        value: string
      } | null
      edition_profile?: {
        __typename?: 'Metafield'
        createdAt: any
        description?: string | null
        id: string
        key: string
        namespace: string
        type: string
        updatedAt: any
        value: string
      } | null
      amazonLink?: {
        __typename?: 'Metafield'
        createdAt: any
        description?: string | null
        id: string
        key: string
        namespace: string
        type: string
        updatedAt: any
        value: string
      } | null
      sellingPoints?: {
        __typename?: 'Metafield'
        createdAt: any
        description?: string | null
        id: string
        key: string
        namespace: string
        type: string
        updatedAt: any
        value: string
      } | null
    } | null
    compareAtPriceRange: {
      __typename?: 'ProductPriceRange'
      maxVariantPrice: {
        __typename?: 'MoneyV2'
        amount: any
        currencyCode: Types.CurrencyCode
      }
      minVariantPrice: {
        __typename?: 'MoneyV2'
        amount: any
        currencyCode: Types.CurrencyCode
      }
    }
    landingPage?: {
      __typename?: 'Metafield'
      createdAt: any
      description?: string | null
      id: string
      key: string
      namespace: string
      type: string
      updatedAt: any
      value: string
    } | null
    model?: {
      __typename?: 'Metafield'
      createdAt: any
      description?: string | null
      id: string
      key: string
      namespace: string
      type: string
      updatedAt: any
      value: string
    } | null
    sellingPoints?: {
      __typename?: 'Metafield'
      createdAt: any
      description?: string | null
      id: string
      key: string
      namespace: string
      type: string
      updatedAt: any
      value: string
    } | null
    simpleDescription?: {
      __typename?: 'Metafield'
      createdAt: any
      description?: string | null
      id: string
      key: string
      namespace: string
      type: string
      updatedAt: any
      value: string
    } | null
    whatIsInTheBox?: {
      __typename?: 'Metafield'
      createdAt: any
      description?: string | null
      id: string
      key: string
      namespace: string
      type: string
      updatedAt: any
      value: string
    } | null
    specs?: {
      __typename?: 'Metafield'
      createdAt: any
      description?: string | null
      id: string
      key: string
      namespace: string
      type: string
      updatedAt: any
      value: string
    } | null
    faqs?: {
      __typename?: 'Metafield'
      createdAt: any
      description?: string | null
      id: string
      key: string
      namespace: string
      type: string
      updatedAt: any
      value: string
    } | null
    manuals?: {
      __typename?: 'Metafield'
      createdAt: any
      description?: string | null
      id: string
      key: string
      namespace: string
      type: string
      updatedAt: any
      value: string
    } | null
    amazonLink?: {
      __typename?: 'Metafield'
      createdAt: any
      description?: string | null
      id: string
      key: string
      namespace: string
      type: string
      updatedAt: any
      value: string
    } | null
    seo: {
      __typename?: 'SEO'
      title?: string | null
      description?: string | null
    }
    SEO_H1?: {
      __typename?: 'Metafield'
      createdAt: any
      description?: string | null
      id: string
      key: string
      namespace: string
      type: string
      updatedAt: any
      value: string
    } | null
    images: {
      __typename?: 'ImageConnection'
      edges: Array<{
        __typename?: 'ImageEdge'
        node: {
          __typename?: 'Image'
          altText?: string | null
          height?: number | null
          id?: string | null
          originalSrc: any
          width?: number | null
          transformedSrc: any
          largeImageUrl: any
          mediumImageUrl: any
          smallImageUrl: any
          thumbImageUrl: any
          productSaleImageUrl: any
        }
      }>
    }
    variants: {
      __typename?: 'ProductVariantConnection'
      edges: Array<{
        __typename?: 'ProductVariantEdge'
        node: {
          __typename?: 'ProductVariant'
          availableForSale: boolean
          currentlyNotInStock: boolean
          id: string
          quantityAvailable?: number | null
          requiresShipping: boolean
          sku?: string | null
          title: string
          weight?: number | null
          weightUnit: Types.WeightUnit
          compareAtPriceV2?: {
            __typename?: 'MoneyV2'
            amount: any
            currencyCode: Types.CurrencyCode
          } | null
          priceV2: {
            __typename?: 'MoneyV2'
            amount: any
            currencyCode: Types.CurrencyCode
          }
          selectedOptions: Array<{
            __typename?: 'SelectedOption'
            name: string
            value: string
          }>
          unitPrice?: {
            __typename?: 'MoneyV2'
            amount: any
            currencyCode: Types.CurrencyCode
          } | null
          image?: {
            __typename?: 'Image'
            altText?: string | null
            height?: number | null
            id?: string | null
            originalSrc: any
            width?: number | null
            thumbImageUrl: any
          } | null
          model?: {
            __typename?: 'Metafield'
            createdAt: any
            description?: string | null
            id: string
            key: string
            namespace: string
            type: string
            updatedAt: any
            value: string
          } | null
          edition_profile?: {
            __typename?: 'Metafield'
            createdAt: any
            description?: string | null
            id: string
            key: string
            namespace: string
            type: string
            updatedAt: any
            value: string
          } | null
          amazonLink?: {
            __typename?: 'Metafield'
            createdAt: any
            description?: string | null
            id: string
            key: string
            namespace: string
            type: string
            updatedAt: any
            value: string
          } | null
          sellingPoints?: {
            __typename?: 'Metafield'
            createdAt: any
            description?: string | null
            id: string
            key: string
            namespace: string
            type: string
            updatedAt: any
            value: string
          } | null
        }
      }>
    }
    seriesName?: {
      __typename?: 'Metafield'
      createdAt: any
      description?: string | null
      id: string
      key: string
      namespace: string
      type: string
      updatedAt: any
      value: string
    } | null
    howToUse?: { __typename?: 'Metafield'; id: string; value: string } | null
    discountJson?: {
      __typename?: 'Metafield'
      id: string
      value: string
    } | null
    promotionalEndTime?: {
      __typename?: 'Metafield'
      id: string
      value: string
    } | null
  } | null
}

export type ProductVariantQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']
}>

export type ProductVariantQuery = {
  __typename?: 'QueryRoot'
  node?:
    | { __typename?: 'AppliedGiftCard' }
    | { __typename?: 'Article' }
    | { __typename?: 'Blog' }
    | { __typename?: 'Cart' }
    | { __typename?: 'CartLine' }
    | { __typename?: 'Checkout' }
    | { __typename?: 'CheckoutLineItem' }
    | { __typename?: 'Collection' }
    | { __typename?: 'Comment' }
    | { __typename?: 'ExternalVideo' }
    | { __typename?: 'GenericFile' }
    | { __typename?: 'Location' }
    | { __typename?: 'MailingAddress' }
    | { __typename?: 'MediaImage' }
    | { __typename?: 'Menu' }
    | { __typename?: 'MenuItem' }
    | { __typename?: 'Metafield' }
    | { __typename?: 'Metaobject' }
    | { __typename?: 'Model3d' }
    | { __typename?: 'Order' }
    | { __typename?: 'Page' }
    | { __typename?: 'Payment' }
    | { __typename?: 'Product' }
    | { __typename?: 'ProductOption' }
    | {
        __typename?: 'ProductVariant'
        availableForSale: boolean
        currentlyNotInStock: boolean
        id: string
        quantityAvailable?: number | null
        requiresShipping: boolean
        sku?: string | null
        title: string
        weight?: number | null
        weightUnit: Types.WeightUnit
        compareAtPriceV2?: {
          __typename?: 'MoneyV2'
          amount: any
          currencyCode: Types.CurrencyCode
        } | null
        priceV2: {
          __typename?: 'MoneyV2'
          amount: any
          currencyCode: Types.CurrencyCode
        }
        selectedOptions: Array<{
          __typename?: 'SelectedOption'
          name: string
          value: string
        }>
        unitPrice?: {
          __typename?: 'MoneyV2'
          amount: any
          currencyCode: Types.CurrencyCode
        } | null
        image?: {
          __typename?: 'Image'
          altText?: string | null
          height?: number | null
          id?: string | null
          originalSrc: any
          width?: number | null
          thumbImageUrl: any
        } | null
        model?: {
          __typename?: 'Metafield'
          createdAt: any
          description?: string | null
          id: string
          key: string
          namespace: string
          type: string
          updatedAt: any
          value: string
        } | null
        edition_profile?: {
          __typename?: 'Metafield'
          createdAt: any
          description?: string | null
          id: string
          key: string
          namespace: string
          type: string
          updatedAt: any
          value: string
        } | null
        amazonLink?: {
          __typename?: 'Metafield'
          createdAt: any
          description?: string | null
          id: string
          key: string
          namespace: string
          type: string
          updatedAt: any
          value: string
        } | null
        sellingPoints?: {
          __typename?: 'Metafield'
          createdAt: any
          description?: string | null
          id: string
          key: string
          namespace: string
          type: string
          updatedAt: any
          value: string
        } | null
      }
    | { __typename?: 'Shop' }
    | { __typename?: 'ShopPolicy' }
    | { __typename?: 'UrlRedirect' }
    | { __typename?: 'Video' }
    | null
}

export type QueryProductVariantQuantityQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']
}>

export type QueryProductVariantQuantityQuery = {
  __typename?: 'QueryRoot'
  node?:
    | { __typename?: 'AppliedGiftCard' }
    | { __typename?: 'Article' }
    | { __typename?: 'Blog' }
    | { __typename?: 'Cart' }
    | { __typename?: 'CartLine' }
    | { __typename?: 'Checkout' }
    | { __typename?: 'CheckoutLineItem' }
    | { __typename?: 'Collection' }
    | { __typename?: 'Comment' }
    | { __typename?: 'ExternalVideo' }
    | { __typename?: 'GenericFile' }
    | { __typename?: 'Location' }
    | { __typename?: 'MailingAddress' }
    | { __typename?: 'MediaImage' }
    | { __typename?: 'Menu' }
    | { __typename?: 'MenuItem' }
    | { __typename?: 'Metafield' }
    | { __typename?: 'Metaobject' }
    | { __typename?: 'Model3d' }
    | { __typename?: 'Order' }
    | { __typename?: 'Page' }
    | { __typename?: 'Payment' }
    | { __typename?: 'Product' }
    | { __typename?: 'ProductOption' }
    | { __typename?: 'ProductVariant'; quantityAvailable?: number | null }
    | { __typename?: 'Shop' }
    | { __typename?: 'ShopPolicy' }
    | { __typename?: 'UrlRedirect' }
    | { __typename?: 'Video' }
    | null
}

export type ProductRecommendationsQueryVariables = Types.Exact<{
  productId: Types.Scalars['ID']
}>

export type ProductRecommendationsQuery = {
  __typename?: 'QueryRoot'
  productRecommendations?: Array<{
    __typename?: 'Product'
    availableForSale: boolean
    description: string
    descriptionHtml: any
    handle: string
    id: string
    tags: Array<string>
    productType: string
    title: string
    totalInventory?: number | null
    updatedAt: any
    vendor: string
    compareAtPriceRange: {
      __typename?: 'ProductPriceRange'
      maxVariantPrice: {
        __typename?: 'MoneyV2'
        amount: any
        currencyCode: Types.CurrencyCode
      }
      minVariantPrice: {
        __typename?: 'MoneyV2'
        amount: any
        currencyCode: Types.CurrencyCode
      }
    }
    landingPage?: {
      __typename?: 'Metafield'
      createdAt: any
      description?: string | null
      id: string
      key: string
      namespace: string
      type: string
      updatedAt: any
      value: string
    } | null
    model?: {
      __typename?: 'Metafield'
      createdAt: any
      description?: string | null
      id: string
      key: string
      namespace: string
      type: string
      updatedAt: any
      value: string
    } | null
    sellingPoints?: {
      __typename?: 'Metafield'
      createdAt: any
      description?: string | null
      id: string
      key: string
      namespace: string
      type: string
      updatedAt: any
      value: string
    } | null
    simpleDescription?: {
      __typename?: 'Metafield'
      createdAt: any
      description?: string | null
      id: string
      key: string
      namespace: string
      type: string
      updatedAt: any
      value: string
    } | null
    whatIsInTheBox?: {
      __typename?: 'Metafield'
      createdAt: any
      description?: string | null
      id: string
      key: string
      namespace: string
      type: string
      updatedAt: any
      value: string
    } | null
    specs?: {
      __typename?: 'Metafield'
      createdAt: any
      description?: string | null
      id: string
      key: string
      namespace: string
      type: string
      updatedAt: any
      value: string
    } | null
    faqs?: {
      __typename?: 'Metafield'
      createdAt: any
      description?: string | null
      id: string
      key: string
      namespace: string
      type: string
      updatedAt: any
      value: string
    } | null
    manuals?: {
      __typename?: 'Metafield'
      createdAt: any
      description?: string | null
      id: string
      key: string
      namespace: string
      type: string
      updatedAt: any
      value: string
    } | null
    amazonLink?: {
      __typename?: 'Metafield'
      createdAt: any
      description?: string | null
      id: string
      key: string
      namespace: string
      type: string
      updatedAt: any
      value: string
    } | null
    seo: {
      __typename?: 'SEO'
      title?: string | null
      description?: string | null
    }
    SEO_H1?: {
      __typename?: 'Metafield'
      createdAt: any
      description?: string | null
      id: string
      key: string
      namespace: string
      type: string
      updatedAt: any
      value: string
    } | null
    images: {
      __typename?: 'ImageConnection'
      edges: Array<{
        __typename?: 'ImageEdge'
        node: {
          __typename?: 'Image'
          altText?: string | null
          height?: number | null
          id?: string | null
          originalSrc: any
          width?: number | null
          transformedSrc: any
          largeImageUrl: any
          mediumImageUrl: any
          smallImageUrl: any
          thumbImageUrl: any
          productSaleImageUrl: any
        }
      }>
    }
    variants: {
      __typename?: 'ProductVariantConnection'
      edges: Array<{
        __typename?: 'ProductVariantEdge'
        node: {
          __typename?: 'ProductVariant'
          availableForSale: boolean
          currentlyNotInStock: boolean
          id: string
          quantityAvailable?: number | null
          requiresShipping: boolean
          sku?: string | null
          title: string
          weight?: number | null
          weightUnit: Types.WeightUnit
          compareAtPriceV2?: {
            __typename?: 'MoneyV2'
            amount: any
            currencyCode: Types.CurrencyCode
          } | null
          priceV2: {
            __typename?: 'MoneyV2'
            amount: any
            currencyCode: Types.CurrencyCode
          }
          selectedOptions: Array<{
            __typename?: 'SelectedOption'
            name: string
            value: string
          }>
          unitPrice?: {
            __typename?: 'MoneyV2'
            amount: any
            currencyCode: Types.CurrencyCode
          } | null
          image?: {
            __typename?: 'Image'
            altText?: string | null
            height?: number | null
            id?: string | null
            originalSrc: any
            width?: number | null
            thumbImageUrl: any
          } | null
          model?: {
            __typename?: 'Metafield'
            createdAt: any
            description?: string | null
            id: string
            key: string
            namespace: string
            type: string
            updatedAt: any
            value: string
          } | null
          edition_profile?: {
            __typename?: 'Metafield'
            createdAt: any
            description?: string | null
            id: string
            key: string
            namespace: string
            type: string
            updatedAt: any
            value: string
          } | null
          amazonLink?: {
            __typename?: 'Metafield'
            createdAt: any
            description?: string | null
            id: string
            key: string
            namespace: string
            type: string
            updatedAt: any
            value: string
          } | null
          sellingPoints?: {
            __typename?: 'Metafield'
            createdAt: any
            description?: string | null
            id: string
            key: string
            namespace: string
            type: string
            updatedAt: any
            value: string
          } | null
        }
      }>
    }
    seriesName?: {
      __typename?: 'Metafield'
      createdAt: any
      description?: string | null
      id: string
      key: string
      namespace: string
      type: string
      updatedAt: any
      value: string
    } | null
    howToUse?: { __typename?: 'Metafield'; id: string; value: string } | null
    discountJson?: {
      __typename?: 'Metafield'
      id: string
      value: string
    } | null
    promotionalEndTime?: {
      __typename?: 'Metafield'
      id: string
      value: string
    } | null
  }> | null
}

export type ProductAccessoriesQueryVariables = Types.Exact<{
  query: Types.Scalars['String']
}>

export type ProductAccessoriesQuery = {
  __typename?: 'QueryRoot'
  products: {
    __typename?: 'ProductConnection'
    edges: Array<{
      __typename?: 'ProductEdge'
      node: {
        __typename?: 'Product'
        availableForSale: boolean
        description: string
        descriptionHtml: any
        handle: string
        id: string
        tags: Array<string>
        productType: string
        title: string
        totalInventory?: number | null
        updatedAt: any
        vendor: string
        compareAtPriceRange: {
          __typename?: 'ProductPriceRange'
          maxVariantPrice: {
            __typename?: 'MoneyV2'
            amount: any
            currencyCode: Types.CurrencyCode
          }
          minVariantPrice: {
            __typename?: 'MoneyV2'
            amount: any
            currencyCode: Types.CurrencyCode
          }
        }
        landingPage?: {
          __typename?: 'Metafield'
          createdAt: any
          description?: string | null
          id: string
          key: string
          namespace: string
          type: string
          updatedAt: any
          value: string
        } | null
        model?: {
          __typename?: 'Metafield'
          createdAt: any
          description?: string | null
          id: string
          key: string
          namespace: string
          type: string
          updatedAt: any
          value: string
        } | null
        sellingPoints?: {
          __typename?: 'Metafield'
          createdAt: any
          description?: string | null
          id: string
          key: string
          namespace: string
          type: string
          updatedAt: any
          value: string
        } | null
        simpleDescription?: {
          __typename?: 'Metafield'
          createdAt: any
          description?: string | null
          id: string
          key: string
          namespace: string
          type: string
          updatedAt: any
          value: string
        } | null
        whatIsInTheBox?: {
          __typename?: 'Metafield'
          createdAt: any
          description?: string | null
          id: string
          key: string
          namespace: string
          type: string
          updatedAt: any
          value: string
        } | null
        specs?: {
          __typename?: 'Metafield'
          createdAt: any
          description?: string | null
          id: string
          key: string
          namespace: string
          type: string
          updatedAt: any
          value: string
        } | null
        faqs?: {
          __typename?: 'Metafield'
          createdAt: any
          description?: string | null
          id: string
          key: string
          namespace: string
          type: string
          updatedAt: any
          value: string
        } | null
        manuals?: {
          __typename?: 'Metafield'
          createdAt: any
          description?: string | null
          id: string
          key: string
          namespace: string
          type: string
          updatedAt: any
          value: string
        } | null
        amazonLink?: {
          __typename?: 'Metafield'
          createdAt: any
          description?: string | null
          id: string
          key: string
          namespace: string
          type: string
          updatedAt: any
          value: string
        } | null
        seo: {
          __typename?: 'SEO'
          title?: string | null
          description?: string | null
        }
        SEO_H1?: {
          __typename?: 'Metafield'
          createdAt: any
          description?: string | null
          id: string
          key: string
          namespace: string
          type: string
          updatedAt: any
          value: string
        } | null
        images: {
          __typename?: 'ImageConnection'
          edges: Array<{
            __typename?: 'ImageEdge'
            node: {
              __typename?: 'Image'
              altText?: string | null
              height?: number | null
              id?: string | null
              originalSrc: any
              width?: number | null
              transformedSrc: any
              largeImageUrl: any
              mediumImageUrl: any
              smallImageUrl: any
              thumbImageUrl: any
              productSaleImageUrl: any
            }
          }>
        }
        variants: {
          __typename?: 'ProductVariantConnection'
          edges: Array<{
            __typename?: 'ProductVariantEdge'
            node: {
              __typename?: 'ProductVariant'
              availableForSale: boolean
              currentlyNotInStock: boolean
              id: string
              quantityAvailable?: number | null
              requiresShipping: boolean
              sku?: string | null
              title: string
              weight?: number | null
              weightUnit: Types.WeightUnit
              compareAtPriceV2?: {
                __typename?: 'MoneyV2'
                amount: any
                currencyCode: Types.CurrencyCode
              } | null
              priceV2: {
                __typename?: 'MoneyV2'
                amount: any
                currencyCode: Types.CurrencyCode
              }
              selectedOptions: Array<{
                __typename?: 'SelectedOption'
                name: string
                value: string
              }>
              unitPrice?: {
                __typename?: 'MoneyV2'
                amount: any
                currencyCode: Types.CurrencyCode
              } | null
              image?: {
                __typename?: 'Image'
                altText?: string | null
                height?: number | null
                id?: string | null
                originalSrc: any
                width?: number | null
                thumbImageUrl: any
              } | null
              model?: {
                __typename?: 'Metafield'
                createdAt: any
                description?: string | null
                id: string
                key: string
                namespace: string
                type: string
                updatedAt: any
                value: string
              } | null
              edition_profile?: {
                __typename?: 'Metafield'
                createdAt: any
                description?: string | null
                id: string
                key: string
                namespace: string
                type: string
                updatedAt: any
                value: string
              } | null
              amazonLink?: {
                __typename?: 'Metafield'
                createdAt: any
                description?: string | null
                id: string
                key: string
                namespace: string
                type: string
                updatedAt: any
                value: string
              } | null
              sellingPoints?: {
                __typename?: 'Metafield'
                createdAt: any
                description?: string | null
                id: string
                key: string
                namespace: string
                type: string
                updatedAt: any
                value: string
              } | null
            }
          }>
        }
        seriesName?: {
          __typename?: 'Metafield'
          createdAt: any
          description?: string | null
          id: string
          key: string
          namespace: string
          type: string
          updatedAt: any
          value: string
        } | null
        howToUse?: {
          __typename?: 'Metafield'
          id: string
          value: string
        } | null
        discountJson?: {
          __typename?: 'Metafield'
          id: string
          value: string
        } | null
        promotionalEndTime?: {
          __typename?: 'Metafield'
          id: string
          value: string
        } | null
      }
    }>
  }
}

export type ProductRecommendationFragment = {
  __typename?: 'Product'
  handle: string
  featuredImage?: { __typename?: 'Image'; url: any } | null
  recommendationImage?: {
    __typename?: 'Metafield'
    createdAt: any
    description?: string | null
    id: string
    key: string
    namespace: string
    type: string
    updatedAt: any
    value: string
  } | null
  seriesName?: {
    __typename?: 'Metafield'
    createdAt: any
    description?: string | null
    id: string
    key: string
    namespace: string
    type: string
    updatedAt: any
    value: string
  } | null
}

export type GetProductRecommendationQueryVariables = Types.Exact<{
  handle?: Types.InputMaybe<Types.Scalars['String']>
}>

export type GetProductRecommendationQuery = {
  __typename?: 'QueryRoot'
  product?: {
    __typename?: 'Product'
    handle: string
    featuredImage?: { __typename?: 'Image'; url: any } | null
    recommendationImage?: {
      __typename?: 'Metafield'
      createdAt: any
      description?: string | null
      id: string
      key: string
      namespace: string
      type: string
      updatedAt: any
      value: string
    } | null
    seriesName?: {
      __typename?: 'Metafield'
      createdAt: any
      description?: string | null
      id: string
      key: string
      namespace: string
      type: string
      updatedAt: any
      value: string
    } | null
  } | null
}

export type ProductVariantQuantityDataFragment = {
  __typename?: 'ProductVariant'
  quantityAvailable?: number | null
  sku?: string | null
  image?: { __typename?: 'Image'; originalSrc: any } | null
  product: {
    __typename?: 'Product'
    productType: string
    featuredImage?: { __typename?: 'Image'; originalSrc: any } | null
    model?: {
      __typename?: 'Metafield'
      createdAt: any
      description?: string | null
      id: string
      key: string
      namespace: string
      type: string
      updatedAt: any
      value: string
    } | null
  }
  model?: {
    __typename?: 'Metafield'
    createdAt: any
    description?: string | null
    id: string
    key: string
    namespace: string
    type: string
    updatedAt: any
    value: string
  } | null
}

export type ProductVariantsQuantityQueryVariables = Types.Exact<{
  ids: Array<Types.Scalars['ID']> | Types.Scalars['ID']
}>

export type ProductVariantsQuantityQuery = {
  __typename?: 'QueryRoot'
  nodes: Array<
    | { __typename?: 'AppliedGiftCard' }
    | { __typename?: 'Article' }
    | { __typename?: 'Blog' }
    | { __typename?: 'Cart' }
    | { __typename?: 'CartLine' }
    | { __typename?: 'Checkout' }
    | { __typename?: 'CheckoutLineItem' }
    | { __typename?: 'Collection' }
    | { __typename?: 'Comment' }
    | { __typename?: 'ExternalVideo' }
    | { __typename?: 'GenericFile' }
    | { __typename?: 'Location' }
    | { __typename?: 'MailingAddress' }
    | { __typename?: 'MediaImage' }
    | { __typename?: 'Menu' }
    | { __typename?: 'MenuItem' }
    | { __typename?: 'Metafield' }
    | { __typename?: 'Metaobject' }
    | { __typename?: 'Model3d' }
    | { __typename?: 'Order' }
    | { __typename?: 'Page' }
    | { __typename?: 'Payment' }
    | { __typename?: 'Product' }
    | { __typename?: 'ProductOption' }
    | {
        __typename?: 'ProductVariant'
        quantityAvailable?: number | null
        sku?: string | null
        image?: { __typename?: 'Image'; originalSrc: any } | null
        product: {
          __typename?: 'Product'
          productType: string
          featuredImage?: { __typename?: 'Image'; originalSrc: any } | null
          model?: {
            __typename?: 'Metafield'
            createdAt: any
            description?: string | null
            id: string
            key: string
            namespace: string
            type: string
            updatedAt: any
            value: string
          } | null
        }
        model?: {
          __typename?: 'Metafield'
          createdAt: any
          description?: string | null
          id: string
          key: string
          namespace: string
          type: string
          updatedAt: any
          value: string
        } | null
      }
    | { __typename?: 'Shop' }
    | { __typename?: 'ShopPolicy' }
    | { __typename?: 'UrlRedirect' }
    | { __typename?: 'Video' }
    | null
  >
}

export const MetafieldFragmentDoc = gql`
  fragment Metafield on Metafield {
    createdAt
    description
    id
    key
    namespace
    type
    updatedAt
    value
  }
`
export const ProductImageFragmentDoc = gql`
  fragment ProductImage on Image {
    altText
    height
    id
    originalSrc
    width
    transformedSrc(maxWidth: 400)
    largeImageUrl: transformedSrc(maxWidth: 1440)
    mediumImageUrl: transformedSrc(maxWidth: 992, crop: CENTER)
    smallImageUrl: transformedSrc(maxWidth: 768)
    thumbImageUrl: url(transform: { maxWidth: 162 })
    productSaleImageUrl: url(
      transform: { maxWidth: 300, maxHeight: 400, crop: CENTER }
    )
  }
`
export const ImageFragmentDoc = gql`
  fragment Image on Image {
    altText
    height
    id
    originalSrc
    width
  }
`
export const ProductVariantFragmentDoc = gql`
  fragment ProductVariant on ProductVariant {
    availableForSale
    compareAtPriceV2 {
      amount
      currencyCode
    }
    currentlyNotInStock
    id
    priceV2 {
      amount
      currencyCode
    }
    quantityAvailable
    requiresShipping
    selectedOptions {
      name
      value
    }
    sku
    title
    unitPrice {
      amount
      currencyCode
    }
    weight
    weightUnit
    image {
      ...Image
      thumbImageUrl: url(transform: { maxWidth: 162 })
    }
    model: metafield(namespace: "variant_fields", key: "model") {
      ...Metafield
    }
    edition_profile: metafield(
      namespace: "variant_fields"
      key: "edition_profile"
    ) {
      ...Metafield
    }
    amazonLink: metafield(namespace: "variant_fields", key: "amazon_link") {
      ...Metafield
    }
    sellingPoints: metafield(
      namespace: "variant_fields"
      key: "product_selling_points"
    ) {
      ...Metafield
    }
  }
  ${ImageFragmentDoc}
  ${MetafieldFragmentDoc}
`
export const ProductVariantsFragmentDoc = gql`
  fragment ProductVariants on ProductVariantConnection {
    edges {
      node {
        ...ProductVariant
      }
    }
  }
  ${ProductVariantFragmentDoc}
`
export const MetaFieldLessFragmentDoc = gql`
  fragment MetaFieldLess on Metafield {
    id
    value
  }
`
export const ProductFragmentDoc = gql`
  fragment Product on Product {
    availableForSale
    compareAtPriceRange {
      ... on ProductPriceRange {
        maxVariantPrice {
          ... on MoneyV2 {
            amount
            currencyCode
          }
        }
        minVariantPrice {
          ... on MoneyV2 {
            amount
            currencyCode
          }
        }
      }
    }
    landingPage: metafield(namespace: "product_fields", key: "landing_page") {
      ...Metafield
    }
    model: metafield(namespace: "product_fields", key: "model") {
      ...Metafield
    }
    sellingPoints: metafield(
      namespace: "product_fields"
      key: "product_selling_points"
    ) {
      ...Metafield
    }
    simpleDescription: metafield(
      namespace: "product_fields"
      key: "description"
    ) {
      ...Metafield
    }
    whatIsInTheBox: metafield(
      namespace: "product_fields"
      key: "what_is_in_the_box"
    ) {
      ...Metafield
    }
    specs: metafield(namespace: "product_fields", key: "specs") {
      ...Metafield
    }
    faqs: metafield(namespace: "product_fields", key: "faqs") {
      ...Metafield
    }
    manuals: metafield(namespace: "product_fields", key: "manuals") {
      ...Metafield
    }
    amazonLink: metafield(namespace: "product_fields", key: "amazon_link") {
      ...Metafield
    }
    description
    descriptionHtml
    handle
    id
    tags
    productType
    title
    totalInventory
    updatedAt
    vendor
    seo {
      title
      description
    }
    SEO_H1: metafield(namespace: "product_fields", key: "seo_h1") {
      ...Metafield
    }
    images(first: 100) {
      edges {
        node {
          ...ProductImage
        }
      }
    }
    variants(first: 100) {
      ...ProductVariants
    }
    seriesName: metafield(namespace: "product_fields", key: "series_name") {
      ...Metafield
    }
    howToUse: metafield(namespace: "product_fields", key: "how_to_use") {
      ...MetaFieldLess
    }
    discountJson: metafield(namespace: "product_fields", key: "discount_json") {
      ...MetaFieldLess
    }
    promotionalEndTime: metafield(
      namespace: "product_fields"
      key: "promotional_end_time"
    ) {
      ...MetaFieldLess
    }
  }
  ${MetafieldFragmentDoc}
  ${ProductImageFragmentDoc}
  ${ProductVariantsFragmentDoc}
  ${MetaFieldLessFragmentDoc}
`
export const SimpleProductVariantFragmentDoc = gql`
  fragment SimpleProductVariant on ProductVariant {
    compareAtPriceV2 {
      amount
      currencyCode
    }
    currentlyNotInStock
    id
    priceV2 {
      amount
      currencyCode
    }
    sku
    title
    image {
      xs: transformedSrc(maxHeight: 182)
      lg: transformedSrc(maxHeight: 182)
      md: transformedSrc(maxHeight: 134)
      xl: transformedSrc(maxHeight: 96)
    }
  }
`
export const ProductRecommendationFragmentDoc = gql`
  fragment ProductRecommendation on Product {
    handle
    featuredImage {
      url(transform: { crop: CENTER, maxHeight: 236 })
    }
    recommendationImage: metafield(
      namespace: "product_fields"
      key: "recommendation_image"
    ) {
      ...Metafield
    }
    seriesName: metafield(namespace: "product_fields", key: "series_name") {
      ...Metafield
    }
  }
  ${MetafieldFragmentDoc}
`
export const ProductVariantQuantityDataFragmentDoc = gql`
  fragment productVariantQuantityData on ProductVariant {
    quantityAvailable
    sku
    image {
      originalSrc
    }
    product {
      featuredImage {
        originalSrc
      }
      productType
      model: metafield(namespace: "product_fields", key: "model") {
        ...Metafield
      }
    }
    model: metafield(namespace: "variant_fields", key: "model") {
      ...Metafield
    }
  }
  ${MetafieldFragmentDoc}
`
export const ProductDocument = gql`
  query Product($id: ID, $handle: String) {
    product(id: $id, handle: $handle) {
      ...Product
    }
  }
  ${ProductFragmentDoc}
`

/**
 * __useProductQuery__
 *
 * To run a query within a React component, call `useProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductQuery({
 *   variables: {
 *      id: // value for 'id'
 *      handle: // value for 'handle'
 *   },
 * });
 */
export function useProductQuery(
  baseOptions?: Apollo.QueryHookOptions<ProductQuery, ProductQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ProductQuery, ProductQueryVariables>(
    ProductDocument,
    options
  )
}
export function useProductLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProductQuery, ProductQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ProductQuery, ProductQueryVariables>(
    ProductDocument,
    options
  )
}
export type ProductQueryHookResult = ReturnType<typeof useProductQuery>
export type ProductLazyQueryHookResult = ReturnType<typeof useProductLazyQuery>
export type ProductQueryResult = Apollo.QueryResult<
  ProductQuery,
  ProductQueryVariables
>
export const ProductFewDocument = gql`
  query ProductFew($id: ID) {
    product(id: $id) {
      title
      handle
      id
      description
      productType
      tags
      totalInventory
      featuredImage {
        xs: transformedSrc(maxHeight: 182)
        lg: transformedSrc(maxHeight: 182)
        md: transformedSrc(maxHeight: 134)
        xl: transformedSrc(maxHeight: 96)
      }
      variants(first: 100) {
        edges {
          node {
            ...SimpleProductVariant
          }
        }
      }
    }
  }
  ${SimpleProductVariantFragmentDoc}
`

/**
 * __useProductFewQuery__
 *
 * To run a query within a React component, call `useProductFewQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductFewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductFewQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProductFewQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ProductFewQuery,
    ProductFewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ProductFewQuery, ProductFewQueryVariables>(
    ProductFewDocument,
    options
  )
}
export function useProductFewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProductFewQuery,
    ProductFewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ProductFewQuery, ProductFewQueryVariables>(
    ProductFewDocument,
    options
  )
}
export type ProductFewQueryHookResult = ReturnType<typeof useProductFewQuery>
export type ProductFewLazyQueryHookResult = ReturnType<
  typeof useProductFewLazyQuery
>
export type ProductFewQueryResult = Apollo.QueryResult<
  ProductFewQuery,
  ProductFewQueryVariables
>
export const ProductForProductHeaderDocument = gql`
  query ProductForProductHeader($id: ID, $handle: String) {
    product(id: $id, handle: $handle) {
      ...Product
    }
  }
  ${ProductFragmentDoc}
`

/**
 * __useProductForProductHeaderQuery__
 *
 * To run a query within a React component, call `useProductForProductHeaderQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductForProductHeaderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductForProductHeaderQuery({
 *   variables: {
 *      id: // value for 'id'
 *      handle: // value for 'handle'
 *   },
 * });
 */
export function useProductForProductHeaderQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ProductForProductHeaderQuery,
    ProductForProductHeaderQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ProductForProductHeaderQuery,
    ProductForProductHeaderQueryVariables
  >(ProductForProductHeaderDocument, options)
}
export function useProductForProductHeaderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProductForProductHeaderQuery,
    ProductForProductHeaderQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ProductForProductHeaderQuery,
    ProductForProductHeaderQueryVariables
  >(ProductForProductHeaderDocument, options)
}
export type ProductForProductHeaderQueryHookResult = ReturnType<
  typeof useProductForProductHeaderQuery
>
export type ProductForProductHeaderLazyQueryHookResult = ReturnType<
  typeof useProductForProductHeaderLazyQuery
>
export type ProductForProductHeaderQueryResult = Apollo.QueryResult<
  ProductForProductHeaderQuery,
  ProductForProductHeaderQueryVariables
>
export const GetProductVariantDocument = gql`
  query GetProductVariant($id: ID!, $selectedOptions: [SelectedOptionInput!]!) {
    product(id: $id) {
      ...Product
      variantBySelectedOptions(selectedOptions: $selectedOptions) {
        ...ProductVariant
      }
    }
  }
  ${ProductFragmentDoc}
  ${ProductVariantFragmentDoc}
`

/**
 * __useGetProductVariantQuery__
 *
 * To run a query within a React component, call `useGetProductVariantQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductVariantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductVariantQuery({
 *   variables: {
 *      id: // value for 'id'
 *      selectedOptions: // value for 'selectedOptions'
 *   },
 * });
 */
export function useGetProductVariantQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProductVariantQuery,
    GetProductVariantQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetProductVariantQuery,
    GetProductVariantQueryVariables
  >(GetProductVariantDocument, options)
}
export function useGetProductVariantLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProductVariantQuery,
    GetProductVariantQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetProductVariantQuery,
    GetProductVariantQueryVariables
  >(GetProductVariantDocument, options)
}
export type GetProductVariantQueryHookResult = ReturnType<
  typeof useGetProductVariantQuery
>
export type GetProductVariantLazyQueryHookResult = ReturnType<
  typeof useGetProductVariantLazyQuery
>
export type GetProductVariantQueryResult = Apollo.QueryResult<
  GetProductVariantQuery,
  GetProductVariantQueryVariables
>
export const ProductVariantDocument = gql`
  query ProductVariant($id: ID!) {
    node(id: $id) {
      ...ProductVariant
    }
  }
  ${ProductVariantFragmentDoc}
`

/**
 * __useProductVariantQuery__
 *
 * To run a query within a React component, call `useProductVariantQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductVariantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductVariantQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProductVariantQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProductVariantQuery,
    ProductVariantQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ProductVariantQuery, ProductVariantQueryVariables>(
    ProductVariantDocument,
    options
  )
}
export function useProductVariantLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProductVariantQuery,
    ProductVariantQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ProductVariantQuery, ProductVariantQueryVariables>(
    ProductVariantDocument,
    options
  )
}
export type ProductVariantQueryHookResult = ReturnType<
  typeof useProductVariantQuery
>
export type ProductVariantLazyQueryHookResult = ReturnType<
  typeof useProductVariantLazyQuery
>
export type ProductVariantQueryResult = Apollo.QueryResult<
  ProductVariantQuery,
  ProductVariantQueryVariables
>
export const QueryProductVariantQuantityDocument = gql`
  query QueryProductVariantQuantity($id: ID!) {
    node(id: $id) {
      ... on ProductVariant {
        quantityAvailable
      }
    }
  }
`

/**
 * __useQueryProductVariantQuantityQuery__
 *
 * To run a query within a React component, call `useQueryProductVariantQuantityQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryProductVariantQuantityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryProductVariantQuantityQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useQueryProductVariantQuantityQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryProductVariantQuantityQuery,
    QueryProductVariantQuantityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    QueryProductVariantQuantityQuery,
    QueryProductVariantQuantityQueryVariables
  >(QueryProductVariantQuantityDocument, options)
}
export function useQueryProductVariantQuantityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryProductVariantQuantityQuery,
    QueryProductVariantQuantityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    QueryProductVariantQuantityQuery,
    QueryProductVariantQuantityQueryVariables
  >(QueryProductVariantQuantityDocument, options)
}
export type QueryProductVariantQuantityQueryHookResult = ReturnType<
  typeof useQueryProductVariantQuantityQuery
>
export type QueryProductVariantQuantityLazyQueryHookResult = ReturnType<
  typeof useQueryProductVariantQuantityLazyQuery
>
export type QueryProductVariantQuantityQueryResult = Apollo.QueryResult<
  QueryProductVariantQuantityQuery,
  QueryProductVariantQuantityQueryVariables
>
export const ProductRecommendationsDocument = gql`
  query productRecommendations($productId: ID!) {
    productRecommendations(productId: $productId) {
      ...Product
    }
  }
  ${ProductFragmentDoc}
`

/**
 * __useProductRecommendationsQuery__
 *
 * To run a query within a React component, call `useProductRecommendationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductRecommendationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductRecommendationsQuery({
 *   variables: {
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useProductRecommendationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProductRecommendationsQuery,
    ProductRecommendationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ProductRecommendationsQuery,
    ProductRecommendationsQueryVariables
  >(ProductRecommendationsDocument, options)
}
export function useProductRecommendationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProductRecommendationsQuery,
    ProductRecommendationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ProductRecommendationsQuery,
    ProductRecommendationsQueryVariables
  >(ProductRecommendationsDocument, options)
}
export type ProductRecommendationsQueryHookResult = ReturnType<
  typeof useProductRecommendationsQuery
>
export type ProductRecommendationsLazyQueryHookResult = ReturnType<
  typeof useProductRecommendationsLazyQuery
>
export type ProductRecommendationsQueryResult = Apollo.QueryResult<
  ProductRecommendationsQuery,
  ProductRecommendationsQueryVariables
>
export const ProductAccessoriesDocument = gql`
  query ProductAccessories($query: String!) {
    products(query: $query, first: 20) {
      edges {
        node {
          ...Product
        }
      }
    }
  }
  ${ProductFragmentDoc}
`

/**
 * __useProductAccessoriesQuery__
 *
 * To run a query within a React component, call `useProductAccessoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductAccessoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductAccessoriesQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useProductAccessoriesQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProductAccessoriesQuery,
    ProductAccessoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ProductAccessoriesQuery,
    ProductAccessoriesQueryVariables
  >(ProductAccessoriesDocument, options)
}
export function useProductAccessoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProductAccessoriesQuery,
    ProductAccessoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ProductAccessoriesQuery,
    ProductAccessoriesQueryVariables
  >(ProductAccessoriesDocument, options)
}
export type ProductAccessoriesQueryHookResult = ReturnType<
  typeof useProductAccessoriesQuery
>
export type ProductAccessoriesLazyQueryHookResult = ReturnType<
  typeof useProductAccessoriesLazyQuery
>
export type ProductAccessoriesQueryResult = Apollo.QueryResult<
  ProductAccessoriesQuery,
  ProductAccessoriesQueryVariables
>
export const GetProductRecommendationDocument = gql`
  query GetProductRecommendation($handle: String) {
    product(handle: $handle) {
      ...ProductRecommendation
    }
  }
  ${ProductRecommendationFragmentDoc}
`

/**
 * __useGetProductRecommendationQuery__
 *
 * To run a query within a React component, call `useGetProductRecommendationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductRecommendationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductRecommendationQuery({
 *   variables: {
 *      handle: // value for 'handle'
 *   },
 * });
 */
export function useGetProductRecommendationQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetProductRecommendationQuery,
    GetProductRecommendationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetProductRecommendationQuery,
    GetProductRecommendationQueryVariables
  >(GetProductRecommendationDocument, options)
}
export function useGetProductRecommendationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProductRecommendationQuery,
    GetProductRecommendationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetProductRecommendationQuery,
    GetProductRecommendationQueryVariables
  >(GetProductRecommendationDocument, options)
}
export type GetProductRecommendationQueryHookResult = ReturnType<
  typeof useGetProductRecommendationQuery
>
export type GetProductRecommendationLazyQueryHookResult = ReturnType<
  typeof useGetProductRecommendationLazyQuery
>
export type GetProductRecommendationQueryResult = Apollo.QueryResult<
  GetProductRecommendationQuery,
  GetProductRecommendationQueryVariables
>
export const ProductVariantsQuantityDocument = gql`
  query ProductVariantsQuantity($ids: [ID!]!) {
    nodes(ids: $ids) {
      ...productVariantQuantityData
    }
  }
  ${ProductVariantQuantityDataFragmentDoc}
`

/**
 * __useProductVariantsQuantityQuery__
 *
 * To run a query within a React component, call `useProductVariantsQuantityQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductVariantsQuantityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductVariantsQuantityQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useProductVariantsQuantityQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProductVariantsQuantityQuery,
    ProductVariantsQuantityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ProductVariantsQuantityQuery,
    ProductVariantsQuantityQueryVariables
  >(ProductVariantsQuantityDocument, options)
}
export function useProductVariantsQuantityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProductVariantsQuantityQuery,
    ProductVariantsQuantityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ProductVariantsQuantityQuery,
    ProductVariantsQuantityQueryVariables
  >(ProductVariantsQuantityDocument, options)
}
export type ProductVariantsQuantityQueryHookResult = ReturnType<
  typeof useProductVariantsQuantityQuery
>
export type ProductVariantsQuantityLazyQueryHookResult = ReturnType<
  typeof useProductVariantsQuantityLazyQuery
>
export type ProductVariantsQuantityQueryResult = Apollo.QueryResult<
  ProductVariantsQuantityQuery,
  ProductVariantsQuantityQueryVariables
>
