import { queryChannelSourceByWebsite } from '@/api/common'
import { isDreoApp } from '@/utils'
import {
  login,
  router as hsRouter,
  setWebviewConfig,
  showDialog as showAppDialog,
} from '@hsfe/dreojsapi'
import { Box } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import Typography from '@mui/material/Typography'
import * as Sentry from '@sentry/nextjs'
import Cookies from 'js-cookie'
import Image from 'next/legacy/image'
import { useRouter } from 'next/router'
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useCookie } from 'react-use'
import {
  CartDocument,
  CartQueryVariables,
  CheckoutCreateMutation,
  CheckoutCreateMutationVariables,
  CheckoutFragment,
  CheckoutLineItemsAddMutation,
  CheckoutLineItemsAddMutationVariables,
  CheckoutLineItemsRemoveMutation,
  CheckoutLineItemsRemoveMutationVariables,
  CheckoutLineItemsUpdateMutation,
  CheckoutLineItemsUpdateMutationVariables,
  useCheckoutCreateMutation,
  useCheckoutLineItemsAddMutation,
  useCheckoutLineItemsRemoveMutation,
  useCheckoutLineItemsUpdateMutation,
  useCheckoutShippingLineUpdateMutation,
} from 'shopifyFrontendApi/documents/checkout.graphql'
import {
  ProductVariantQuantityDataFragment as ProductVariantQuantityData,
  useProductVariantsQuantityLazyQuery,
} from 'shopifyFrontendApi/documents/product.graphql'
import { getUserInfo } from 'src/api/account'
import { useAuth } from 'src/contexts/auth-context'
import fbq from 'src/utils/fpixel'
import GoogleTagManager, {
  ContentIds,
  Contents,
  LineItems,
} from 'src/utils/gtm'
import { client } from 'src/utils/helpers/client'
import useSwr, { KeyedMutator } from 'swr'
import { User } from 'types/account'

// import appApi from '@/utils/appApi'

const env = process.env.NEXT_PUBLIC_WEB_APP_ENV

export type AddLines = CheckoutLineItemsAddMutationVariables['lineItems']
export type UpdateLines = CheckoutLineItemsUpdateMutationVariables['lineItems']
export type removeLineIds =
  CheckoutLineItemsRemoveMutationVariables['lineItemIds']

export type AddToCart = (
  lines: AddLines
) => Promise<CheckoutLineItemsAddMutation | null | undefined>

export type UpdateCart = (
  lines: UpdateLines
) => Promise<CheckoutLineItemsUpdateMutation | null | undefined>

export type RemoveLineItems = (
  lineIds: removeLineIds
) => Promise<CheckoutLineItemsRemoveMutation | null | undefined>

export type Cart = CheckoutFragment

const CartContext = React.createContext<
  | {
      cart: Cart | undefined
      cartId: string
      checkoutLoading: boolean
      loading: boolean
      cartLoading: boolean
      hasOutOfStock: boolean
      user: User | undefined
      token: string
      addToCart: AddToCart
      updateCart: UpdateCart
      removeLineItems: RemoveLineItems
      clearCart: () => Promise<
        CheckoutLineItemsRemoveMutation | null | undefined
      >
      handleCheckout: (
        shippingAddress: CheckoutCreateMutationVariables['input']['shippingAddress']
      ) => Promise<CheckoutCreateMutation['checkoutCreate'] | null | undefined>
      handleBuyNow: (
        shippingAddress: CheckoutCreateMutationVariables['input']['shippingAddress']
      ) => Promise<CheckoutCreateMutation['checkoutCreate'] | null | undefined>
      handleBuyNowByVisitor: (
        variantId: string | string[],
        productCategory?: string,
        attribute?: { key: string; value: string }[]
      ) => Promise<void>
      preOrderCheckout: (
        variantId: string,
        productCategory?: string
      ) => Promise<void>
      handleCheckoutByVictor: (variantId: string) => Promise<void>
      handleCheckoutNow: () => void
      beginCheckoutPush: (cartData: { node?: Cart }) => void
      mutate: KeyedMutator<{ node?: Cart } | undefined>
    }
  | undefined
>(undefined)

CartContext.displayName = 'CartContext'

enum OrderType {
  /** 游客 */
  TOURIST = 'tourist',
  /** 用户 */
  USER = 'user',
}

export const CartProvider = ({ children }: { children: ReactNode }) => {
  const userData = useAuth()
  const { user, token } = userData

  // 定义购物车ID
  const [userCartId, setUserCartId] = useState('')

  // 是否需要更新购物车ID
  // const [reloadFlag, setReloadFlag] = useState(false)

  // 本地购物车ID
  const [localCartId, setLocalCartId, deleteLocalCartId] =
    useCookie('localCartId')

  const router = useRouter()

  // cj click id
  const [cjEvent, setCjEvent] = useCookie('cj_event')

  // impact click id
  const [icClickId, setIcClickId] = useCookie('ir_click_id')

  // loading
  const [loading, setLoading] = useState(false)

  // 购物车loading
  const [cartLoading, setCartLoading] = useState(true)

  // 缺货产品图片列表
  const [outOfStockProductImages, setOutOfStockProductImages] = useState<
    string[]
  >([])

  // 是否展示弹窗
  const [showDialog, setDialog] = useState(false)

  // 查询变体数量
  const [fetchProductVariantsQuantity] = useProductVariantsQuantityLazyQuery()

  // 发起结算
  const [checkoutCreate, { loading: checkoutCreateLoading }] =
    useCheckoutCreateMutation()

  const backToCart = useCallback(() => {
    if (router.query?.variants) {
      router.back()
    } else if (router.asPath !== '/account/cart') {
      router.push('/account/cart')
    }

    setDialog(false)
  }, [router])

  const mainWrapperRef = useRef<HTMLElement | null>(null)
  useEffect(() => {
    mainWrapperRef.current = document.getElementById('main')
  }, [])

  // 设置impact cookie
  useEffect(() => {
    const icClickIdCookie = Cookies.get('__ir_click_id__')

    if (icClickIdCookie)
      setIcClickId(`${icClickIdCookie}`, {
        expires: 60 * 60 * 24 * 7,
      })
    if (router.query?.irclickid) {
      setIcClickId(`${router.query.irclickid}`, {
        expires: 60 * 60 * 24 * 7,
      })
      Cookies.set('__ir_click_id__', `${router.query.irclickid}`, {
        expires: 60 * 60 * 24 * 180,
      })
    }
  }, [router, setIcClickId])

  // 设置cj cookie
  useEffect(() => {
    const cjEvent = Cookies.get('__cj_event__')

    if (cjEvent)
      setCjEvent(`${cjEvent}`, {
        expires: 60 * 60 * 24 * 7,
      })
    if (router.query?.cjevent) {
      setCjEvent(`${router.query.cjevent}`, {
        expires: 60 * 60 * 24 * 7,
      })
      Cookies.set('__cj_event__', `${router.query.cjevent}`, {
        expires: 60 * 60 * 24 * 395,
      })
    }
  }, [router, setCjEvent])

  // const swrParams = useMemo(
  //   () => ({
  //     url: '/api/cartId',
  //     userVerified: user?.verified,
  //     userToken: token,
  //     reloadFlag: reloadFlag,
  //     localCartId,
  //   }),
  //   [reloadFlag, user, token, localCartId]
  // )

  // 获取cartId
  // const { data: cartId, mutate: cartIdMutate } = useSwr(
  //   swrParams,
  //   async ({ userVerified, reloadFlag, userToken, localCartId }) => {
  //     // 未登录情况下获取购物车ID
  //     if (!userToken && !userVerified) {
  //       if (localCartId) return localCartId

  //       const checkoutCreateRes = await checkoutCreate({
  //         variables: {
  //           input: {},
  //         },
  //       })

  //       setLocalCartId(
  //         `${checkoutCreateRes?.data?.checkoutCreate?.checkout?.id ?? ''}`,
  //         {
  //           expires: 60 * 60 * 24 * 7,
  //         }
  //       )

  //       return checkoutCreateRes?.data?.checkoutCreate?.checkout?.id
  //     }

  //     // 用户已登录
  //     if (!userVerified) return
  //     const res = await getCartId(reloadFlag)

  //     if (!res.data?.cartNo) {
  //       setReloadFlag(true)
  //       return
  //     }

  //     return res.data?.cartNo
  //     // return 'Z2lkOi8vc2hvcGlmeS9DaGVja291dC82YzBjZjRhMWVlMGQyYmM2MWEzM2NkZDFlYWU5ZjI0OT9rZXk9OGY4YTJkNzMwODk0MzI1ZjA2NmIxNjIwN2E5YzAxNzk='
  //   },
  //   {
  //     revalidateIfStale: false,
  //     // revalidateOnFocus: false,
  //     // revalidateOnReconnect: false,
  //   }
  // )

  const cartSwrParams = useMemo(
    () => ['/api/cart', userCartId] as const,
    [userCartId]
  )

  // 获取购物车数据
  const { data, mutate, isValidating } = useSwr(
    cartSwrParams,
    async (_, id) => {
      if (!id) return
      const { data } = await client.query<{ node?: Cart }, CartQueryVariables>({
        query: CartDocument,
        variables: {
          id: id ?? '',
        },
        fetchPolicy: 'network-only',
      })

      return data
    },
    {
      revalidateOnFocus: true,
    }
  )

  // 是否有缺货商品
  const hasOutOfStock = useMemo(
    () =>
      !!data?.node?.lineItems.edges.find(
        ({ node }) => node.variant?.quantityAvailable === 0
      ),
    [data]
  )

  // 添加购物车
  const [cartLinesAdd, { loading: cartLinesAddLoading }] =
    useCheckoutLineItemsAddMutation()

  const addToCart = useCallback(
    async (lines: CheckoutLineItemsAddMutationVariables['lineItems']) => {
      const { data } = await cartLinesAdd({
        variables: {
          checkoutId: userCartId ?? '',
          lineItems: lines,
        },
      })
      if (data?.checkoutLineItemsAdd?.checkout)
        mutate({ node: data?.checkoutLineItemsAdd.checkout }, false)
      // gtm.addToCart()
      return data
    },
    [userCartId, cartLinesAdd, mutate]
  )

  // 更新购物车
  const [cartLinesUpdate, { loading: cartLinesUpdateLoading }] =
    useCheckoutLineItemsUpdateMutation()

  const updateCart = useCallback(
    async (lines: CheckoutLineItemsUpdateMutationVariables['lineItems']) => {
      const { data } = await cartLinesUpdate({
        variables: {
          checkoutId: userCartId ?? '',
          lineItems: lines,
        },
      })
      if (data?.checkoutLineItemsUpdate?.checkout)
        // mutate({ cart: data.cartLinesUpdate.cart }, false)
        mutate()
      return data
    },
    [userCartId, cartLinesUpdate, mutate]
  )

  // 移除
  const [cartLinesRemove, { loading: cartLinesRemoveLoading }] =
    useCheckoutLineItemsRemoveMutation()

  const removeLineItems = useCallback(
    async (
      lineIds: CheckoutLineItemsRemoveMutationVariables['lineItemIds']
    ) => {
      const { data } = await cartLinesRemove({
        variables: {
          checkoutId: userCartId ?? '',
          lineItemIds: lineIds,
        },
      })

      // if (data?.cartLinesRemove?.cart)
      // mutate({ cart: data.cartLinesRemove.cart }, false)

      // 重新更新购物车
      mutate()
      return data
    },
    [userCartId, cartLinesRemove, mutate]
  )

  const clearCart = useCallback(async () => {
    const { data: resData } = await cartLinesRemove({
      variables: {
        checkoutId: userCartId ?? '',
        lineItemIds:
          data?.node?.lineItems.edges.map((lineEdge) => lineEdge.node.id) ?? [],
      },
    })
    // if (resData?.cartLinesRemove?.cart)
    if (resData?.checkoutLineItemsRemove?.checkout) {
      mutate({ node: resData.checkoutLineItemsRemove.checkout }, false)
    }
    // mutate({ cart: resData.cartLinesRemove.cart }, false)
    return resData
  }, [userCartId, cartLinesRemove, mutate, data])

  // 更新本地购物车数据到用户的购物车数据
  const handleUpdateCart = useCallback(
    async (cartId: string) => {
      if (!localCartId || (localCartId && localCartId === cartId)) {
        mutate()
        return setCartLoading(false)
      }

      setCartLoading(true)
      const { data } = await client.query<{ node?: Cart }, CartQueryVariables>({
        query: CartDocument,
        variables: {
          id: localCartId ?? '',
        },
      })

      deleteLocalCartId()

      const { data: cartLinesAddData } = await cartLinesAdd({
        variables: {
          checkoutId: cartId ?? '',
          lineItems:
            data?.node?.lineItems.edges.map(({ node: lineItem }) => ({
              quantity: lineItem.quantity,
              variantId: lineItem.variant?.id ?? '',
            })) ?? [],
        },
      })

      setCartLoading(false)
      if (cartLinesAddData?.checkoutLineItemsAdd?.checkout)
        mutate({ node: cartLinesAddData?.checkoutLineItemsAdd.checkout }, false)
    },
    [cartLinesAdd, localCartId, deleteLocalCartId, mutate]
  )

  const getUserCartId = useCallback(async () => {
    // 已登录未激活
    if (user && !user.verified) return

    let newCartId = ''

    // 用户未登录
    // 如果有本地购物车ID
    if (localCartId) {
      const { data } = await client.query<{ node?: Cart }, CartQueryVariables>({
        query: CartDocument,
        variables: {
          id: localCartId,
        },
      })

      // 更新购物车数据
      mutate(data, false)
      newCartId = localCartId
    } else {
      // 没有本地购物车ID则重新获取
      const checkoutCreateRes = await checkoutCreate({
        variables: {
          input: {},
        },
      })

      setLocalCartId(
        `${checkoutCreateRes?.data?.checkoutCreate?.checkout?.id ?? ''}`,
        {
          expires: 60 * 60 * 24 * 7,
        }
      )

      if (checkoutCreateRes?.data?.checkoutCreate?.checkout)
        mutate(
          { node: checkoutCreateRes?.data?.checkoutCreate.checkout },
          false
        )

      setUserCartId(checkoutCreateRes?.data?.checkoutCreate?.checkout?.id ?? '')

      if (checkoutCreateRes?.data?.checkoutCreate?.checkout?.id)
        newCartId = checkoutCreateRes.data.checkoutCreate.checkout.id
    }
    setUserCartId(newCartId)
    handleUpdateCart(newCartId)
  }, [
    checkoutCreate,
    handleUpdateCart,
    localCartId,
    mutate,
    setLocalCartId,
    user,
  ])

  // 监听返回的购物车数据 如没有node则重新请求
  useEffect(() => {
    if (data && !data?.node) {
      setCartLoading(true)
      getUserCartId()
    }
  }, [data, getUserCartId])

  // 获取购物车ID
  useEffect(() => {
    getUserCartId()
  }, [user, getUserCartId])

  useEffect(() => {
    if (!['checkout', 'cart'].find((item) => router.asPath.includes(item)))
      return

    // 是否需要更新购物车
    let canUpdateCart = false
    const lineItems: CheckoutLineItemsUpdateMutationVariables['lineItems'] = []

    // 是否需要删除无变体数据
    const removeLineItemsIds: CheckoutLineItemsRemoveMutationVariables['lineItemIds'] =
      []

    data?.node?.lineItems.edges.forEach(({ node }) => {
      if (
        (node.variant?.quantityAvailable as number) > 0 &&
        node.quantity > (node.variant?.quantityAvailable as number)
      ) {
        canUpdateCart = true
        lineItems.push({
          id: node.id,
          quantity: node.variant?.quantityAvailable as number,
        })
      }

      if (!node.variant) removeLineItemsIds.push(node.id)
    })

    if (canUpdateCart && lineItems.length > 0) {
      updateCart(lineItems)
    }

    if (removeLineItemsIds.length > 0) removeLineItems(removeLineItemsIds)
  }, [data, updateCart, removeLineItems, router])

  // 修改运费方式
  const [
    checkoutShippingLineUpdate,
    { loading: checkoutShippingLineUpdateLoading },
  ] = useCheckoutShippingLineUpdateMutation()

  const getNewCartData = useCallback(async () => {
    // 验证购物车是否有库存变化
    const { data: cartData } = await client.query<
      { node?: Cart },
      CartQueryVariables
    >({
      query: CartDocument,
      variables: {
        id: userCartId ?? '',
      },
      fetchPolicy: 'network-only',
    })

    setOutOfStockProductImages(
      cartData?.node?.lineItems.edges
        .filter(({ node }) => node.variant?.quantityAvailable === 0)
        .map(
          ({ node }) =>
            `${
              node.variant?.image?.originalSrc ||
              node.variant?.product.images.edges[0].node.originalSrc
            }`
        ) ?? ([] as string[])
    )

    return cartData
  }, [userCartId])

  // 上报开始结账事件
  const beginCheckoutPush = useCallback((cartData: { node?: Cart }) => {
    if (cartData.node) {
      const items: LineItems = []
      const content_ids: ContentIds = []
      const contents: Contents = []
      let num_items = 0

      cartData.node.lineItems.edges.map(({ node }) => {
        items.push({
          item_id: node.variant?.sku as string,
          item_name: node.title,
          affiliation: 'dreo_official_website',
          item_brand: 'dreo',
          item_category: node.variant?.product?.productType,
          price: node.variant?.priceV2.amount,
          currency: 'USD',
          quantity: node.quantity,
          discount: +node.discountAllocations[0]?.allocatedAmount.amount,

          id: node.variant?.sku as string,
          name: node.title,
          brand: 'dreo',
          category: node.variant?.product?.productType,
          variant:
            node.variant?.title === 'Default Title'
              ? undefined
              : node.variant?.title,
        })

        if (node.variant?.sku && !content_ids.includes(node.variant?.sku)) {
          content_ids.push(node.variant.sku)
        }

        num_items += node.quantity

        contents.push({
          id: node.variant?.sku as string,
          quantity: node.quantity,
        })
      })
      GoogleTagManager.beginCheckout({
        value: +cartData.node.totalPriceV2.amount,
        items,
        content_ids,
        contents,
        num_items,
      })

      fbq.initiateCheckout({
        content_ids,
        contents,
        num_items,
        content_type: 'product_group',
        content_category: 'begin_checkout',
        value: +cartData.node.totalPriceV2.amount,
        currency: 'USD',
      })
    }
  }, [])

  // 发起结算流程
  const handleCheckout = useCallback(
    async (
      shippingAddress: CheckoutCreateMutationVariables['input']['shippingAddress']
    ) => {
      setLoading(true)
      // 验证购物车是否有库存变化

      const cartData = await getNewCartData()

      const cart = cartData.node as Cart

      // 如果有缺货产品 则显示弹窗
      const outOfStockProductImagesArr: string[] = []
      cartData?.node?.lineItems.edges.forEach(({ node }) => {
        if (node.variant?.quantityAvailable === 0) {
          outOfStockProductImagesArr.push(
            `${
              node.variant?.image?.originalSrc ||
              node.variant?.product.images.edges[0].node.originalSrc
            }`
          )
        }
      })

      setLoading(false)
      mutate({ node: cartData.node as Cart }, false)
      // 如果有缺货产品则进行弹窗提示
      if (outOfStockProductImagesArr.length > 0) {
        setDialog(true)
        setOutOfStockProductImages(outOfStockProductImagesArr)
        return
      }

      // 获取来源
      const origin = location.origin
      const referrer = document.referrer
      let source = 'others'

      if (
        referrer === '' ||
        `${origin}/` === referrer ||
        referrer.includes(location.hostname)
      ) {
        source = 'direct'
      } else {
        const hostname = referrer.includes('.')
          ? new URL(referrer).hostname
          : referrer
        const res = await queryChannelSourceByWebsite(
          hostname.includes('www.') ? hostname.replace(/www\./, '') : hostname
        )

        if (res.data) {
          const hostSource = res.data.value.split('_')
          if (hostSource[hostSource.length - 1]) {
            source = hostSource[hostSource.length - 1].toLocaleLowerCase()
          }
        }
      }

      // 添加app来源的订单信息
      if (isDreoApp()) {
        source = 'app'
      }

      let customAttributes = [
        {
          key: 'email',
          value: user?.email ?? '',
        },
        {
          key: 'uid',
          value: `${user?.id}`,
        },
        {
          key: 'checkout_type',
          value: 'cart',
        },
        {
          key: 'order_type',
          value: OrderType.USER,
        },
        {
          key: 'cart_id',
          value: `${userCartId}`,
        },
        {
          key: 'source',
          value: source,
        },
        {
          key: 'entrance',
          value: 'purchase',
        },
      ]

      if (source === 'others' && referrer) {
        customAttributes.push({ key: 'referrer', value: referrer })
      }

      // 添加utm标识
      const utm_source = Cookies.get('utm_source')
      const utm_medium = Cookies.get('utm_medium')
      const utm_campaign = Cookies.get('utm_campaign')

      if (utm_source && utm_medium && utm_campaign) {
        customAttributes = customAttributes.concat([
          {
            key: 'utm_source',
            value: utm_source,
          },
          {
            key: 'utm_medium',
            value: utm_medium,
          },
          {
            key: 'utm_campaign',
            value: utm_campaign,
          },
        ])
      }

      // 添加环境标识
      if (['development', 'dev', 'fat', 'uat'].includes(`${env}`)) {
        customAttributes.push({ key: 'env', value: `${env}` })
      }

      // 添加cj标识
      if (cjEvent) {
        customAttributes.push({ key: 'cj_event', value: cjEvent })
      }

      // impact标识
      if (icClickId) {
        customAttributes.push({ key: 'ic_click_id', value: icClickId })
      }

      const variables = {
        input: {
          email: user?.email ?? '',
          shippingAddress,
          customAttributes,
          lineItems: data?.node?.lineItems.edges.map((lineEdge) => ({
            quantity: lineEdge.node.quantity,
            variantId: lineEdge.node.variant?.id ?? '',
          })),
          ...(cjEvent && {
            note: 'cj / affiliate',
          }),
          ...(icClickId && {
            note: 'impact / affiliate',
          }),
        },
      }

      // 添加产品分类信息
      const productCategories: string[] = []
      const productModels: string[] = []
      const customAttributesMap: { [key: string]: string } = {}
      const modelMap: { [K: string]: string } = {}

      cart.lineItems.edges.map(({ node: lineItem }) => {
        if (
          lineItem.variant?.product.productType &&
          !productCategories.includes(lineItem.variant.product.productType)
        )
          productCategories.push(lineItem.variant.product.productType)

        if (lineItem.variant?.sku) {
          customAttributesMap[lineItem.variant.sku] =
            lineItem.variant.product.productType
          modelMap[lineItem.variant.sku] = `${
            lineItem.variant?.model?.value ||
            lineItem.variant?.product.model?.value
          }`
        }

        const model =
          lineItem.variant?.model?.value ||
          lineItem.variant?.product.model?.value

        model && !productModels.includes(model) && productModels.push(model)
      })

      customAttributes.push({
        key: 'productCategories',
        value: productCategories.concat(productModels).join(','),
      })

      customAttributes.push({
        key: 'product_attritutes',
        value: JSON.stringify(customAttributesMap),
      })

      customAttributes.push({
        key: 'models',
        value: JSON.stringify(modelMap),
      })

      if (
        !['development', 'dev', 'fat', 'uat'].includes(
          `${process.env.NEXT_PUBLIC_WEB_APP_ENV}`
        )
      ) {
        // sentry埋点
        const checkoutTransaction = Sentry.startTransaction({
          name: 'cart_checkout',
        })

        Sentry.getCurrentHub().configureScope((scope) =>
          scope.setSpan(checkoutTransaction)
        )

        // 记录请求的变量
        const checkoutVariablesSpan = checkoutTransaction.startChild({
          op: 'cart_variables',
          description: JSON.stringify(variables.input),
        })

        checkoutVariablesSpan.finish()
        checkoutTransaction.finish()
      }

      const { data: checkoutData } = await checkoutCreate({
        variables,
      })

      if (checkoutData?.checkoutCreate?.checkout) {
        // 清空购物车数据
        // await clearCart()

        // 默认免运费
        await checkoutShippingLineUpdate({
          variables: {
            checkoutId: checkoutData?.checkoutCreate?.checkout?.id ?? '',
            shippingRateHandle: 'Expedited',
          },
        })
      }

      return checkoutData?.checkoutCreate
    },
    [
      getNewCartData,
      mutate,
      user?.email,
      user?.id,
      userCartId,
      cjEvent,
      icClickId,
      data?.node?.lineItems.edges,
      checkoutCreate,
      checkoutShippingLineUpdate,
    ]
  )

  // 游客方式结算
  const handleCheckoutByVictor = useCallback(async () => {
    setLoading(true)

    // 验证购物车是否有库存变化
    const cartData = await getNewCartData()

    const cart = cartData.node as Cart

    // 如果有缺货产品 则显示弹窗
    const outOfStockProductImagesArr: string[] = []
    cartData?.node?.lineItems.edges.forEach(({ node }) => {
      if (node.variant?.quantityAvailable === 0) {
        outOfStockProductImagesArr.push(
          `${
            node.variant?.image?.originalSrc ||
            node.variant?.product.images.edges[0].node.originalSrc
          }`
        )
      }
    })

    setLoading(false)
    mutate({ node: cartData.node as Cart }, false)
    // 如果有缺货产品则进行弹窗提示
    if (outOfStockProductImagesArr.length > 0) {
      setDialog(true)
      setOutOfStockProductImages(outOfStockProductImagesArr)
      return
    }

    // 上报开始结账事件
    beginCheckoutPush(cartData)
    // 获取来源
    const origin = location.origin
    const referrer = document.referrer
    let source = 'others'

    if (
      referrer === '' ||
      `${origin}/` === referrer ||
      referrer.includes(location.hostname)
    ) {
      source = 'direct'
    } else {
      const hostname = referrer.includes('.')
        ? new URL(referrer).hostname
        : referrer
      const res = await queryChannelSourceByWebsite(
        hostname.includes('www.') ? hostname.replace(/www\./, '') : hostname
      )

      if (res.data) {
        const hostSource = res.data.value.split('_')
        if (hostSource[hostSource.length - 1]) {
          source = hostSource[hostSource.length - 1].toLocaleLowerCase()
        }
      }
    }

    // 添加app来源的订单信息
    if (isDreoApp()) {
      source = 'app'
    }

    let customAttributes = [
      {
        key: 'checkout_type',
        value: 'cart',
      },
      {
        key: 'order_type',
        value: OrderType.TOURIST,
      },
      {
        key: 'cart_id',
        value: `${userCartId}`,
      },
      {
        key: 'source',
        value: source,
      },
      {
        key: 'entrance',
        value: 'purchase',
      },
    ]

    if (source === 'others' && referrer) {
      customAttributes.push({ key: 'referrer', value: referrer })
    }

    // 添加utm标识
    const utm_source = Cookies.get('utm_source')
    const utm_medium = Cookies.get('utm_medium')
    const utm_campaign = Cookies.get('utm_campaign')

    if (utm_source && utm_medium && utm_campaign) {
      customAttributes = customAttributes.concat([
        {
          key: 'utm_source',
          value: utm_source,
        },
        {
          key: 'utm_medium',
          value: utm_medium,
        },
        {
          key: 'utm_campaign',
          value: utm_campaign,
        },
      ])
    }

    // 添加产品分类信息
    const productCategories: string[] = []
    const productModels: string[] = []
    // 传入sku:category
    const customAttributesStrMap: { [K: string]: string } = {}
    const modelMap: { [K: string]: string } = {}

    cart.lineItems.edges.map(({ node: lineItem }) => {
      if (
        lineItem.variant?.product.productType &&
        !productCategories.includes(lineItem.variant.product.productType)
      )
        productCategories.push(lineItem.variant.product.productType)

      if (lineItem.variant?.sku) {
        customAttributesStrMap[lineItem.variant.sku] =
          lineItem.variant.product.productType
        modelMap[lineItem.variant.sku] = `${
          lineItem.variant?.model?.value ||
          lineItem.variant?.product?.model?.value
        }`
      }

      const model =
        lineItem.variant?.model?.value ||
        lineItem.variant?.product?.model?.value
      model && !productModels.includes(model) && productModels.push(model)
    })

    customAttributes.push({
      key: 'productCategories',
      value: productCategories.concat(productModels).join(','),
    })

    customAttributes.push({
      key: 'product_attritutes',
      value: JSON.stringify(customAttributesStrMap),
    })

    customAttributes.push({
      key: 'models',
      value: JSON.stringify(modelMap),
    })

    // 添加环境标识
    if (['development', 'dev', 'fat', 'uat'].includes(`${env}`)) {
      customAttributes.push({ key: 'env', value: `${env}` })
    }

    // 添加cj标识
    if (cjEvent) {
      customAttributes.push({ key: 'cj_event', value: cjEvent })
    }

    // impact标识
    if (icClickId) {
      customAttributes.push({ key: 'ic_click_id', value: icClickId })
    }

    const variables = {
      input: {
        customAttributes,
        lineItems: data?.node?.lineItems.edges.map((lineEdge) => ({
          quantity: lineEdge.node.quantity,
          variantId: lineEdge.node.variant?.id ?? '',
        })),
        ...(cjEvent && {
          note: 'cj / affiliate',
        }),
        ...(icClickId && {
          note: 'impact / affiliate',
        }),
      },
    }

    if (
      !['development', 'dev', 'fat', 'uat'].includes(
        `${process.env.NEXT_PUBLIC_WEB_APP_ENV}`
      )
    ) {
      // sentry埋点
      const checkoutTransaction = Sentry.startTransaction({
        name: 'cart_checkout_by_victor',
      })

      Sentry.getCurrentHub().configureScope((scope) =>
        scope.setSpan(checkoutTransaction)
      )

      // 记录请求的变量
      const checkoutVariablesSpan = checkoutTransaction.startChild({
        op: 'cart_variables_by_victor',
        description: JSON.stringify(variables.input),
      })

      checkoutVariablesSpan.finish()
      checkoutTransaction.finish()
    }

    const { data: checkoutData } = await checkoutCreate({
      variables,
    })

    window.location.href = checkoutData?.checkoutCreate?.checkout?.webUrl
  }, [
    beginCheckoutPush,
    checkoutCreate,
    cjEvent,
    data?.node?.lineItems.edges,
    getNewCartData,
    icClickId,
    mutate,
    userCartId,
  ])

  // 按钮点击前往结算
  const handleCheckoutNow = useCallback(async () => {
    // 未登录
    if (!token) {
      await handleCheckoutByVictor()
      return
    }

    const cartData = await getNewCartData()

    // 如果有缺货产品 则显示弹窗
    const outOfStockProductImagesArr: string[] = []
    cartData?.node?.lineItems.edges.forEach(({ node }) => {
      if (node.variant?.quantityAvailable === 0) {
        outOfStockProductImagesArr.push(
          `${
            node.variant?.image?.originalSrc ||
            node.variant?.product.images.edges[0].node.originalSrc
          }`
        )
      }
    })

    setLoading(false)

    mutate({ node: cartData.node as Cart }, false)
    if (outOfStockProductImagesArr.length > 0) {
      setDialog(true)
      return
    }

    // 上报开始结账事件
    beginCheckoutPush(cartData)

    // App中嵌入
    if (isDreoApp()) {
      hsRouter.push({
        url: `dreo://nav/Webview/browser?url=${window.origin}/checkout/address`,
      })
      return
    }

    router.push('/checkout/address')
  }, [
    token,
    getNewCartData,
    mutate,
    beginCheckoutPush,
    router,
    handleCheckoutByVictor,
  ])

  // 游客方式购买
  const handleBuyNowByVisitor = useCallback(
    async (
      variantIds: string | string[],
      productCategories?: string,
      attributes: { key: string; value: string }[] = []
    ) => {
      // 获取来源
      const origin = location.origin
      const referrer = document.referrer
      let source = 'others'

      if (
        referrer === '' ||
        `${origin}/` === referrer ||
        referrer.includes(location.hostname)
      ) {
        source = 'direct'
      } else {
        const hostname = referrer.includes('.')
          ? new URL(referrer).hostname
          : referrer
        const res = await queryChannelSourceByWebsite(
          hostname.includes('www.') ? hostname.replace(/www\./, '') : hostname
        )

        if (res.data) {
          const hostSource = res.data.value.split('_')
          if (hostSource[hostSource.length - 1]) {
            source = hostSource[hostSource.length - 1].toLocaleLowerCase()
          }
        }
      }
      // 添加app来源的订单信息
      if (isDreoApp()) {
        source = 'app'
      }

      let customAttributes = [
        {
          key: 'checkout_type',
          value: 'buy_now_visitor',
        },
        {
          key: 'order_type',
          value: OrderType.TOURIST,
        },
        {
          key: 'source',
          value: source,
        },
        {
          key: 'entrance',
          value: 'purchase',
        },
        {
          key: 'productCategories',
          value: `${productCategories}`,
        },
        ...attributes,
      ]

      if (source === 'others' && referrer) {
        customAttributes.push({ key: 'referrer', value: referrer })
      }

      // 添加utm标识
      const utm_source = Cookies.get('utm_source')
      const utm_medium = Cookies.get('utm_medium')
      const utm_campaign = Cookies.get('utm_campaign')

      if (utm_source && utm_medium && utm_campaign) {
        customAttributes = customAttributes.concat([
          {
            key: 'utm_source',
            value: utm_source,
          },
          {
            key: 'utm_medium',
            value: utm_medium,
          },
          {
            key: 'utm_campaign',
            value: utm_campaign,
          },
        ])
      }

      // 添加环境标识
      if (['development', 'dev', 'fat', 'uat'].includes(`${env}`)) {
        customAttributes.push({ key: 'env', value: `${env}` })
      }

      // 添加cj标识
      if (cjEvent) {
        customAttributes.push({ key: 'cj_event', value: cjEvent })
      }

      // impact标识
      if (icClickId) {
        customAttributes.push({ key: 'ic_click_id', value: icClickId })
      }

      const lineItems: Array<{ quantity: number; variantId: string }> = []
      if (Array.isArray(variantIds)) {
        variantIds.forEach((variantId) =>
          lineItems.push({ quantity: 1, variantId })
        )
      } else {
        lineItems.push({ quantity: 1, variantId: variantIds })
      }

      const variables = {
        input: {
          customAttributes,
          lineItems,
          ...(cjEvent && {
            note: 'cj / affiliate',
          }),
          ...(icClickId && {
            note: 'impact / affiliate',
          }),
        },
      }

      if (
        !['development', 'dev', 'fat', 'uat'].includes(
          `${process.env.NEXT_PUBLIC_WEB_APP_ENV}`
        )
      ) {
        // sentry埋点
        const checkoutTransaction = Sentry.startTransaction({
          name: 'buy_now_by_visitor',
        })

        Sentry.getCurrentHub().configureScope((scope) =>
          scope.setSpan(checkoutTransaction)
        )

        // 记录请求的变量
        const checkoutVariablesSpan = checkoutTransaction.startChild({
          op: 'buy_now_by_visitor_variables',
          description: JSON.stringify(variables.input),
        })
        checkoutVariablesSpan.finish()
        checkoutTransaction.finish()
      }

      const { data: checkoutData } = await checkoutCreate({
        variables,
      })

      GoogleTagManager.onCheckoutOption(2, 'Address Select')

      if (checkoutData?.checkoutCreate?.checkout) {
        // 清空购物车数据
        // await clearCart()

        // 默认免运费
        await checkoutShippingLineUpdate({
          variables: {
            checkoutId: checkoutData?.checkoutCreate?.checkout?.id ?? '',
            shippingRateHandle: 'Expedited',
          },
        })
      }

      if (checkoutData?.checkoutCreate?.checkout?.webUrl)
        window.location.href = checkoutData.checkoutCreate.checkout.webUrl
    },
    [checkoutCreate, checkoutShippingLineUpdate, cjEvent, icClickId]
  )

  // 立即购买
  const handleBuyNow = useCallback(
    async (
      shippingAddress: CheckoutCreateMutationVariables['input']['shippingAddress']
    ) => {
      if (!router.query?.variants) return

      const checkoutType = router.query?.type ?? 'buy_now'

      const variantIds = (router.query.variants as string)
        .split(',')
        .map((item) => `gid://shopify/ProductVariant/${item}`)

      const { data: productVariantsData } = await fetchProductVariantsQuantity({
        variables: { ids: variantIds },
      })

      const productCategories: string[] = []
      const productModels: string[] = []
      const customAttributesMap: { [K: string]: string } = {}
      const modelMap: { [K: string]: string } = {}

      const outOfStockProductImagesArr: string[] = []
      productVariantsData?.nodes.forEach((node) => {
        const variant = node as ProductVariantQuantityData
        const model = variant.model?.value || variant.product.model?.value

        productCategories.push(variant.product.productType)

        if (variant?.sku) {
          customAttributesMap[variant.sku] = variant.product.productType
          modelMap[variant.sku] = `${
            variant.model?.value || variant.product.model?.value
          }`
        }

        variant.product.productType &&
          !productCategories.includes(variant.product.productType) &&
          productCategories.push(variant.product.productType)

        model && !productModels.includes(model) && productModels.push(model)

        if (variant.quantityAvailable === 0) {
          outOfStockProductImagesArr.push(
            `${
              variant.image?.originalSrc ||
              variant.product?.featuredImage?.originalSrc
            }`
          )
        }
      })

      if (outOfStockProductImagesArr.length > 0) {
        setDialog(true)
        setOutOfStockProductImages(outOfStockProductImagesArr)
        return
      }

      // 获取来源
      const origin = location.origin
      const referrer = document.referrer
      let source = 'others'

      if (
        referrer === '' ||
        `${origin}/` === referrer ||
        referrer.includes(location.hostname)
      ) {
        source = 'direct'
      } else {
        const hostname = referrer.includes('.')
          ? new URL(referrer).hostname
          : referrer
        const res = await queryChannelSourceByWebsite(
          hostname.includes('www.') ? hostname.replace(/www\./, '') : hostname
        )

        if (res.data) {
          const hostSource = res.data.value.split('_')
          if (hostSource[hostSource.length - 1]) {
            source = hostSource[hostSource.length - 1].toLocaleLowerCase()
          }
        }
      }

      // 添加app来源的订单信息
      if (isDreoApp()) {
        source = 'app'
      }

      let customAttributes = [
        {
          key: 'email',
          value: user?.email ?? '',
        },
        {
          key: 'uid',
          value: `${user?.id}`,
        },
        {
          key: 'checkout_type',
          value: `${checkoutType}`,
        },
        {
          key: 'order_type',
          value: OrderType.USER,
        },
        {
          key: 'source',
          value: source,
        },
        {
          key: 'entrance',
          value: 'purchase',
        },
        {
          key: 'productCategories',
          value: productCategories.concat(productModels).join(','),
        },
        {
          key: 'product_attritutes',
          value: JSON.stringify(customAttributesMap),
        },
        {
          key: 'models',
          value: JSON.stringify(modelMap),
        },
      ]

      if (source === 'others' && referrer) {
        customAttributes.push({ key: 'referrer', value: referrer })
      }

      // 添加utm标识
      const utm_source = Cookies.get('utm_source')
      const utm_medium = Cookies.get('utm_medium')
      const utm_campaign = Cookies.get('utm_campaign')

      if (utm_source && utm_medium && utm_campaign) {
        customAttributes = customAttributes.concat([
          {
            key: 'utm_source',
            value: utm_source,
          },
          {
            key: 'utm_medium',
            value: utm_medium,
          },
          {
            key: 'utm_campaign',
            value: utm_campaign,
          },
        ])
      }

      // 添加环境标识
      if (['development', 'dev', 'fat', 'uat'].includes(`${env}`)) {
        customAttributes.push({ key: 'env', value: `${env}` })
      }

      // 添加cj标识
      if (cjEvent) {
        customAttributes.push({ key: 'cj_event', value: cjEvent })
      }

      // impact标识
      if (icClickId) {
        customAttributes.push({ key: 'ic_click_id', value: icClickId })
      }

      const variables = {
        input: {
          email: user?.email ?? '',
          shippingAddress,
          customAttributes,
          lineItems: variantIds.map((variantId) => ({
            quantity: 1,
            variantId,
          })),
          ...(cjEvent && {
            note: 'cj / affiliate',
          }),
          ...(icClickId && {
            note: 'impact / affiliate',
          }),
        },
      }

      if (
        !['development', 'dev', 'fat', 'uat'].includes(
          `${process.env.NEXT_PUBLIC_WEB_APP_ENV}`
        )
      ) {
        // sentry埋点
        const checkoutTransaction = Sentry.startTransaction({
          name: 'buy_now_checkout',
        })

        Sentry.getCurrentHub().configureScope((scope) =>
          scope.setSpan(checkoutTransaction)
        )

        // 记录请求的变量
        const checkoutVariablesSpan = checkoutTransaction.startChild({
          op: 'buy_now_variables',
          description: JSON.stringify(variables.input),
        })
        checkoutVariablesSpan.finish()
        checkoutTransaction.finish()
      }

      const { data: checkoutData } = await checkoutCreate({
        variables,
      })

      GoogleTagManager.onCheckoutOption(2, 'Address Select')

      if (checkoutData?.checkoutCreate?.checkout) {
        // 清空购物车数据
        // await clearCart()

        // 默认免运费
        await checkoutShippingLineUpdate({
          variables: {
            checkoutId: checkoutData?.checkoutCreate?.checkout?.id ?? '',
            shippingRateHandle: 'Expedited',
          },
        })
      }

      return checkoutData?.checkoutCreate
    },
    [
      router.query?.variants,
      router.query?.type,
      fetchProductVariantsQuantity,
      user?.email,
      user?.id,
      cjEvent,
      icClickId,
      checkoutCreate,
      checkoutShippingLineUpdate,
    ]
  )

  const appValidate = useCallback(async () => {
    // 如果在App中嵌入
    if (isDreoApp()) {
      const dialogConfig = {
        content: 'Please verify your email address first.',
        cancelText: 'Cancel',
        cancelColor: '#696969',
        confirmText: 'Go Verify',
        confirmColor: '#0051CF',
        confirmUrl: 'dreo://nav/rn/verifyemail?type=3&showSkip=false',
      }
      // 已登录
      if ((token || window.h5Token) && user) {
        // 是否未激活 则唤起APP激活验证
        if (user && !user?.verified) {
          showAppDialog(dialogConfig)
          return false
        }
      } else if ((token || window.h5Token) && !user) {
        const { data: userData } = await getUserInfo()
        // 是否未激活 则唤起APP激活验证
        if (userData && !userData?.verified) {
          showAppDialog(dialogConfig)
          return false
        }
      } else {
        // 未登录 则唤起APP登录
        login()
        return false
      }
    }

    return true
  }, [token, user])

  /**
   * 预售
   */
  const preOrderCheckout = useCallback(
    async (variantId: string) => {
      // 判断是否登录
      const valid = await appValidate()
      if (!valid) return

      // App中嵌入
      if (isDreoApp()) {
        hsRouter.push({
          url: `dreo://nav/Webview/browser?url=${encodeURIComponent(
            `${window.origin}/checkout/address?variants=${variantId}&type=pre_order`
          )}`,
        })
      }

      // 未登录 游客购买
      if (!token) {
        return handleBuyNowByVisitor(
          `gid://shopify/ProductVariant/${variantId}`,
          '',
          [{ key: 'checkout_type', value: 'pre_order' }]
        )
      }

      // 判断用户是否未激活
      if (token && user && !user?.verified) {
        router.push({
          pathname: '/account/has-not-been-activated',
          query: {
            redirectTo: encodeURIComponent(router.asPath),
          },
        })
        return
      }

      router.push(`/checkout/address?variants=${variantId}&type=pre_order`)
    },
    [appValidate, handleBuyNowByVisitor, router, token, user]
  )

  const checkoutLoading = useMemo(
    () =>
      cartLinesRemoveLoading ||
      cartLinesUpdateLoading ||
      cartLinesAddLoading ||
      checkoutCreateLoading ||
      checkoutShippingLineUpdateLoading,
    [
      cartLinesRemoveLoading,
      cartLinesUpdateLoading,
      cartLinesAddLoading,
      checkoutCreateLoading,
      checkoutShippingLineUpdateLoading,
    ]
  )

  const cartProductTotal = useMemo(
    () =>
      data?.node?.lineItems.edges.reduce(
        (prev, cur) => (prev += cur.node.quantity),
        0
      ) ?? 0,
    [data?.node]
  )

  useEffect(() => {
    // 在App中嵌入 设置页面标题
    if (isDreoApp() && !(!data?.node || cartLoading || isValidating)) {
      setWebviewConfig({
        navRightConfig: [
          {
            type: '2',
            key: 'cart',
            ...(cartProductTotal > 0
              ? {
                  number: `${cartProductTotal > 99 ? '99+' : cartProductTotal}`,
                }
              : {
                  number: '',
                }),
          },
        ],
      })
    }
  }, [cartLoading, cartProductTotal, data?.node, isValidating])

  const cartContextValue = useMemo(
    () => ({
      ...userData,
      cart: data?.node as Cart,
      cartId: userCartId ?? '',
      addToCart,
      hasOutOfStock,
      loading: loading || userData.loading,
      cartLoading: !data?.node || cartLoading || isValidating,
      checkoutLoading,
      updateCart,
      removeLineItems,
      handleCheckout,
      handleBuyNow,
      handleBuyNowByVisitor,
      handleCheckoutByVictor,
      clearCart,
      handleCheckoutNow,
      beginCheckoutPush,
      preOrderCheckout,
      mutate,
    }),
    [
      isValidating,
      cartLoading,
      userData,
      data,
      userCartId,
      addToCart,
      hasOutOfStock,
      checkoutLoading,
      loading,
      updateCart,
      removeLineItems,
      handleCheckout,
      handleBuyNow,
      handleBuyNowByVisitor,
      handleCheckoutByVictor,
      clearCart,
      handleCheckoutNow,
      beginCheckoutPush,
      preOrderCheckout,
      mutate,
    ]
  )

  return (
    <CartContext.Provider value={cartContextValue}>
      {children}
      <Dialog
        open={showDialog}
        onClose={() => setDialog(false)}
        scroll="body"
        sx={{
          position: 'absolute',
          zIndex: 99999,
          '& .MuiPaper-root': {
            borderRadius: 2,
            boxShadow: 'none',
          },
          '& .MuiBackdrop-root': {
            bgcolor: 'rgba(0,0,0,0.16)',
            backdropFilter: 'blur(4px)',
          },
        }}
        keepMounted
        maxWidth="md"
      >
        <DialogContent
          sx={{
            maxWidth: {
              xs: 327,
              md: 400,
            },
            py: {
              xs: 4,
              md: 5,
            },
            px: {
              xs: 4,
              md: 7.5,
            },
          }}
        >
          <Box>
            <Typography
              variant="headlineMediumSemiBold"
              color="text_ultraStrong.main"
              mb={2.5}
              align="center"
            >
              Ooops!
            </Typography>
            <Box
              sx={{
                position: 'relative',
                width: {
                  xs: 120,
                  md: 148,
                },
                height: {
                  xs: 91.21,
                  md: 112.5,
                },
                m: '0 auto 20px',
              }}
            >
              {outOfStockProductImages.length > 0 && (
                <Box
                  sx={{
                    position: 'absolute',
                    zIndex: 10,
                    width: '100%',
                    height: '100%',
                    borderRadius: '4px',
                    boxShadow: '3px 4px 10px rgba(0,0,0,0.15)',
                    bgcolor: '#fff',
                    transform:
                      outOfStockProductImages.length > 1
                        ? 'translate(-4px, -4px)'
                        : 'translate(0)',
                  }}
                >
                  <Image
                    src={outOfStockProductImages[0] ?? ''}
                    unoptimized
                    layout={'fill'}
                    objectFit="cover"
                  />
                </Box>
              )}
              {outOfStockProductImages.length > 1 && (
                <Box
                  sx={{
                    position: 'absolute',
                    zIndex: 0,
                    width: '100%',
                    height: '100%',
                    borderRadius: '4px',
                    boxShadow: '3px 4px 10px rgba(0,0,0,0.15)',
                    bgcolor: '#fff',
                    transform: 'translate(4px, 4px)',
                  }}
                ></Box>
              )}
            </Box>
            <Typography
              variant="labelMediumRegular"
              color="text_strong.main"
              mb={4}
              align="center"
            >
              The quanity of items in the shopping cart has changed.
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                disableRipple
                variant="contained"
                color="enviLight_neutral"
                onClick={backToCart}
              >
                Back{!router.query?.variants ? ' to cart' : ''}
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </CartContext.Provider>
  )
}

export const useCart = () => {
  const context = React.useContext(CartContext)
  if (!context) {
    throw new Error('useCart must be use in CartContext')
  }

  return context
}
